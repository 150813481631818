/* eslint-disable react/jsx-filename-extension */
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../../config/config';
import { moduleRank } from '../../../utils/programScores';
import Initiatives from './Initiatives';
import { InfoText } from '../../../components/elements/elements';
import './ip.scss';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Modal, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import IpBubble from './IpBubble';

const InitiativePrioritizationHome = ({ programId, oppIndex, projectId, projectName, navigate, opportunityIndex }) => {

  const [bubbleChartStatus, setBubbleChartStatus] = useState(false);
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [oppIndex, setOppIndex] = useState(0);
  // const { programId } = useParams();
  // const programId = '61ad0125e9b675042f2dbf51'

  async function getProgram() {
    const px = await Axios.get(`${config.api}/programs/${programId}`).then((x) => x.data);

    for (let j = 0; j < px.opportunities.length; j += 1) {
      for (let i = 0; i < px.opportunities[j].modules.length; i += 1) {
        const module = px.opportunities[j].modules[i];
        module.score = parseFloat(moduleRank(module, px.config));
      }
    }
    setProgram(px);
    setLoading(false);
  }
  useEffect(() => {
    getProgram();
  }, []);

  // function selectOpp(e) {
  //   // eslint-disable-next-line radix
  //   setOppIndex(e);
  // }

  return (
    <div style={{ marginTop: 30 }} className="ip-container projects-page">
      {
        program && <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/company-admin">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/company-admin/projects">Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate('program')}>
            <a>{program.programName}</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {program.opportunities[oppIndex].opportunityName}
          </Breadcrumb.Item>

        </Breadcrumb>
      }
      <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>

        <h1>Prioritization</h1>
        <Button type="default" onClick={() => setBubbleChartStatus(true)}>
          Prioritization Graph
        </Button>
      </div>
      {
        !loading ?
          <div className="ip-bodyarea">
            <InfoText>Maturity score mapping</InfoText>
            <div style={{ height: 20 }} />
            {
              program
              && (
                <div className="modules">
                  <div className="mt">
                    <div className="head">0.0 to 0.9</div>
                    <div className="initiatives">
                      <Initiatives
                        min={0}
                        max={0.9}
                        modules={program.opportunities[oppIndex].modules}
                      />
                    </div>
                  </div>
                  <div className="mt">
                    <div className="head">1.0 to 1.9</div>
                    <div className="initiatives">
                      <Initiatives
                        min={1}
                        max={1.9}
                        modules={program.opportunities[oppIndex].modules}
                      />
                    </div>
                  </div>
                  <div className="mt">
                    <div className="head">2.0 to 2.9</div>
                    <div className="initiatives">
                      <Initiatives
                        min={2}
                        max={2.9}
                        modules={program.opportunities[oppIndex].modules}
                      />
                    </div>
                  </div>
                  <div className="mt">
                    <div className="head">3.0 to 3.9</div>
                    <div className="initiatives">
                      <Initiatives
                        min={3}
                        max={3.9}
                        modules={program.opportunities[oppIndex].modules}
                      />
                    </div>
                  </div>
                  <div className="mt">
                    <div className="head">4.0 to 5.0</div>
                    <div className="initiatives">
                      <Initiatives
                        min={4}
                        max={5}
                        modules={program.opportunities[oppIndex].modules}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          :
          <Skeleton paragraph={{ rows: 10 }} active />
      }

      <Modal
        title={<h1 style={{ textTransform: 'none', paddingTop: 15, marginBottom: 0 }}>Initiative Prioritization Ranking</h1>}
        centered
        className="lg-modal"
        visible={bubbleChartStatus}
        onOk={() => { }}
        onCancel={() => setBubbleChartStatus(false)}
      >
        <IpBubble
          opportunityIndex={opportunityIndex}
          programData={program}
        />
      </Modal>
    </div>
  );
};

InitiativePrioritizationHome.propTypes = {
  programId: PropTypes.string.isRequired,
  oppIndex: PropTypes.number.isRequired,
  showBubbleChart: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  opportunityIndex: PropTypes.number.isRequired,
}

export default InitiativePrioritizationHome;
