import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import config from "../../../../config/config";
import LaunchPad from './LaunchPad';
import Programs from './Programs';
import Initiatives from './Initiatives';
import Features from './Features';
import Opportunities from './Opportunities';
import { message } from 'antd';
import FeatureRank from './FeatureRank';
import _ from 'underscore';
import AddProgram from './AddProgram';
import AddInitiative from './AddInitiative';
import AddOpportunity from './AddOpportunity';
import AddFeature from './AddFeature';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CustomerAdminContext } from '../../CustomerAdminContext';
import UploadPrograms from './UploadPrograms';
import AddModel from '../Dashboard/AddModel';
// import { useAuth0 } from "@auth0/auth0-react";


const PRHome = ({ reducer }) => {

    const { projectId } = useParams();
    const [loading, setLoading] = useState(true);
    const [colView, setColView] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [programIndex, setProgramIndex] = useState(0);
    const [opportunityIndex, setOpportunityIndex] = useState(0);
    const [inititiveIndex, setInititiveIndex] = useState(0);
    const [featureIndex, setFeatureIndex] = useState(0);
    const [mode, setMode] = useState('program');
    const [featureFromTree, setFeatureFromTree] = useState(true);
    const { rankingHierarchy } = useContext(CustomerAdminContext);
    const [showModal, setShowModal] = useState(false);
    // const { getIdTokenClaims } = useAuth0();


    const getPrograms = async () => {
        setLoading(true);
        const programs = await Axios.post(`${config.api}/programs/by-project`, {
            projectId
        });
        setLoading(false);
        return programs.data;
    }

    useEffect(() => {
        // setToken(tk.__raw)
        attachProgram();
    }, []);

    function attachProgram() {
        getPrograms().then(x => {
            setPrograms(x);
            if (x.length === 0) {
                setMode('program');
                setColView(false)
            }
        });
    }

    async function updateProgram(program) {
        const newProgram = await Axios.put(`${config.api}/programs/${program._id}`, program).then(x => x.data);
        message.success('Score captured successfully');
        let pCopy = _.clone(programs)
        pCopy[programIndex] = newProgram;
        setPrograms(pCopy);
        if (!featureFromTree) {
            setColView(false);
            setMode('feature');
        } else {
            setColView(true);
        }
    }


    function renderMode() {
        switch (mode) {
            case 'program':
                return <Programs
                    programs={programs}
                    setColView={() => setColView(true)}
                    projectId={projectId}
                    onAddProgram={() => {
                        getPrograms(projectId).then(x => setPrograms(x))
                    }}
                    setProgramIndex={(index) => {
                        setMode('opportunity');
                        setProgramIndex(index)
                    }}
                    editProgram={(index) => {
                        setProgramIndex(index);
                        setMode('editProgram')
                    }}
                    deleteProgram={async (index) => {
                        await Axios.delete(`${config.api}/programs/${programs[index]._id}`);
                        message.success(`${rankingHierarchy[1].s} deleted successfully`);
                        let pCopy = _.clone(programs);
                        pCopy.splice(index, 1);
                        setPrograms(pCopy);
                        setMode('program');
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                    navigateTo={() => {
                        setMode('program');
                    }}
                />
            case 'editProgram':
                return <AddProgram
                    program={programs[programIndex]}
                    type="PUT"
                    onCacel={() => {
                        setMode('program');
                    }}
                    onAddProgram={(px) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex] = px;
                        setPrograms(pCopy);
                        setMode('program');
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                />
            case 'opportunity':
                return <Opportunities
                    opportunities={programs[programIndex].opportunities}
                    onSaveOpportunity={onSaveOpportunity}
                    setColView={() => setColView(true)}
                    configs={programs[programIndex].config}
                    setOpportunityIndex={(index) => {
                        setOpportunityIndex(index);
                        setMode('inititive');
                    }}
                    editOpportunity={() => setMode('editOpportunity')}
                    deleteOpportunity={async (index) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities.splice(index, 1);
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('opportunity');
                        message.success(`${rankingHierarchy[2].s} deleted successfully`);
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    navigate={() => setMode('program')}
                />
            case 'editOpportunity':
                return <AddOpportunity
                    opportunity={programs[programIndex].opportunities[opportunityIndex]}
                    type="PUT"
                    onCacel={() => { }}
                    projectName={reducer.dataReducer.project.projectName}
                    programName={programs[programIndex].programName}
                    projectId={projectId}
                    onCancel={() => setMode('opportunity')}
                    onSaveOpportunity={async (opportunity) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex] = opportunity;
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('opportunity');
                        message.success(`${rankingHierarchy[2].s} updated successfully`);
                    }}

                />

            case 'inititive':
                return <Initiatives
                    initiatives={programs[programIndex].opportunities[opportunityIndex].modules}
                    onSaveInitiative={onSaveInitiative}
                    timeUnit={programs[programIndex].config.timeUnit}
                    setColView={() => setColView(true)}
                    configs={programs[programIndex].config}
                    editInitiative={(index) => {
                        setInititiveIndex(index);
                        setMode('editInitiative');
                    }}
                    setInitiativeIndex={(index) => {
                        setMode('feature');
                        setInititiveIndex(index);
                    }}
                    deleteInitiative={async (index) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex].modules.splice(index, 1);
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('inititive');
                        message.success(`${rankingHierarchy[3].s} deleted successfully`);
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    opportunitieName={programs[programIndex].opportunities[opportunityIndex].opportunityName}
                    navigate={(type) => {
                        setMode(type);
                    }}
                />

            case 'editInitiative':
                return <AddInitiative
                    initiative={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex]}
                    type="PUT"
                    onSaveInitiative={() => { }}
                    onCancel={() => setMode('inititive')}
                    timeUnit={programs[programIndex].config.timeUnit}
                    updateInitiative={async (initiative) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex].modules[inititiveIndex] = initiative;
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('inititive');
                        message.success(`${rankingHierarchy[3].s} updated successfully`);
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    opportunitieName={programs[programIndex].opportunities[opportunityIndex].opportunityName}
                    navigate={(type) => {
                        setMode(type);
                    }}
                />

            case 'feature':
                return <Features
                    setColView={() => setColView(true)}
                    features={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features}
                    onSaveFeature={onSaveFeature}
                    configs={programs[programIndex].config}
                    deleteFeature={async (index) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features.splice(index, 1);
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('feature');
                        message.success(`${rankingHierarchy[4].s} deleted successfully`);
                    }}
                    onSelectFeature={(index) => {
                        setMode('featureRank');
                        setFeatureIndex(index);
                        setFeatureFromTree(false);
                    }}
                    editFeature={() => setMode('editFeature')}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    opportunitieName={programs[programIndex].opportunities[opportunityIndex].opportunityName}
                    initiativeName={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].moduleName}
                    navigate={(type) => {
                        setMode(type);
                    }}
                />

            case 'editFeature':
                return <AddFeature
                    feature={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features[featureIndex]}
                    type="PUT"
                    onSaveFeature={async (fx) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features[featureIndex].feature = fx.feature;
                        pCopy[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features[featureIndex].description = fx.description;

                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('feature');
                        message.success(`${rankingHierarchy[4].s} updated successfully`);
                    }}
                    onCancel={() => setMode('feature')}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    opportunitieName={programs[programIndex].opportunities[opportunityIndex].opportunityName}
                    initiativeName={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].moduleName}
                    navigate={(type) => {
                        setMode(type);
                    }}
                />

            case 'featureRank':
                return <FeatureRank
                    program={programs[programIndex]}
                    opportunityIndex={opportunityIndex}
                    initiativeIndex={inititiveIndex}
                    featureIndex={featureIndex}
                    updateProgram={updateProgram}
                    setFeatureIndex={(index) => setFeatureIndex(index)}
                    onCacel={() => {
                        if (!featureFromTree) {
                            setColView(false);
                            setMode('feature');
                        } else {
                            setColView(true);
                        }
                    }}
                    projectName={reducer.dataReducer.project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    programId={programs[programIndex]._id}
                    opportunitieName={programs[programIndex].opportunities[opportunityIndex].opportunityName}
                    initiativeName={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].moduleName}
                    featureName={programs[programIndex].opportunities[opportunityIndex].modules[inititiveIndex].features[featureIndex].feature}
                    navigate={(type) => {
                        setMode(type);
                    }}
                />
            default:
                return null
        }
    }

    function onSaveOpportunity(opportunity) {
        let p = programs[programIndex];
        p.opportunities.push(opportunity);
        Axios.put(`${config.api}/programs/${programs[programIndex]._id}`, p).then(x => {
            let pCopy = _.clone(programs)
            pCopy[programIndex] = x.data;
            setPrograms(pCopy);
            message.success(`${rankingHierarchy[2].s} created successfully!`);
        });
    }

    function onSaveInitiative(initiative) {
        let p = programs[programIndex];
        p.opportunities[opportunityIndex].modules.push(initiative);

        Axios.put(`${config.api}/programs/${programs[programIndex]._id}`, p).then(x => {
            let pCopy = _.clone(programs)
            pCopy[programIndex] = x.data;
            setPrograms(pCopy);
            message.success(`${rankingHierarchy[3].s} created successfully!`);
        });
    }

    function onSaveFeature(feature) {
        let p = programs[programIndex];
        p.opportunities[opportunityIndex].modules[inititiveIndex].features.push(feature);

        Axios.put(`${config.api}/programs/${programs[programIndex]._id}`, p).then(x => {
            let pCopy = _.clone(programs)
            pCopy[programIndex] = x.data;
            setPrograms(pCopy);
            message.success(`${rankingHierarchy[4].s} created successfully!`);
        });
    }

    function cancelModal() {
        setShowModal(false)
    }



    return (
        <div>
            {
                colView ?
                    <LaunchPad
                        programs={programs}
                        colView={colView}
                        setColView={() => {
                            setMode('program');
                            setColView(false)
                        }}
                        onFeatureSelect={(index) => {
                            setColView(false)
                            setMode('featureRank');
                            setFeatureIndex(index);
                            setFeatureFromTree(true);
                        }}
                        loading={loading}
                        onOpportunitySelect={(index) => {
                            setOpportunityIndex(index)
                        }}
                        onProgramSelect={(index) => {
                            setProgramIndex(index)
                        }}
                        onInitiativeSelect={(index) => setInititiveIndex(index)}
                        indexes={
                            {
                                programIndex,
                                opportunityIndex,
                                inititiveIndex,
                                featureIndex
                            }
                        }
                        showAll={(type) => {
                            setColView(false)
                            setMode(type);
                        }}
                        setShowModal={() => setShowModal(true)}
                    />
                    :
                    <div className="">
                        {/* animate__animated animate__fadeInLeftBig */}
                        {
                            renderMode()
                        }
                    </div>
            }

            <AddModel
                title="Upload Excel"
                visible={showModal}
                handleCancel={cancelModal}
            >
                <UploadPrograms
                    projectId={projectId}
                    token={Axios.defaults.headers.Authorization}
                    handleError={cancelModal}
                    handleSuccess={() => {
                        attachProgram();
                        cancelModal();
                    }}
                />
            </AddModel>
        </div>
    );
};

PRHome.propTypes = {
    reducer: PropTypes.object
}

function mapStateToProps(state) {
    return {
        reducer: state
    };
}


export default connect(
    mapStateToProps
)(PRHome);
