import React from 'react';
import Tile from './Tile';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
const KpiList = ({ arr, title, nameKey, descriptionKey, index, onSelect, onClick }) => {
    return (
        <div className="kpi-list">
            <Tile
                count={arr.length}
                title={title}
                onClick={onClick}
            />
            <ol className="the-list">
                {arr.map((item, i) =>
                    <li key={i} className={index === i ? 'active' : ''} onClick={() => onSelect(i)} >
                        <div>
                            <span>{item[nameKey]}</span>
                            <span className="chevron">
                                <RightOutlined />
                            </span>
                        </div>
                        <p>{
                            item[descriptionKey]
                        }</p>
                        <p>
                            {
                                item.start_date &&
                                <span className="date">
                                    {moment(item.start_date).format("DD, MMM YYYY")} | {item.no_of_days} days
                                </span>
                            }
                        </p>
                    </li>
                )}
            </ol>
        </div>
    );
};

KpiList.propTypes = {
    arr: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    nameKey: PropTypes.string.isRequired,
    index: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    descriptionKey: PropTypes.string
}

export default KpiList;