import React, { useContext } from 'react';
import ModuleTile from '../../../components/common/Tiles/ModuleTile/ModuleTile';
import CompanyHomeEmpty from '../CompanyHome/CompanyHomeEmpty';
import './company-home.scss';
import assesmentImg from '../../../assets/icons/assesment_module.png';
import dashboardSvg from '../../../assets/icons/dashboard.svg';
// import tutorialsImg from '../../../assets/icons/tutorials.png';
import usersImg from '../../../assets/icons/users.svg';
import helpImg from '../../../assets/icons/help.png';
import { CustomerAdminContext } from '../CustomerAdminContext';

const CompanyHome = () => {

    const { rankingHierarchy } = useContext(CustomerAdminContext);

    return (
        <div className="projects-page company-home-page">
            <div className="top">
                <CompanyHomeEmpty rankingHierarchy={rankingHierarchy} subText="Manage the design thinking journey for your organization." />
            </div>
            <div className="module-assigned" style={{ display: 'flex', flexDirection: 'row', marginTop: 80, marginLeft: 40 }}>
                <ModuleTile
                    imgLink={dashboardSvg}
                    title={`Dashboard`}
                    subText={`View your company's progress`}
                    linkTo="/company-admin/dashboard"
                />
                <ModuleTile
                    imgLink={assesmentImg}
                    title={`${rankingHierarchy[0].p}`}
                    subText={`Create and/or view ${rankingHierarchy[0].s} associated to this account`}
                    linkTo="/company-admin/projects"
                />
                <ModuleTile
                    imgLink={usersImg}
                    title="Users"
                    subText="View, add, edit or delete users from the system"
                    linkTo="/company-admin/users"
                />

                <ModuleTile
                    imgLink={helpImg}
                    title="Help"
                    subText="Setup the Nectar application to suit your needs"
                    linkTo="/company-admin/help"
                />

            </div>
        </div>
    );
};

export default CompanyHome;