import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const AddTile = ({type,onNewClick}) => {
    return (
        <div className="subs-tile tile" style={{paddingRight: 20, marginTop: 20, marginRight: 20}}>
            <div className="new-subs">
                <span>New {capitalizeFirstLetter(type)}</span>
            </div>
            <span className="small-txt">Click here to add new {type}</span>
            <div>
                <Button onClick={onNewClick} type="primary" className="custom-add">Add {type}</Button>
            </div>
        </div>
    );
};

AddTile.propTypes = {
    type: PropTypes.string.isRequired,
    onNewClick: PropTypes.func.isRequired
}

export default AddTile;