import React from 'react';
import { MC,HeadText,HeadPart,Bullet,Per,BuletText,MTX,SubText } from '../../elements/elements';
import { Progress } from 'antd';
import PropTypes from 'prop-types';

const MaturityResultCard = (props) => {
    return (
      <MC onClick={props.openModal}>
        <HeadPart>
          <HeadText>{props.name}</HeadText>
          <SubText>{props.index}/{props.noS}</SubText>
        </HeadPart>
        <div>
            <Progress className="large-progress" type="circle" percent={props.thresholdPer} width={100} />
            <Progress className="small-progress" type="circle" percent={props.maturityPer} width={80} />
        </div>
        <div>
            <MTX>
                <Per>{props.maturityPer}%</Per>
                <Bullet red></Bullet>
                <BuletText>Assessment Percentage</BuletText>
            </MTX>
            <MTX>
                <Per>{props.thresholdPer}%</Per>
                <Bullet green></Bullet>
                <BuletText>Threshold Percentage</BuletText>
            </MTX>
            <div></div>
        </div>
      </MC>
    );
};

MaturityResultCard.propTypes = {
    name: PropTypes.string,
    index: PropTypes.string,
    noS: PropTypes.string,
    noQ: PropTypes.string,
    maturityPer: PropTypes.string,
    thresholdPer: PropTypes.string,
    openModal: PropTypes.func
}

export default MaturityResultCard;