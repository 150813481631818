import React, {useContext} from 'react';
import './tile.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlxHrSB } from '../../../../../components/elements/elements';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {  Modal } from 'antd';
import {CustomerAdminContext} from '../../../CustomerAdminContext';

const { confirm } = Modal;

function countObj(opportunities){
    let featureCount = 0;
    const opportunityCount =  opportunities.reduce((acc, opportunity) => {
        for(let i = 0; i< opportunity.modules.length; i++){
            featureCount += opportunity.modules[i].features.length;
        }
        return acc + opportunity.modules.length;
    }, 0);

    return {opportunityCount , featureCount};

}

const ProgramTile = ({ program, onClick, editProgram, deleteProgram}) => {
    const {opportunityCount, featureCount} = countObj(program.opportunities);
    const {rankingHierarchy} = useContext(CustomerAdminContext);

    function showConfirm() {
        confirm({
          title: `Are you sure you want to delete this ${rankingHierarchy[1].s.toLowerCase()}?`,
          icon: <ExclamationCircleOutlined />,
          content: `You will not be able to recover this ${rankingHierarchy[1].s.toLowerCase()}`,
          okText: 'Yes',
          cancelText: 'No',
          centered: true,
          onOk() {
            deleteProgram();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    return (
        <div className="tile" onClick={onClick} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <FlxHrSB>
                  <span className="title">{program.programName}</span>
                  <div className="action-icons" style={{marginRight: 10}}>
                      <EditOutlined style={{marginRight: 10}} onClick={(e)=>{
                        editProgram();
                        e.stopPropagation();
                      }} />
                      <DeleteOutlined onClick={(e)=>{
                          showConfirm();
                          e.stopPropagation();
                      }} />
                  </div>
                </FlxHrSB>
                <div style={{height:20}}></div>
                <div className="row__tile">
                    <span>{rankingHierarchy[2].p}</span>-
                    <span>{program.opportunities.length}</span>
                </div>
                <div className="row__tile">
                    <span>{rankingHierarchy[3].p}</span>-
                    <span>{opportunityCount}</span>
                </div>
                <div className="row__tile">
                    <span>{rankingHierarchy[4].p}</span>-
                    <span>{featureCount}</span>
                </div>
            </div>

            <div style={{marginBottom: 15, display: 'flex', justifyContent: 'space-between'}}>
                <span style={{color: 'var(--text-color'}}>Created - {moment(program.createdAt).format('MMM DD yy')}</span>
                <span style={{color: 'var(--primary-color', marginRight: 10}}>Active</span>
            </div>
        </div>
    );
};

ProgramTile.propTypes ={
    program: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    editProgram: PropTypes.func.isRequired,
    deleteProgram: PropTypes.func.isRequired
}

export default ProgramTile;