import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import Axios from 'axios';
import config from '../../../../config/config';
import { useAuth0 } from '@auth0/auth0-react';
import { Breadcrumb, message } from 'antd';
import { useParams, useHistory, Link } from 'react-router-dom';
import ProjectDetails from '../../Project/ProjectForm/ProjectDetails';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/actions'
import PropTypes from 'prop-types';

const NewAssesment = (props) => {

    const { projectId } = useParams();
    const history = useHistory();
    const { user } = useAuth0();
    const [questionTypes, setQuestionTypes] = useState([]);
    const [assesmentInfo, setAssesmentInfo] = useState({
        assesment: { name: null, location: null, industry: null }
    });
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingQuestions, setLoadingQuestions] = useState(true);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);


    const { assesmentId } = useParams();

    function selectQuestion(question, questionIndex, questionType, questionTypeIndex, value) {
        let qt = _.clone(questionTypes)
        qt[questionTypeIndex].questions[questionIndex].selected = value;
        setQuestionTypes(qt);
    }


    useEffect(() => {
        Axios.get(`${config.api}/questionTypes`).then(resp => {
            setQuestionTypes(resp.data);
            setLoadingQuestions(false);
        });
        getAuth0Users();

    }, []);

    function editQuestions(index, qIndex, value) {
        let qt = _.clone(questionTypes)
        qt[index].questions[qIndex].question = value;
        setQuestionTypes(qt);
    }

    async function getAuth0Users() {
        const users = await Axios({
            method: 'POST',
            url: `${config.api}/auth0/auth0-users`,
            data: {
                db: user[`${config.auth0.audience}/user_metadata`].databaseName,
                userType: 'COMPANY_USER'
            }
        });
        setUsers(users.data);
        setLoadingUsers(false);
    }

    async function saveAssesment(form) {
        setLoading(true);
        let usersAssesment = [];
        if (form.data.users) {
            for (let i = 0; i < form.data.users.length; i++) {
                let ux = _.find(users, (u) => u.email === form.data.users[i])
                usersAssesment.push(ux);
            }
        }

        console.log(questionTypes);
        console.log(form.data);


        if (form.type === 'POST') {
            const dataX = {
                name: form.data.name,
                projectId: projectId,
                questions: questionTypes,
                location: form.data.location,
                type: form.data.type,
                users: usersAssesment,
                validUpto: form.data.validUpto,
                description: form.data.description
            }
            await Axios({
                method: 'POST',
                url: `${config.api}/assesments`,
                data: dataX
            });
            setLoading(false);
            message.success('Assesment Created Successfully');
            history.push({
                pathname: '/company-admin/maturity-assesments/' + projectId
            })
        } else {
            const dataX = {
                name: form.data.name,
                projectId: form.data.projectId,
                questions: form.data.questions,
                location: form.data.location,
                type: form.data.type,
                users: usersAssesment,
                validUpto: form.data.validUpto,
                description: form.data.description
            }
            await Axios({
                method: 'PUT',
                url: `${config.api}/assesments/${assesmentId}`,
                data: dataX
            });
            setLoading(false);
            message.success('Assesment Updated Successfully');
            history.push({
                pathname: '/company-admin/maturity-assesments/' + form.data.projectId
            })
        }
    }

    return (
        <div className="projects-page">
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-admin">Projects</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/company-admin/project/${props.reducer.dataReducer.project._id}`}>{props.reducer.dataReducer.project.projectName}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={{
                        pathname: `/company-admin/maturity-assesments/${props.reducer.dataReducer.project._id}`,
                        state: props.reducer.dataReducer.project
                    }}>Assesments</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    New Assessment
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="assesment-new">
                <ProjectDetails
                    questionTypes={questionTypes}
                    selectQuestion={selectQuestion}
                    assesmentInfo={assesmentInfo}
                    setAssesmentInfo={(e) => {
                        setAssesmentInfo(e.data);
                        saveAssesment(e);
                    }}
                    users={users}
                    loadingUsers={loadingUsers}
                    loadingQuestions={loadingQuestions}
                    assesmentId={assesmentId}
                    loading={loading}
                    setLoading={(s) => setLoading(s)}
                    project={props.reducer.dataReducer.project}
                    editQuestions={editQuestions}
                    updateQuestionTypes={(x) => setQuestionTypes(x)}
                />
            </div>
        </div>
    );
};


NewAssesment.propTypes = {
    actions: PropTypes.any,
    reducer: PropTypes.object
}


function mapStateToProps(state) {
    return {
        reducer: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAssesment);

