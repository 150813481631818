import { Col, Input, Row, Form, Button } from "antd"; //Popover
import React,{useState, useEffect} from "react";
import PropTypes from "prop-types";
// import { InfoCircleOutlined } from "@ant-design/icons";

const stateX = {
  validateMessages: {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  },
};

const rules = [
  {
    required: true,
  },
  {
    pattern: /[0-5]/,
    message: "Please enter a value between 1-5",
  },
];

const FeatureInputs = ({initialValues, submitResponse, inputChanged, onCacel}) => {
  const [form] = Form.useForm()
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if(initialValues){
      console.log(initialValues)
      form.setFieldsValue({responses: initialValues[0]})
    } else{
      form.setFieldsValue({responses: {}})
    }
   }, [form, initialValues])
  return (
    <Form
      form={form}
      name="nest-messages"
      autoComplete="off"
      className="onboard-form"
      onFinish={(e) => {
        setDisabled(true);
        submitResponse(e.responses)
      }}
      validateMessages={stateX.validateMessages}
      onValuesChange={inputChanged}
      // initialValues={{
      //   responses: initialValues === undefined ? null : initialValues[0],
      // }}
    >
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "dependency"]}
            label="Dependency"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="Does the feature have dependencies on other systems, third party platforms, etc.?">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "competitive"]}
            label="Competitive"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How does the feature compare to similar competitive features?">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "innovation"]}
            label="Innovation"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="Does the feature significantly advance capabilities relative to competitors and other retailers in the marketplace?">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "implementation"]}
            label="Implementation"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How complex is the implementation of the feature? How long will the feature take to implement? ">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "maintainance"]}
            label="Maintainance"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How difficult will this capability / feature be to maintain over time?">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "cost"]}
            label="Cost"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="What is the estimated cost to develop the solution?">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "reusability"]}
            label="Reusability"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="Can this functionality be reused for other apps or capablities">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "audienceSize"]}
            label="Audience Size"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="Can this functionality be reused for other apps or capablities">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "strategic"]}
            label="Strategic"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How well does this capability/feature meet the strategic objectives">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "duration"]}
            label="Duration"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How long will this capability / feature take to implement.">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Form.Item
            name={["responses", "integration"]}
            label="Integration"
            validateTrigger="onBlur"
            rules={rules}
          >
            <Input type="number" max={5} placeholder="Enter between 1-5" />
            {/* <Popover content="How complex is the integration of the feature to other systems required to deliver the feature (i.e. order processing, fulfillment, etc.)">
              <InfoCircleOutlined className="feature_rank_tooltip" />
            </Popover> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit" disabled={disabled}>
            {disabled ? "Saving Scores..." : "Save Scores"}
          </Button>
          <Button style={{ marginLeft: 10 }} type="default" onClick={onCacel}>
            Cancel
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

FeatureInputs.propTypes = {
    submitResponse: PropTypes.func.isRequired,
    initialValues: PropTypes.any,
    inputChanged: PropTypes.func.isRequired,
    onCacel: PropTypes.func.isRequired,
};

export default FeatureInputs;
