import React from 'react';
import { Skeleton } from 'antd';
import './subs-tile.scss';



const SubsTileLoading = () => {
    return (
        <div className="subs-tile">
            <div>
                <Skeleton paragraph={{ rows: 3 }} active={true} size={'small'} />
            </div>
            <div className="images">
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            </div>
        </div>
    );
};

export default SubsTileLoading;