import styled,{css} from 'styled-components';

export const DefaultRoundButton = styled.div`
  background: var(--gray2);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #afafaf;
  font-size: 20px;
  pointer-events: none;
  cursor: not-allowed;
  ${(props) =>
    props.active && css`
      background: #f7981d;
      color: #fff;
  `}
  ${(props) =>
    props.completed && css`
     pointer-events: all;
     cursor: pointer;
  `}
`;


export const NeoButton = styled.button`
  border:none;
  outline:none;
  float: left;
  margin: 0 10px 0 10px;
  border-radius: 3px;
  min-height: 40px;
  :disabled{
    color: #a5a4a4;
    cursor: not-allowed;
  }
  cursor: pointer;
  /* box-shadow:  10px 10px 20px #c8cacf, -10px -10px 20px rgba(255, 255, 255, 0.53); */
  padding:10px 30px 10px 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  ${(props) =>
    props.primary && css`
      background: #f7981d;
      color: #FFF;
  `}
`;

export const NeoFillButton = styled.button`
  border-radius: 30px;
  background: #ff9e00;
  box-shadow: 9px 9px 18px rgb(217 134 0 / 30%), -9px -9px 18px rgb(255 182 0 / 10%);
  border:none;
  outline:none;
  float: left;
  margin: 0 10px 0 10px;
  border-radius: 30px;
  min-height: 40px;
  padding:10px 30px 10px 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #000;
  cursor: pointer;
`



export const WelcomeUser = styled.div`
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ff9e00;
  padding:20px;
`

export const CompanyLogo = styled.img`
  min-height: 56.9px;
  max-height: 56px;
`

export const ButtonArea = styled.div`
  float:right
`

export const QuestionHead = styled.div`
  font-size: 24px;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-color);
  margin-top: 20px;
  display: flex;
  align-items: center;
`

export const Logo = styled.div`
    height: 60px;
    width: 60px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 50%;
    padding: 12px;
    background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
    float:left;
    display: flex;
    justify-content: center;
    align-items: center;
`


export const ProcessComp = styled.div`
    border-radius: 20px;
    background: #F6F6FA;
    box-shadow:  14px 14px 34px #d1d1d5, -14px -14px 34px #ffffff;
    height:217px;
    width:300px;
    margin-right:20px;
    float:left;
`
export const Img = styled.img`
    width: 300px;
    height: 126px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

export const Info = styled.div`
  border-radius: 34px;
  background: #f6f6fa;
  box-shadow: 3px 3px 6px #d1d1d5, -3px -3px 6px #ffffff;
  height: 31px;
  width: 31px;
  float: right;
  margin-right: 26px;
  margin-top: -4px;
  padding-top: 5px;
`;
export const Controls =  styled.div`
        text-align: center;
        margin-top: 10px;
        width: 100%;
        button{
            margin-left: 57px;
}
`


export const Text = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #575f6b;
  text-align:center;
  padding-top: 17px;
`

export const ModuleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`


export const FlexContainer = styled.div`
      /* padding: 0 80px 80px 80px; */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 1366px;
      /* margin: auto; */
`

export const FlexBox = styled.section`
  display:flex;
  ${(props) =>
    props.center && css`
     align-items: center;
     justify-content: center;
  `}

  ${(props) =>
    props.flexDirection && css`
      flex-direction: ${props.flexDirection};
  `}

  ${(props) =>
    props.height && css`
      min-height: ${props.height};
  `}

  ${(props) =>
    props.justifyContent && css`
      justify-content: ${props.justifyContent};
  `}

  ${(props) =>
    props.alignItems && css`
      align-items: ${props.alignItems};
  `}
  
`


export const TopArea = styled.div`

`;

export const ContentArea = styled.div`
  min-height:48vh;
  /* overflow-y: auto; */
`;

export const BottomArea = styled.div`

`;

export const ProjectDetailsComp = styled.div`
    /* padding: 0 80px 80px 80px; */
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const ResultArea = styled.div`
  display: flex;
  flex-direction: row;
`

export const MaturityArea = styled.div`
  
`

export const AccumudateArea = styled.div`
      
    flex: 1;
    min-height: 70vh;
    flex-direction: row;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: left;
` 


export const MC = styled.div`
  background: var(--background);
  display: flex;
  height: 270px;
  width: 270px;
  border-radius: 4px;
  /* background: linear-gradient(145deg,#e6eaf2,#f5f5f9); */
  box-shadow: var(--shadow);
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* margin: 20px 20px 20px 0; */
  cursor: pointer;
`;
export const HeadText = styled.h2`
    font-family: 'Lato';
    text-align: left;
    font-size:16px;
    font-weight:bold;
    color: var(--text-color);
`
export const HeadPart = styled.div`
    display: flex;
    width: 100%;
    padding: 0 20px 0 20px;
    justify-content: space-between;
`
export const Bullet = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    ${(props) =>  props.red && css`
        background-color: #ff2e77;  
    `}
    ${(props) =>  props.green && css`
        background-color: #8DC63F;  
    `}
    ${(props) =>  props.yellow && css`
      background-color: #ff9e00;
    `}
    ${(props) =>  props.blue && css`
      background-color: #0092b9;
    `}
`
export const Per = styled.span`
  /* font-size: 18px; */
  /* font-weight: bold; */
  text-align: left;
  color: var(--text-color);
  margin-right: 10px;
`
export const BuletText = styled.span`
  font-size: 12px;
  text-align: left;
  color: var(--text-color);
  font-weight:500;
`
export const MTX = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const SubText = styled.span`
  color: var(--gray1);
  font-size:12px;
`

export const SVGImage = styled.img`
  height: 85px;
  margin-right:10px;
`

export const PMBoxContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export const YellowHead = styled.h1`
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ff9e00;
`

export const FlxHrSB = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const InfoText = styled.span`
  color: var(--text-color);
`