import { Breadcrumb, Button } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { InfoText } from '../../../../components/elements/elements';
import PropTypes from 'prop-types';
import './list.scss';
import AddTile from './Tiles/AddTile';
import { MenuOutlined } from '@ant-design/icons';
import FeatureTile from './Tiles/FeatureTile';
import AddFeature from './AddFeature';
import {CustomerAdminContext} from '../../CustomerAdminContext';


const Features = ({features, setColView, onSaveFeature, onSelectFeature, configs, deleteFeature, editFeature, projectId, projectName, programName, opportunitieName, navigate, initiativeName}) => {
    const [mode,setMode] = useState('view');
    const {rankingHierarchy} = useContext(CustomerAdminContext);
    
    return (
        <div className="projects-page">
            {
                mode === 'view' ?
                <section>
                    
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/company-admin">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/company-admin/projects">
                                {rankingHierarchy[0].p}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('program')}>
                            <a>{programName}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('opportunity')}>
                            <a>{opportunitieName}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('inititive')}>
                            <a>{initiativeName}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {rankingHierarchy[4].p}
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h1>{rankingHierarchy[4].p}</h1>
                        <Button type="default" title="List View" onClick={setColView}>
                            <MenuOutlined /> Show as Grid
                        </Button>
                    </div>
                    <InfoText>
                        You have {features.length} <span style={{color: 'var(--primary-color)'}}>active</span> {rankingHierarchy[4].s.toLowerCase()}(s).
                    </InfoText>
                    <div className="list-area">
                        {
                            features.map((feature, index) => <FeatureTile 
                                onClick={()=>{onSelectFeature(index)}} 
                                feature={feature} 
                                key={index} 
                                configs={configs}
                                deleteFeature={()=>deleteFeature(index)}
                                editFeature={()=>editFeature(index)}
                            />)
                        }
                        <AddTile type={rankingHierarchy[4].s} onNewClick={()=>setMode('add')} />
                    </div>
                </section>
                : 
                <AddFeature 
                    onCancel={()=>{
                        setMode('view')
                    }}
                    onSaveFeature={(data)=>{
                        onSaveFeature(data);
                        setMode('view')
                    }}
                    projectId={projectId}
                    projectName={projectName}
                    programName={programName}
                    opportunitieName={opportunitieName}
                    initiativeName={initiativeName}
                    navigate={navigate}
                />
            }
        </div>
    );
};

Features.propTypes = {
    features: PropTypes.array.isRequired,
    setColView: PropTypes.func.isRequired,
    onSaveFeature: PropTypes.func.isRequired,
    onSelectFeature: PropTypes.func.isRequired,
    configs: PropTypes.object.isRequired,
    deleteFeature: PropTypes.func.isRequired,
    editFeature: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    opportunitieName: PropTypes.string.isRequired,
    initiativeName: PropTypes.string.isRequired
}

export default Features;