import React, { useContext } from 'react';
import './tile.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlxHrSB } from '../../../../../components/elements/elements';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import moment from 'moment';
import { Modal } from 'antd';
import { CustomerAdminContext } from '../../../CustomerAdminContext';

const { confirm } = Modal;

function countObj(modules) {
  const featureCount = modules.reduce((acc, module) => {
    return acc + module.features.length;
  }, 0);

  return featureCount;

}

function opportunityRank(opportunity, masterScores) {
  if (opportunity.modules === undefined || opportunity.modules.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < opportunity.modules.length; i += 1) {
    const moduleR = moduleRank(opportunity.modules[i], masterScores);
    sum += parseFloat(moduleR);
  }
  return (sum / opportunity.modules.length).toFixed(1);
}

function moduleRank(module, masterScores) {
  if (module.features === undefined || module.features.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < module.features.length; i += 1) {
    const fa = featureRank(module.features[i], masterScores);
    const isNumber = (value) => !Number.isNaN(Number(value));
    sum += !isNumber(fa) ? 0 : parseFloat(fa);
  }
  return (sum / module.features.length).toFixed(1);
}
function featureRank(feature, masterScores) {
  if (feature.responses === undefined) {
    return 0;
  }
  const resp = Object.keys(feature.responses[0]);
  let sum = 0;
  for (let i = 0; i < resp.length; i += 1) {
    sum += (parseFloat(masterScores[resp[i]]) / 100) * parseFloat(feature.responses[0][resp[i]]);
  }
  return parseFloat(sum / resp.length).toFixed(1);
}

const OpportunityTile = ({ opportunity, onClick, configs, editOpportunity, deleteOpportunity, accessFrom, showPrioritization }) => {
  const { rankingHierarchy } = useContext(CustomerAdminContext);
  function showConfirm() {
    confirm({
      title: `Are you sure you want to delete this ${rankingHierarchy[2].s.toLowerCase()}?`,
      icon: <ExclamationCircleOutlined />,
      content: `You will not be able to recover this ${rankingHierarchy[2].s.toLowerCase()}`,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteOpportunity();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function navigateTo() {
    if (accessFrom === 'IR') {
      onClick();
    } else {
      //
      showPrioritization();
    }
  }

  return (
    <div className="tile" onClick={navigateTo} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div>
        <FlxHrSB>
          <span className="title">{opportunity.opportunityName}</span>
          <div className="action-icons" style={{ marginRight: 10 }}>
            <EditOutlined style={{ marginRight: 10 }} onClick={(e) => {
              editOpportunity();
              e.stopPropagation();
            }} />

            <DeleteOutlined onClick={(e) => {
              showConfirm();
              e.stopPropagation();
            }} />
          </div>
        </FlxHrSB>

        <div style={{ height: 20 }}></div>
        <div className="row__tile">
          <span>{rankingHierarchy[3].p}</span>-
          <span>{opportunity.modules.length}</span>
        </div>
        <div className="row__tile">
          <span>{rankingHierarchy[4].p}</span>-
          <span>{countObj(opportunity.modules)}</span>
        </div>
        <div className="">
          <span style={{ fontWeight: 'bold', fontSize: 18 }}>{
            (parseFloat(opportunityRank(opportunity, configs))).toFixed(1)
          }</span>
          <span> {rankingHierarchy[2].s} Rank</span>
        </div>
      </div>

      <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ color: 'var(--text-color' }}>
          Created - {moment(opportunity.createdAt.replaceAll('.', ':')).format('MMM DD yy')}
        </span>
        <span style={{ color: 'var(--primary-color', marginRight: 10 }}>Active</span>
      </div>
    </div>
  );
};

OpportunityTile.propTypes = {
  opportunity: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  editOpportunity: PropTypes.func.isRequired,
  deleteOpportunity: PropTypes.func.isRequired,
  accessFrom: PropTypes.string,
  showPrioritization: PropTypes.func
}

export default OpportunityTile;