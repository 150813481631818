import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import './adp.scss';
import { NeoButton } from '../../../../../components/elements/elements';

const options = [
  { name: 'Weekly', val: 'W' },
  { name: 'Monthly', val: 'M' },
  { name: 'Quarterly', val: 'Q' },
];

const AdvanceSettingsModal = ({ configs, changeConfig, handleCancel }) => (
  <div>
    <div className="p-form-area">
      {
        Object.keys(configs).map((config) => (
          config !== 'timeUnit'
            ? (
              <div className="p-fl-item" key={`${config}`}>
                <div className="p-inp-group">
                  <label htmlFor="p-dep">{
                    config === 'audienceSize'? 'Audience Size': config
                  }</label>
                  <Input
                    className="neo"
                    id="dep"
                    type="number"
                    placeholder="Program Name"
                    value={configs[config]}
                    onChange={(e) => changeConfig(config, e.target.value)}
                  />
                </div>
              </div>
            )
            : (
              <div className="p-fl-item" key={`${config}`}>
                <div className="p-inp-group">
                  <label style={{width:300}} htmlFor="p-dep-2">Time Unit</label>
                  <Select style={{width:338}} value={configs[config]} onChange={(val) => changeConfig(config, val)}>
                    {
                      options.map((op) => (
                        <Select.Option
                          key={op.val}
                          value={op.val}
                        >
                          {op.name}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
              </div>
            )
        ))
      }
    </div>
    <hr className="devider" />
    <div className="pf-button-area">
      <NeoButton primary onClick={handleCancel}>
        Ok
      </NeoButton>
    </div>
  </div>
);

AdvanceSettingsModal.propTypes = {
  configs: PropTypes.shape({
    dependency: PropTypes.number,
  }),
  changeConfig: PropTypes.func,
  handleCancel: PropTypes.func,
};

AdvanceSettingsModal.defaultProps = {
  configs: {},
  changeConfig: () => {},
  handleCancel: () => {},
};

export default AdvanceSettingsModal;
