/* eslint-disable react/jsx-filename-extension */
import React, { useState, useContext } from 'react';
import _ from 'underscore';
import { Button, Col, Input, message, Row, Form, Breadcrumb } from 'antd';
import Axios from 'axios';
import { SettingOutlined } from '@ant-design/icons';
import config from '../../../../config/config';
import AddModel from '../Dashboard/AddModel';
import AdvanceSettingsModal from '../Programs/AddProgram/AdvanceSettingsModal.jsx';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import './add-p.scss';
import { Link } from 'react-router-dom';
import {CustomerAdminContext} from '../../CustomerAdminContext';



const initConfigs = {
  dependency: 100,
  competitive: 130,
  innovation: 85,
  implementation: 100,
  integration: 100,
  maintainance: 85,
  cost: 100,
  reusability: 85,
  audienceSize: 125,
  strategic: 140,
  duration: 120,
  timeUnit: 'Q',
};

const stateX = {
  validateMessages:  {
      required: '${label} is required!',
      types: {
        email: '${label} is not validate email!',
        number: '${label} is not a validate number!',
      },
      number: {
        range: '${label} must be between ${min} and ${max}',
      },
  }
}

const AddProgram = ({onAddProgram, onCacel, program, type, projectId, projectName }) => {
  const [advanceModal, setAdvanceModal] = useState(false);
  const [configs, setConfigs] = useState(initConfigs);
  const [disable, setDisable] = useState(false);
  const {rankingHierarchy} = useContext(CustomerAdminContext);
  const [buttonTxt, setButtonTxt] = useState('Save Program');
  
  function saveProgram(data) {

    let dataProgram = null;

    if(type === 'PUT') {
      dataProgram = {
        programName: data.program.programName,
        description: data.program.description,
        kpi: data.program.kpi,
        objective: data.program.objective,
        opportunities: program.opportunities,
        config: configs,
        jiraProject: program.jiraProject,
        projectId: program.projectId,
        status: program.status,
      }
    } else {
      dataProgram = {
        ...data.program,
        configs,
        projectId: projectId,
      }
    }

    setButtonTxt(`Saving ${rankingHierarchy[1].s}...`);
    setDisable(true);
    Axios({
      url:`${config.api}/programs/${type === 'PUT' ? program._id : ''}`,
      method: type,
      data:dataProgram
    }).then((resp) => {
      setButtonTxt(`Save ${rankingHierarchy[1].s}`);
      setDisable(false);
      message.success(`${rankingHierarchy[1].s} saved successfully!`);
      onAddProgram(resp.data);
    });
  }

  function changeConfig(key, val) {
    const newConfigs = _.clone(configs);
    newConfigs[key] = val;
    setConfigs(newConfigs);
  }

  return (
    <div className={type === 'PUT' ? `projects-page add-program` : 'add-program'}>


        <Breadcrumb separator=">">
            <Breadcrumb.Item>
                <Link to="/company-admin">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/company-admin/projects">
                  {rankingHierarchy[0].s}
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={onCacel}>
                <a>
                  {rankingHierarchy[1].s}
                </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                {type === 'PUT' ? `Edit ${program.programName}` : `Create ${rankingHierarchy[1].s}`}
            </Breadcrumb.Item>
        </Breadcrumb>
        <h1>
          {type === 'PUT' ? `Edit ${program.programName}` : `Create ${rankingHierarchy[1].s}`}
        </h1>

        <div className="form">
            <Form
                name="nest-messages"
                autoComplete="off"
                className="onboard-form"
                onFinish={saveProgram}
                validateMessages={stateX.validateMessages}
                initialValues={{
                  program: program
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item name={['program', 'programName']} label={`${rankingHierarchy[1].s} Name`} rules={[{ required: true }]}>
                            <Input placeholder={`Please enter ${(rankingHierarchy[1].s).toLowerCase()} name`} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['program', 'type']} label={`${rankingHierarchy[1].s} Type`} rules={[]}>
                            <Input placeholder={`Please enter ${(rankingHierarchy[1].s).toLowerCase()} type`} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                  {/* <Col span={8}>
                      <Form.Item name={['program', 'kpi']} label="KPI" rules={[{ required: true }]}>
                          <Select
                              placeholder="Select a option from"
                              >
                              <Option value="IT">New Category</Option>
                              <Option value="Car &amp; Automobile">Car &amp; Automobile</Option>
                              <Option value="Finencial &amp; Banking">Finencial &amp; Banking</Option>
                              <Option value="Real Estate">Real Estate</Option>
                              <Option value="Telecommunication">Telecommunication</Option>
                              <Option value="Pharmaceutical">Pharmaceutical</Option>
                              <Option value="Healthcare">Healthcare</Option>
                              <Option value="Aerospace">Aerospace</Option>
                              <Option value="Agriculture">Agriculture</Option>
                          </Select>
                      </Form.Item>
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name={['program', 'behaviourData']} label="Behavior Data" rules={[]}>
                        <Input type="text" placeholder="Please enter the behavior data" />
                    </Form.Item>  
                  </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item name={['program', 'description']} label="Description" rules={[]}>
                            <TextArea placeholder="Please enter program description" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['program', 'objective']} label="Objective" rules={[]}>
                          <TextArea  placeholder="Please enter the objective" />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row>
                <Button type="link" onClick={() => setAdvanceModal(true)}>
                  <SettingOutlined />
                  Advanced Settings
                </Button>
                </Row>
                <Row>

                  <Form.Item style={{marginTop: 20}}>
                      <Button type="primary" htmlType="submit" disabled={disable}>
                        {buttonTxt}
                      </Button>
                      <Button style={{marginLeft: 10}} onClick={onCacel} type="default">Cancel</Button>
                  </Form.Item>
                </Row>
                
            </Form>
        </div>


      <AddModel
        title="Advanced Settings"
        visible={advanceModal}
        handleCancel={() => setAdvanceModal(false)}
      >
        <AdvanceSettingsModal
          configs={type === 'PUT' ? program.config : configs}
          changeConfig={changeConfig}
          handleCancel={() => setAdvanceModal(false)}
        />
      </AddModel>
    </div>
  );
};

AddProgram.propTypes ={
  onAddProgram: PropTypes.func.isRequired,
  onCacel: PropTypes.func.isRequired,
  program: PropTypes.object,
  type: PropTypes.string,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
}

AddProgram.defaultProps = {
  program: null,
  type: 'POST'
}

export default AddProgram;
