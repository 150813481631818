import React from 'react';
import { Button, Modal, Progress } from 'antd';
import { Bullet, Per, BuletText, MTX } from '../../../components/elements/elements';
import Spider from '../../../components/common/Graphs/Spider';
import styled from 'styled-components';
import _ from 'underscore';
import PropTypes from 'prop-types';


const FXS = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom:15px;
`
const QS = styled.div`
  color: var(--text-color);
  font-size: 14px;
`
const Score = styled.div`

`

const EmpText = styled.div`
  color: var(--text-color);
  font-size:14px;
  text-transform: uppercase;
  font-weight: bold;
`

const MaturityDetailsModal = ({ visible, onOk, onCancel, modalDetails, noOfSets }) => {


  function generateData(qs) {
    qs = _.filter(qs, x => x.selected);
    console.log(qs)
    let points = {}
    for (let i = 0; i < qs.length; i++) {
      points[`Q${i + 1}`] = qs[i].value / 5
    }

    return {
      data: points,
      meta: { color: '#ff9e00' }
    }
  }

  function generateCaption(qs) {
    let q = {};
    qs = _.filter(qs, x => x.selected);
    for (let i = 0; i < qs.length; i++) {

      q[`Q${i + 1}`] = `Q${i + 1}`

    }

    return q;
  }


  return (
    <Modal
      title={modalDetails.questionType}
      centered
      className="md-modal"
      visible={visible}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
    >
      <div className="md-top-area">
        <div className="md-progres">
          <Progress className="large-progress" type="circle" percent={modalDetails.thresold} width={130} />
          <Progress className="small-progress" style={{ marginLeft: '-120px' }} type="circle" percent={modalDetails.percantage.toFixed(0)} width={110} />
        </div>
        <div className="md-label">

          <MTX>
            <Per>{modalDetails.percantage.toFixed(0)}%</Per>
            <Bullet red></Bullet>
            <BuletText>Assessment Percentage</BuletText>
          </MTX>
          <MTX>
            <Per>{modalDetails.thresold}%</Per>
            <Bullet green></Bullet>
            <BuletText>Threshold Percentage</BuletText>
          </MTX>
        </div>
      </div>
      <div className="md-line" />
      <div className="md-top-area">
        <div className="md-progres spider">
          <Spider
            data={generateData(modalDetails.questions)}
            captions={generateCaption(modalDetails.questions)}
            size={350}
          />
        </div>
        <div className="md-label">
          {
            modalDetails.questions.map((qs, index) => (
              qs.selected &&
              <FXS key={index}>
                <QS>
                  Q{index + 1}: {qs.question}
                </QS>
                <Score>
                  <div className="score-round-button">
                    {qs.value}
                  </div>
                </Score>
              </FXS>
            ))
          }
        </div>
      </div>

      <div className="md-top-area">
        <div className="md-progres" >
          <MTX>
            <Bullet yellow></Bullet>
            <BuletText>Your Organization’s Score</BuletText>
          </MTX>
        </div>
        <div className="md-label extra-bottom">
          <EmpText>
            {modalDetails.questionType.toUpperCase()} SCORE
          </EmpText>
          <Button type="default">
            {
              _.reduce(modalDetails.questions, (memo, num) => (memo + num.value), 0)
            }/
            {
              (modalDetails.questions.length * 5) * noOfSets
            }
            {/* <i style={{paddingLeft:'10px'}} className="fa fa-arrow-right"></i> */}
          </Button>
        </div>
      </div>

    </Modal>
  );
};

MaturityDetailsModal.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  modalDetails: PropTypes.object,
  noOfSets: PropTypes.number
}


export default MaturityDetailsModal;