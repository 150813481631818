import React from 'react';
import './nav.scss';
import AppLogo from '../AppLogo/AppLogo';
import styled from 'styled-components'
import { Menu, Dropdown } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import config from '../../../config/config';


const ProfileArea = styled.div`
    float:right;
`
const ImgLogo = styled.img`
    margin: 0 15px 0 15px;
`

function Nav() {

    const { location, push } = useHistory();
    const { user, logout } = useAuth0();

    function onClick() {
        let newPath = location.pathname.split('/')[1];
        if (newPath === 'super-admin') {
            push('/super-admin');
        } else if (newPath === 'company-admin') {
            push('/company-admin');
        } else if (newPath === 'company-portal') {
            push('/company-portal');
        }
    }

    return (
        <div className="nav-bar">
            <div>
                <AppLogo onClick={onClick} />
            </div>

            <ProfileArea>

                <ImgLogo style={{ marginRight: -5, borderRadius: '50%', height: 30, width: 30 }} src={user.picture} alt="profile" />

                {
                    user &&
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item onClick={() => {
                                logout({ returnTo: window.location.origin })
                            }}>
                                <span>
                                    Logout
                                </span>
                            </Menu.Item>
                            {
                                user[`${config.auth0.audience}/roles`].includes('CustomerAdmin') && config.env === 'DEV' &&
                                <Menu.Item>
                                    <Link to="/company-admin/cleanup">Cleanup</Link>
                                </Menu.Item>
                            }
                        </Menu>

                    }>

                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <span style={{ marginLeft: '15px', color: "#0e1626" }}>{user.name}</span>
                            {/* <ImgLogo src={require('../../../assets/images/down.svg')} /> */}
                            <i className='fa fa-chevron-down caret'></i>
                        </a>
                    </Dropdown>
                }
            </ProfileArea>
        </div>
    );
}

export default Nav;