import React from 'react';
import '../SubsTile/subs-tile.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import {CheckOutlined, EllipsisOutlined} from '@ant-design/icons';
import './assessment-tile.scss';

const AssessmentTileCA = ({ assessment, onClick}) => {
    return (
        <div className="subs-tile asx-tile" onClick={onClick}>
            <div className="head-text">
                <span>{assessment.name}</span>
                <small>
                   {assessment.type}
                </small>
            </div>
            <span className="mods">
                Valid till: {moment(assessment.validUpto).format('MMM DD, YYYY')}
            </span>
            <span className={`mods asx-status-${assessment.count.status.toLowerCase()}`}>
                {
                    assessment.count.status === "COMPLETED" ? 
                    <><CheckOutlined />&nbsp; {assessment.count.completed}/{assessment.count.total} Participants Completed</>
                    :
                    <><EllipsisOutlined />&nbsp; {assessment.count.completed}/{assessment.count.total} Participants Completed</>
                }
            </span>
                
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                    {
                        assessment.users.slice(0,3).map((user, index)=>
                            <img 
                                title={user.name} 
                                src={user.picture} 
                                key={index} alt="user" 
                                className="user-image" 
                                style={
                                    index === 0 ? {height: 33, width: 33, borderRadius: '50%'}
                                    :
                                    {height: 33, width: 33, borderRadius: '50%',marginLeft: -10}
                                } 
                            />
                        )
                        
                    }
                    {
                        assessment.users.length > 3 &&
                        <span style={{fontWeight: 'normal', fontSize: 16, marginLeft:5}}>+{assessment.users.length - 3} more</span>
                    }
                </div>
            {
                assessment.createdAt &&
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{color: 'var(--text-color)', fontSize: 14, fontWeight: 'normal'}}>Created on- {moment(assessment.createdAt).format('MMM DD yy')}</span>
                    {
                        moment(assessment.validUpto).isBefore(moment()) ?
                        <span style={{color: '#FF0707', marginRight: 10, fontSize: 14, fontWeight: 'normal'}}>Expired</span>
                        :
                        <span style={{color: 'var(--primary-color)', marginRight: 10, fontSize: 14, fontWeight: 'normal'}}>Active</span>
                    }
                </div>
            }
        </div>
    );
};

AssessmentTileCA.propTypes ={
    assessment: PropTypes.object,
    onClick: PropTypes.func,
    userType: PropTypes.string
}

AssessmentTileCA.defaultProps = {
    userType: "CU"
}

export default AssessmentTileCA;