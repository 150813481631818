/* eslint-disable react/jsx-filename-extension */
import React, {useState, useContext} from 'react';
import { Button, Col, Input, Row , Form, Breadcrumb } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import './add-p.scss';
import { Link } from 'react-router-dom';
import {CustomerAdminContext} from '../../CustomerAdminContext';


const stateX = {
  validateMessages:  {
      required: '${label} is required!',
      types: {
        email: '${label} is not validate email!',
        number: '${label} is not a validate number!',
      },
      number: {
        range: '${label} must be between ${min} and ${max}',
      },
  }
}

const AddOpportunity = ({ opportunity, type, onSaveOpportunity, onCancel, projectName, programName,navigate, projectId }) => {

  
  const [disabled, setDisabled] = useState(false);
  const {rankingHierarchy} = useContext(CustomerAdminContext);

  function saveOpportunity(data) {
    setDisabled(true);
    if(type === 'PUT'){

      onSaveOpportunity({
        opportunityName: data.opportunity.opportunityName,
        description: data.opportunity.description,
        modules: opportunity.modules,
        createdAt: opportunity.createdAt,
      });

    } else {
      onSaveOpportunity({
        ...data.opportunity,
        modules:[],
        createdAt: new Date(),
      })
    }
    
  }


  return (
    <div className={type === 'PUT' ? `projects-page add-program` : 'add-program'}>

        <Breadcrumb separator=">">
            <Breadcrumb.Item>
                <Link to="/company-admin">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/company-admin/projects">
                  {rankingHierarchy[0].p}
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={navigate}>
                <a>{programName}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={()=>{
              navigate('');
              setTimeout(()=>{
                navigate('opportunity')
              },0)
            }}>
                <a>
                  {rankingHierarchy[2].p}
                </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Add  {rankingHierarchy[2].s}
            </Breadcrumb.Item>
        </Breadcrumb>
        <h1>
          {type === 'PUT' ? `Edit ${opportunity.opportunityName}` : `Create ${rankingHierarchy[2].s}`}
        </h1>

        <div className="form">
            <Form
                name="nest-messages"
                autoComplete="off"
                className="onboard-form"
                onFinish={saveOpportunity}
                validateMessages={stateX.validateMessages}
                initialValues={{
                  opportunity:opportunity
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item name={['opportunity', 'opportunityName']} label={`${rankingHierarchy[2].s} Name`}rules={[{ required: true }]}>
                            <Input placeholder={`Please enter ${rankingHierarchy[2].s} name`} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                  <Col span={8}>
                        <Form.Item name={['opportunity', 'description']} label="Description" rules={[]}>
                            <TextArea placeholder="Please enter description" />
                        </Form.Item>
                  </Col>
                </Row>

                
                <Row>

                  <Form.Item style={{marginTop: 20}}>
                      <Button type="primary" htmlType="submit" disabled={disabled}>
                        {disabled ? `Saving ${rankingHierarchy[2].s}...` : `Save ${rankingHierarchy[2].s}`}
                      </Button>
                      <Button style={{marginLeft: 10}} onClick={onCancel} type="default">Cancel</Button>
                  </Form.Item>
                </Row>
                
            </Form>
        </div>
    </div>
  );
};

AddOpportunity.propTypes ={
    onSaveOpportunity: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    opportunity: PropTypes.object,
    type: PropTypes.string,
    projectName: PropTypes.string,
    programName: PropTypes.string,
    navigate: PropTypes.func,
    projectId: PropTypes.string,
}

AddOpportunity.defaultProps = {
    opportunity: null,
    type: 'POST',
}

export default AddOpportunity;
