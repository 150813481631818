import { Breadcrumb, Button, message, Form, Input, Row, Col, Select, Skeleton } from 'antd';
import React,{useState, useEffect, useContext} from 'react';
import './create-project.scss';
import { Link,useHistory,useParams } from 'react-router-dom';
import Axios from 'axios';
import config from '../../../../config/config';
import ModuleCard from '../../../../components/common/ModuleCard/ModuleCard';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {CustomerAdminContext} from '../../CustomerAdminContext'


const stateX = {
    validateMessages:  {
        required: '${label} is required!',
        types: {
          email: '${label} is not validate email!',
          number: '${label} is not a validate number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    }
}


const CreateProject = () => {

    const history = useHistory();
    const {rankingHierarchy} = useContext(CustomerAdminContext);
    const [disabled, setDisabled] = useState(true);
    const [modules, setModules] = useState([]);
    const [project, setProject] = useState(null);
    const {projectId} = useParams();
    const [form] = Form.useForm();
    const [globalLoading, setGlobalLoading] = useState(false);
    const [buttonName, setButtonName] = useState(projectId ? `Update ${rankingHierarchy[0].s}` : `Create ${rankingHierarchy[0].s}`);



    async function onFinish(vals){
        if(!projectId){
            setDisabled(true);
            setButtonName(`Creating ${rankingHierarchy[0].s}...`);
            setButtonName(`Creating ${rankingHierarchy[0].s}...`);
            vals.project.modules = modules;
            await Axios.post(`${config.api}/projects`, vals);
            message.success(`${rankingHierarchy[0].s} created successfully`);
            history.push(`/company-admin/projects`);
        } else { //this is update
            setDisabled(true);
            setButtonName(`Updating ${rankingHierarchy[0].s}...`);
            vals.project._id = projectId;
            vals.project.modules = project.modules;
            await Axios.put(`${config.api}/projects/${projectId}`, vals.project);
            message.success(`${rankingHierarchy[0].s} updated successfully`);
            history.push(`/company-admin/project/${projectId}`);
        }
    }

    useEffect(() => {
        getModules();
        if(projectId){
            setGlobalLoading(true);
            Axios.get(`${config.api}/projects/${projectId}`).then(px=>{
                setDisabled(false);
                form.setFieldsValue({project: px.data})
                setProject(px.data);
                setGlobalLoading(false);
            })
        }
    } , []);


    async function getModules(){
        let modules = await Axios.post(`${config.api}/organizations/get-modules`).then(res => res.data);
        for(let i = 0; i < modules.length; i++){
            modules[i].selected = false;
        }
        setModules(modules)
    }

    function selectModule(index){
        let mods = _.clone(modules);
        mods[index].selected = !mods[index].selected;
        setModules(mods);
        setDisabled(false)
    }

    function selectModuleProject(index){
        let pCopy = _.clone(project);
        pCopy.modules[index].selected = !pCopy.modules[index].selected;
        setProject(pCopy);
        setDisabled(false)
    }

    if(!globalLoading){
        return (
            <div className="sub-page create-project">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to="/company-admin">
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/company-admin/projects">
                            {rankingHierarchy[0].p}
                        </Link>
                    </Breadcrumb.Item>
                    {
                        project && <Breadcrumb.Item>
                            <Link to={`/company-admin/project/${projectId}`}>{project.projectName}</Link>
                        </Breadcrumb.Item>
                    }
                    <Breadcrumb.Item>{ projectId ? `Edit ${rankingHierarchy[0].s}` : `Create ${rankingHierarchy[0].s}` }</Breadcrumb.Item>
                </Breadcrumb>
                <h1>{ projectId ? `Edit ${rankingHierarchy[0].s}` : `Create ${rankingHierarchy[0].s}` }</h1>
                <p>Please enter information regarding the new {rankingHierarchy[0].s.toLowerCase()} created.</p>
                <Form
                    name="nest-messages"
                    autoComplete="off"
                    form={form}
                    className="onboard-form"
                    onFinish={onFinish}
                    validateMessages={stateX.validateMessages}
                    // initialValues={initialVals}
                >
                    <Row>
                        <Col span={8}>
                            <Form.Item name={['project', 'projectName']} label={`${rankingHierarchy[0].s} Name`} rules={[{ required: true }]}>
                                <Input className="neo" placeholder={`Please enter ${rankingHierarchy[0].s.toLowerCase()} name`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Form.Item name={['project', 'location']} label="Location" rules={[{ required: true }]}>
                                <Input className="neo" placeholder="Please enter the location" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item className="industry" name={['project', 'industry']} label="Industry Type" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select a option from"
                                    // onChange={this.onGenderChange}
                                    >
                                    <Select.Option value="High Technology">High Technology</Select.Option>
                                    <Select.Option value="Insurance &amp; Healthcare">Insurance &amp; Healthcare</Select.Option>
                                    <Select.Option value="Financial Services">Financial Services</Select.Option>
                                    <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                                    <Select.Option value="Agricultural ">Agricultural</Select.Option>
                                    <Select.Option value="Utilities &amp; Energy">Utilities &amp; Energy</Select.Option>
                                    <Select.Option value="Retail">Retail</Select.Option>
                                    <Select.Option value="Consumer Goods">Consumer Goods</Select.Option>
                                    <Select.Option value="Life Sciences">Life Sciences</Select.Option>
                                    <Select.Option value="Education &amp; Institutions">Education &amp; Institutions</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                    
                    </Row>
                    <Row>
                        {
                            !project ?
                            <Col span={24} style={{display: 'flex'}}>
                                {
                                    modules.map((module, index)=>(
                                    module.status && <ModuleCard 
                                        module={module} 
                                        key={module.id} 
                                        index={index}
                                        selectModule={selectModule}
                                    />
                                    ))
                                }
                            </Col>
                            :
                            <Col span={24} style={{display: 'flex'}}>
                                {
                                    project.modules.map((module, index)=>(
                                    module.status && <ModuleCard 
                                        module={module} 
                                        key={module.id} 
                                        index={index}
                                        selectModule={selectModuleProject}
                                    />
                                    ))
                                }
                            </Col>
                        }
                    </Row>
                    <Form.Item style={{marginTop: 20}}>
                        <Button type="primary" htmlType="submit" disabled={disabled}>
                            {buttonName}
                        </Button>
                        <Link to={ projectId ? `/company-admin/project/${projectId}` : '/company-admin/projects' }>
                            <Button className="outline" style={{marginLeft:10}}>
                                Cancel
                            </Button>
                        </Link>
                    </Form.Item>
    
                </Form>
    
            </div>
        );
    } else  {
        return <div className="projects-page">
            <Skeleton active paragraph={{rows:5}} />
        </div>
    }
    
};

CreateProject.propTypes = {
    projectId: PropTypes.string,
}


export default CreateProject;