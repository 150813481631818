import React from 'react';
import Card from '../../../../components/common/Card/Card';

const Score = (props) => {
    return <>
        <div className="card-holder">
            <Card
                type="nonselected"
                count={props.data.moduleCount}
                text="Modules"
            />
        </div>
        <div className="card-holder">
            <Card
                type="nonselected"
                count={props.data.featureCount}
                text="Capabilities"
            />
        </div>
        <div className="card-holder">

            <Card
                type="nonselected"
                count="0"
                text="Completed"
            />
        </div>
    </>
}

export default Score;