
export const setProject = (project) => dispatch => {
    dispatch({
        type: 'SET_PROJECT',
        payload: project
    })
}

export const setAssessment = (project) => dispatch => {
    dispatch({
        type: 'SET_ASSESSMENT',
        payload: project
    })
}


