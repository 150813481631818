import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import './authorize.scss';
import auth from './undraw_greek_freak_j-2-qb.svg';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from 'react-router-dom';
const audience = "http://my-app";
import { useHistory } from "react-router-dom";

const Authorize = () => {
    const  {user, getIdTokenClaims, isAuthenticated}  = useAuth0();
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();


    useEffect(()=>{
            console.log(user);
            async function getToken(){
                if(isAuthenticated && !loaded){
                    const token = await getIdTokenClaims();
                    axios.defaults.headers["Authorization"] = `Bearer ${token.__raw}`;
                    setLoaded(true);
                    checkRoleAndRedirect(user);
                }
            }
            getToken();

    },[isAuthenticated]);


    function checkRoleAndRedirect(user){
        if(user[`${audience}/roles`].includes("SuperAdmin")){

            history.push("/super-admin");
        }
        else if(user[`${audience}/roles`].includes("CustomerAdmin")){
            history.push("/company-admin");
        }
        else {
            history.push("/company-portal");
        }
    }

    return (
        <div className="authorize">
            <img src={auth} style={{height:300}} alt="authorize" />
            <h3 style={{paddingTop: 20}} >
                <Spin />
                &nbsp; Authenticating...
            </h3>
        </div>
    );
};

export default Authorize;

