import React, {useState, useContext} from 'react';
import { Menu } from 'antd';
import './super-admin-sub-nav.scss';
import { Link } from 'react-router-dom'; //useHistory
import {CustomerAdminContext} from '../../../modules/CompanyAdmin/CustomerAdminContext';


const CustomerAdminSubNav = () => {

    const [current,setCurrent] = useState('home');
    const {rankingHierarchy} = useContext(CustomerAdminContext);
    // let history = useHistory ();

    function handleClick(e){
        setCurrent(e.key);
        // if(e.key === 'help'){
        //     history.push('/super-admin/help')
        // } else if(e.key === 'subscription'){
        //     history.push('/super-admin')
        // }
    }

    return (
      <div className="sasn">
        <Menu selectedKeys={[current]} mode="horizontal" onClick={handleClick}>
          {/* <Menu.Item key="home">Home</Menu.Item> */}
          <Menu.Item key="home">
            <Link to="/company-admin/">
              Home
            </Link>
          </Menu.Item>
          <Menu.Item key="launchpad">
            <Link to="/company-admin/projects">
              {rankingHierarchy[0].p}
            </Link>
          </Menu.Item>
          <Menu.Item key="item">
            <Link to="/company-admin/users">
              Users
            </Link>
          </Menu.Item>
          <Menu.Item key="help">
            <Link to="/company-admin/help">
              Help
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    );
}

export default CustomerAdminSubNav;