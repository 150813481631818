import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SubsTab = ({ value, name, to = "/" }) => {
  return (
    <div className="subs-tile">
      <h2 style={{ fontSize: "25px" }}>{value}</h2>
      <label>{name}</label>
      <Link to={to}>Details</Link>
    </div>
  );
};

SubsTab.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  to: PropTypes.string,
};

export default SubsTab;
