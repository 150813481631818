import { Button } from 'antd';
import React from 'react';
import './empty-hm.scss';
import star from './stars.svg';
import { useAuth0 } from "@auth0/auth0-react";
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const CompanyHomeEmpty = ({subText, rankingHierarchy}) => {
    const  {user}  = useAuth0();

    return (
        <div className="empty-hm">
            <div className="illustration">
                <img src={star} alt="star" />
            </div>
            <div className="greeting">
                <h1>Welcome <span className="user">{user.name.split(" ")[0]}!</span></h1>
                <p>
                    {subText}
                </p>
                <div style={{display: 'flex'}}>
                    <Link to="/company-admin/new-project">
                        <Button type="primary">Add {rankingHierarchy[0].s}</Button>
                    </Link>
                    <Link to="/company-admin/help">
                        <Button className="outline" style={{marginLeft: 10}}>Need Help?</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

CompanyHomeEmpty.propTypes = {
    subText: PropTypes.string,
    rankingHierarchy: PropTypes.array
}

export default CompanyHomeEmpty;