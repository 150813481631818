import React from 'react';

const HelpPage = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center',height: '86vh'}}>
            <h1>Help is on its way…Coming Soon</h1>
            <img src={require('../../assets/icons/help.svg')} alt="Help icon" />
        </div>
    );
};

export default HelpPage;