import React from 'react';
// import NewLogo from '../../../assets/icons/Logo_Icon.png';
import NewLogo from './pp.png';
import NewLogoDark from './pp2.png';

import PropTypes from 'prop-types';
function AppLogo({ onClick }) {
    return (
        <>
            <img className='app-logo-light' onClick={onClick} style={{ height: '35px', marginLeft: 9, cursor: 'pointer' }} src={NewLogo} alt="App logo" />
            <img className='app-logo-dark' onClick={onClick} style={{ height: '35px', marginLeft: 9, cursor: 'pointer' }} src={NewLogoDark} alt="App logo Dark" />
        </>
    );
}

AppLogo.propTypes = {
    onClick: PropTypes.func
}

export default AppLogo;