function getSumOfKey(responses, key) {
  let sum = 0;
  for (let i = 0; i < responses.length; i += 1) {
    sum += responses[i][key];
  }
  return parseFloat(sum);
}

export function getScoreMatrix(module) {
  for (let i = 0; i < module.features.length; i += 1) {
    const feature = module.features[i];
    feature.scores = {};
    try {
      const resp = Object.keys(feature.responses[0]);

      for (let j = 0; j < resp.length; j += 1) {
        feature.scores[resp[j]] = getSumOfKey(feature.responses, resp[j]) / feature.responses.length;
      }
    } catch (e) {
      console.log(feature);
    }
  }

  const obj = {
    // eslint-disable-next-line max-len
    audienceSize: 0, competitive: 0, cost: 0, dependency: 0, duration: 0, implementation: 0, innovation: 0, integration: 0, maintainance: 0, reusability: 0, strategic: 0,
  };
  const mx = Object.keys(obj);
  for (let i = 0; i < module.features.length; i++) {
    const fx = module.features[i];

    for (let j = 0; j < mx.length; j++) {
      obj[mx[j]] += fx.scores[mx[j]];
    }
  }

  for (let k = 0; k < mx.length; k++) {
    obj[mx[k]] = obj[mx[k]] / module.features.length;
  }
  return obj;
}

export function featureRank(feature, masterScores) {
  if (feature.responses === undefined) {
    return 0;
  }
  const resp = Object.keys(feature.responses[0]);
  let sum = 0;
  for (let i = 0; i < resp.length; i += 1) {
    // eslint-disable-next-line max-len
    sum += (parseFloat(masterScores[resp[i]]) / 100) * getSumOfKey(feature.responses, resp[i]);// parseFloat(feature.responses[0][resp[i]]);
  }
  return parseFloat(sum / resp.length).toFixed(1);
}

export function moduleRank(module, masterScores) {
  if (module.features === undefined || module.features.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < module.features.length; i += 1) {
    const fa = featureRank(module.features[i], masterScores);
    const isNumber = (value) => !Number.isNaN(Number(value));
    sum += !isNumber(fa) ? 0 : parseFloat(fa);
  }
  return (sum / module.features.length).toFixed(1);
}

export function opportunityRank(opportunity) {
  if (opportunity.modules === undefined || opportunity.modules.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < opportunity.modules.length; i += 1) {
    const moduleR = moduleRank(opportunity.modules[i]);
    sum += parseFloat(moduleR);
  }
  return (sum / opportunity.modules.length).toFixed(1);
}

/* Todo: Need to work on aggregate result set */
function calculateKPI() { // opportunity
  // get feature rank
  /* const feature = opportunity.modules[selectedModuleIndex].features[featureIndex];

  return {
    featureRank: featureRank(feature),
    moduleRank: moduleRank(opportunity.modules[selectedModuleIndex]),
    opportunityRank: opportunityRank(opportunity),
  };
  */
}

export default calculateKPI;
