import React from "react";
import GForm from "./GFrom.jsx";
import "./gen.scss";
import { useHistory } from "react-router-dom";
import moment from "moment";

const GenerativePrioritisation = () => {
  const history = useHistory();

  const collectData = (data) => {
    const date = moment(data.startDate).format("YYYY-MM-D");
    history.push(`/company-admin/gen-initiatives/${btoa(data.problem)}/${data.timeline}/${data.initiative}/${date}`);
  };
  return (
    <div className="gen-container">
      <div className="banner"></div>
      <div className="form">
        <GForm onFinish={(data) => collectData(data)} />
      </div>
    </div>
  );
};

export default GenerativePrioritisation;
