import React, { useEffect, useState } from "react";
import KpiList from "../../../components/common/KpiList/KpiList";
import { useParams } from "react-router-dom";
import Axios from "axios";
import config from "../../../config/config";
import moment from "moment";
import "./gen_init.scss";
import { Button, Spin, message } from "antd";
import AddModel from "../PrioritizeRanking/Dashboard/AddModel";
import _ from "underscore";
import Roadmap from "./Roadmap/Roadmap.jsx";
import magic from "./assets/magic.png";
import confetti from "canvas-confetti";

const GenInitiatives = () => {
  const { problem, timeline, initiative, startDate } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [chartType, setChartType] = useState(null);
  const [data, setData] = useState({
    problem_statement: "",
    initiatives: [],
  });
  // const [data, setData] = useState(mock);
  const [activeInitiativeIndex, setActiveInitiativeIndex] = useState(-1);
  const [activeProgramIndex, setActiveProgramIndex] = useState(-1);
  const [activeTaskIndex, setActiveTaskIndex] = useState(-1);

  const [isInitiativeLoading, setIsInitiativeLoading] = useState(true);
  const [isProgramLoading, setIsProgramLoading] = useState(false);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  useEffect(() => {
    getInitiativesFromAI(atob(problem), timeline, initiative, startDate);
  }, []);

  async function generateProgram() {
    setIsProgramLoading(true);
    Axios.post(`${config.api}/openai/generate-programs`, {
      initiative: data.initiatives[activeInitiativeIndex],
    })
      .then((res) => {
        doRandomCanfetti();
        for (let i = 0; i < res.data.programs.length; i++) {
          res.data.programs[i].tasks = [];
        }
        let clonedData = _.clone(data);
        clonedData.initiatives[activeInitiativeIndex].programs = res.data.programs;
        console.log(clonedData);
        setData(clonedData);
        setIsProgramLoading(false);
      })
      .catch((err) => {
        message.error("Error occured while generating programs");
        console.log(err);
        setIsProgramLoading(false);
      });
  }

  async function getInitiativesFromAI(problem, timeline, initiative, startDate) {
    Axios.post(`${config.api}/openai/generate-initiatives`, {
      problem: problem,
      duration: timeline,
      startDate: startDate,
      initiativeCount: initiative,
    })
      .then((resp) => {
        doCanfetti();
        setData(resp.data);
        setIsInitiativeLoading(false);
      })
      .catch((err) => {
        setIsInitiativeLoading(false);
        message.error("Error occured while generating initiatives");
        console.log(err);
      });
  }

  async function generateTask() {
    setIsTaskLoading(true);
    let program = data.initiatives[activeInitiativeIndex].programs[activeProgramIndex];
    Axios.post(`${config.api}/openai/generate-tasks`, {
      program: program,
    })
      .then((res) => {
        doRandomCanfetti();
        let clonedData = _.clone(data);
        clonedData.initiatives[activeInitiativeIndex].programs[activeProgramIndex].tasks = res.data.tasks;
        console.log(clonedData);
        setData(clonedData);
        setIsTaskLoading(false);
      })
      .catch((err) => {
        setIsTaskLoading(false);
        message.error("Error occured while generating tasks");
        console.log(err);
      });
  }

  function showRoadmap(type) {
    setShowModal(true);
    setChartType(type);
  }

  function doCanfetti() {
    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);
  }

  function doRandomCanfetti() {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    confetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { y: 0.6 },
    });
  }

  if (isInitiativeLoading) {
    return (
      <div style={{ display: "flex", height: "80vh", width: "100vw", justifyContent: "center", alignItems: "center" }}>
        <Spin /> <p>Generating your Initiatives for {atob(problem)}</p>
      </div>
    );
  } else {
    return (
      <div className="projects-page gen-init">
        <h1>{data.problem_statement}</h1>
        <span className="sub-head">
          Duration: {data.duration} weeks | Start Date: {moment(data.start_date).format("DD, MMM YYYY")}
        </span>
        <div className="the-list" style={{ display: "flex" }}>
          {data.initiatives.length > 0 && (
            // INITIATIVES
            <KpiList
              arr={data.initiatives}
              title={`Initiatives`}
              nameKey="initiative_name"
              descriptionKey="description"
              index={activeInitiativeIndex}
              onSelect={(index) => {
                // onFeatureSelect(index);
                setActiveInitiativeIndex(index);
                if (data.initiatives[index].programs.length > 0) {
                  setActiveProgramIndex(0);
                } else {
                  setActiveProgramIndex(-1);
                }
              }}
              onClick={() => {
                showRoadmap("initiative");
              }}
            />
          )}

          {activeInitiativeIndex > -1 && data.initiatives[activeInitiativeIndex].programs.length > 0 ? (
            // PROGRAMS
            <KpiList
              arr={data.initiatives[activeInitiativeIndex].programs}
              title={`Programs`}
              nameKey="program_name"
              descriptionKey="description"
              index={activeProgramIndex}
              onSelect={(index) => {
                setActiveProgramIndex(index);
              }}
              onClick={() => {
                showRoadmap("program");
              }}
            />
          ) : (
            //activeProgramIndex > -1 &&
            <div className="no-programs">
              <span>
                {/* No Program exists */}
                {/* <b>{data.initiatives[activeInitiativeIndex]?.initiative_name}</b>{" "} */}
              </span>
              <Button type="primary" disabled={isProgramLoading} onClick={() => generateProgram()}>
                <img src={magic} height={20} width={20} alt="magic" style={{ marginRight: 5 }} />
                {isProgramLoading ? "Generating Programs..." : "Generate Programs"}
              </Button>
            </div>
          )}

          {activeProgramIndex > -1 &&
          data.initiatives[activeInitiativeIndex].programs[activeProgramIndex].tasks.length > 0 ? (
            // Tasks
            <KpiList
              arr={data.initiatives[activeInitiativeIndex].programs[activeProgramIndex].tasks}
              title={`Tasks`}
              nameKey="task_name"
              descriptionKey="description"
              index={activeTaskIndex}
              onSelect={(index) => {
                setActiveTaskIndex(index);
              }}
              onClick={() => {
                showRoadmap("task");
              }}
            />
          ) : (
            activeProgramIndex >= 0 && (
              <div className="no-programs">
                <span>
                  {/* No Task exists */}
                  {/* <b>{data.initiatives[activeInitiativeIndex]?.initiative_name}</b>{" "} */}
                </span>
                <Button type="primary" disabled={isTaskLoading} onClick={() => generateTask()}>
                  <img src={magic} height={20} width={20} alt="magic" style={{ marginRight: 5 }} />
                  {isTaskLoading ? "Generating Tasks..." : "Generate Tasks"}
                </Button>
              </div>
            )
          )}
        </div>

        <AddModel
          visible={showModal}
          handleOk={() => {}}
          handleCancel={() => {
            setShowModal(false);
          }}
          title="Timeline"
        >
          {chartType === "initiative" && <Roadmap data={data.initiatives} nameKey="initiative_name" />}
          {chartType === "program" && (
            <Roadmap data={data.initiatives[activeInitiativeIndex].programs} nameKey="program_name" />
          )}
          {chartType === "task" && (
            <Roadmap
              data={data.initiatives[activeInitiativeIndex].programs[activeProgramIndex].tasks}
              nameKey="task_name"
            />
          )}
        </AddModel>
      </div>
    );
  }
};

export default GenInitiatives;
