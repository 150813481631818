/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import './ip.scss';
import NumberTile from '../../../components/common/Tiles/Number/NumberTile';

const Initiatives = ({ min, max, modules }) => (
  <div>
    {
        modules.map((mod) => (
          mod.score >= min && mod.score <= max
            && (
            <NumberTile title={mod.moduleName} number={mod.score}  />
            )
        ))
    }
  </div>
);

export default Initiatives;
