import React, { Component } from 'react';

class Questions extends Component {
    render() {
        return (
            <div>
                Question
            </div>
        );
    }
}

export default Questions;