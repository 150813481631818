import React, {useState} from 'react';
import { Menu } from 'antd';
import './super-admin-sub-nav.scss';
import { useHistory } from 'react-router-dom';


const SuperAdminSubNav = () => {

    const [current,setCurrent] = useState('subscription');
    let history = useHistory ();

    function handleClick(e){
        setCurrent(e.key);
        if(e.key === 'help'){
            history.push('/super-admin/help')
        } else if(e.key === 'subscription'){
            history.push('/super-admin')
        }
    }

    return (
      <div className="sasn">
        <Menu selectedKeys={[current]} mode="horizontal" onClick={handleClick}>
          {/* <Menu.Item key="home">Home</Menu.Item> */}
          <Menu.Item key="subscription">Subscriptions</Menu.Item>
          <Menu.Item key="help">Help</Menu.Item>
        </Menu>
      </div>
    );
}

export default SuperAdminSubNav;