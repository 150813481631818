/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { SkeletonTheme } from 'react-loading-skeleton';
import NotFoundPage from './NotFoundPage';
import Login from '../pages/Login/Login';
// import Questions from '../modules/CompanyAdmin/Questions/Questions';
// import SuperAdminHome from '../modules/SuperAdmin/Home/SuperAdminHome';
import Organizations from '../modules/SuperAdmin/Organizations/Organizations';
import OnboardOrg from '../modules/SuperAdmin/Organizations/OnboardOrg';
import UtilComp from '../utils/UtilComp';
import Nav from './common/Nav/Nav';
// import ClientHome from '../modules/ClientApp/ClientHome';
import ProjectHome from '../modules/ClientApp/Project/ProjectHome';
import MaturityAssesment from '../modules/ClientApp/MaturityAssesment/MaturityAssesment';
import SetPassword from '../pages/SetPassword/SetPassword';
import ForgetPassword from '../pages/ResetPassword/ForgetPassword';
// import EditOrg from '../modules/SuperAdmin/Organizations/EditOrg';
import Subscriptions from '../modules/SuperAdmin/Subscriptions/Subscriptions';
import SuperAdminSubNav from './common/SubNav/SuperAdminSubNav';
// import Help from '../modules/SuperAdmin/Help/Help';
import Authorize from '../pages/Authorize/Authorize';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import AppLoading from '../pages/AppLoading/AppLoading';
import config from '../config/config';
import CompanyAdminRoutes from '../modules/CompanyAdmin/CompanyAdminRoutes';
import LandingPage from '../modules/ClientApp/LandingPage';
import CustomerPortalNav from './common/SubNav/CustomerPortalNav';
import MyAssesments from '../modules/ClientApp/Assesment/MyAssesments';
import HelpPage from '../pages/Help/HelpPage';
import 'animate.css';
import '../styles/responsive.scss';
import Tutorial from '../pages/Tutorial/Tutorial';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import SaaS from '../pages/SaaS/SaaS';
import MaturitySubmitted from '../modules/ClientApp/MaturityAssesment/MaturitySubmitted';
import AssessmentResult from '../modules/ClientApp/MaturityAssesment/AssessmentResult/AssessmentResult';
import Unauthorize from '../pages/Unauthorize/Unauthorize';

const App = (props) => {
  const { isAuthenticated, isLoading, getIdTokenClaims, user } = useAuth0();
  const [appLoading, setAppLoading] = React.useState(true);

  useEffect(() => {
    authorize();
  }, [isLoading]);

  async function authorize() {
    if (!isLoading) {
      if (isAuthenticated) {
        const token = await getIdTokenClaims()
        axios.defaults.headers["Authorization"] = `Bearer ${token.__raw}`;
        const role = getRole();
        if (role === 'SuperAdmin') {
          props.history.push('/super-admin');
        } else if (role === 'CustomerAdmin') {
          props.history.push('/company-admin');
        }

      } else {
        props.history.push("/");
      }
      setAppLoading(false);
    }
  }

  function getRole() {
    const userRole = user[`${config.auth0.audience}/roles`];
    if (userRole.includes('SuperAdmin')) {
      return 'SuperAdmin';
    } else if (userRole.includes('CustomerAdmin')) {
      return 'CustomerAdmin';
    } else {
      return 'CustomerUser';
    }
  }

  if (appLoading) {
    return <AppLoading />
  } else {
    return (
      <SkeletonTheme color="#EAEDF2" highlightColor="#F2F3F6">
        <div className="app-first-container">
          <UtilComp history={props.history} user={user} />
          <Switch>
            {/* <Route exact path="/" component={HomePage} /> */}
            <Route path="/" exact component={Login} />
            <Route path="/authorize" component={Authorize} />
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/set-password/:userId/:projectId" component={SetPassword} />
            <Route
              path="/company-admin"
              render={({ match: { url } }) => (
                <CompanyAdminRoutes url={url} history={props.history} />
              )}
            />
            <Route path="/help" component={HelpPage} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/saas-agreement" component={SaaS} />



            <Route
              path="/super-admin"
              render={({ match: { url } }) => (
                <>
                  <Nav history={props.history} />
                  <SuperAdminSubNav />
                  <div className="admin-container">
                    {/* <Content style={{ padding: '0 50px' }}> */}
                    <Route path={`${url}/`} component={Subscriptions} exact />
                    <Route
                      path={`${url}/organizations`}
                      component={Organizations}
                    />
                    <Route path={`${url}/onboard-org`} component={OnboardOrg} />
                    <Route path={`${url}/edit-org/:orgId`} component={OnboardOrg} />
                    <Route path={`${url}/help`} component={HelpPage} />
                    <Route path={`${url}/unauthorized`} component={Unauthorize} />
                    {/* </Content> */}
                  </div>
                </>
              )}
            />

            <Route
              path="/company-portal"
              render={({ match: { url } }) => (
                <>
                  <Nav history={props.history} />
                  <CustomerPortalNav />
                  <div className="">
                    {/* <Route path={`${url}/`} component={ClientHome} exact /> */}
                    <Route path={`${url}/`} component={LandingPage} exact />
                    <Route path={`${url}/assesments`} component={MyAssesments} />
                    <Route path={`${url}/assesment/:assesmentId/:retake?`} component={MaturityAssesment} />
                    <Route path={`${url}/maturity-sumiited/:assesmentId`} component={MaturitySubmitted} />
                    <Route path={`${url}/assessment-result/:assesmentId`} component={AssessmentResult} />
                    <Route path={`${url}/project/:projectId`} component={ProjectHome} />
                    <Route path={`${url}/maturity-assesment/:projectId/:module`} component={MaturityAssesment} />
                    <Route path={`${url}/help`} component={HelpPage} />
                    <Route path={`${url}/tutorial`} component={Tutorial} />
                    <Route path={`${url}/unauthorized`} component={Unauthorize} />
                  </div>
                </>
              )}
            />

            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </SkeletonTheme>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

App.propTypes = {
  history: PropTypes.any,
};

export default hot(module)(App);
