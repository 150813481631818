import React from 'react';
import './mod-tile.scss';
import {useHistory} from 'react-router-dom';

const ModuleTile = ({imgLink,subText,title, linkTo}) => {
    const history = useHistory();
    return (
        <div className="mod-tile" onClick={()=>{history.push(linkTo)}}>
            <div className="img">
                <img style={{height: 70}} src={imgLink} alt=""/>
            </div>
            <div style={{marginTop: 20}}>
                <h2>{title}</h2>
                <p>{subText}</p>
            </div>
        </div>
    );
};

export default ModuleTile;