
import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumb, Input, Select, Skeleton } from 'antd';
import AddNewSub from '../../../../components/common/Tiles/SubsTile/AddNewSub';
import SubsTileLoading from '../../../../components/common/Tiles/SubsTile/SubsTileLoading';
import EmptyPage from '../../../../components/EmptyAdd/EmptyPage';
import { Link, useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import config from '../../../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/actions'
import PropTypes from 'prop-types';
import AssessmentTileCA from '../../../../components/common/Tiles/AssessmentTile/AssessmentTileCA';
import { CustomerAdminContext } from '../../CustomerAdminContext'


const LIMIT = 10;



const AssesmentList = ({ actions, reducer }) => {

  const { projectId } = useParams();
  const history = useHistory();
  const [assesements, setAssesment] = useState([]);
  const numberArray = [1, 2, 3, 4, 5, 6];
  const [loading, setLoading] = useState(true);
  const { rankingHierarchy } = useContext(CustomerAdminContext);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(LIMIT);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [sort, setSort] = useState('name');
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('')


  function getAssesments(sort, l, s, isAppend = true, searchTerm = '') {
    Axios.get(`${config.api}/assesments/assesments-by-project-id/${projectId}?limit=${l}&skip=${s}&sort=${sort}&searchTerm=${searchTerm}`).then(r => {
      // setAssesment(r.data);
      setLoading(false);
      setCount(r.data.count);
      if (isAppend) {
        const newP = assesements.concat(r.data.assesments);
        setAssesment(newP);
      } else {
        setAssesment(r.data.assesments);
      }
      if (r.data.data.length < LIMIT) {
        setReachedEnd(true);
      }
    });

  }

  useEffect(() => {
    getAssesments(sort, LIMIT, 0)
  }, []);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !reachedEnd) {
      const newSkip = skip + limit;
      const newLimit = skip + LIMIT;
      getAssesments(sort, newSkip, newLimit, true);
      setSkip(newSkip);
      setLimit(newLimit);
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      getAssesments(sort, LIMIT, 0, false, searchTerm);
      // Send Axios request here
    }, 1300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  if (loading) {
    return <div className="projects-page">
      <div style={{ width: 400 }}><Skeleton active /></div>
      <div className="projects-loading">
        {
          numberArray.map((x, i) => <SubsTileLoading key={i} />)
        }
      </div>
    </div>
  } else {
    if (count > 0) {
      return (
        <div className="projects-page">

          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to="/company-admin">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/company-admin/projects">
                {rankingHierarchy[0].p}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/company-admin/project/${reducer.dataReducer.project._id}`}>{reducer.dataReducer.project.projectName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Assessments
            </Breadcrumb.Item>
          </Breadcrumb>


          <h1>Assessments</h1>
          <div className='active-and-sort'>
            <div className='counts-active'>
              You have {count} <a href="#">Active</a> assessment(s)
            </div>
            <div className='sort-by'>
              <div className='search' style={{ marginRight: 10 }}>
                <label>Search</label>
                <Input placeholder="Search" autoComplete='off' onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
              <div className='sort-select'>
                <label>Sort By</label>
                <Select defaultValue={sort} style={{ width: 160 }} onChange={(e) => {
                  setLimit(LIMIT);
                  setSkip(0);
                  getAssesments(e, LIMIT, 0, false);
                  setSort(e);
                  setReachedEnd(false);
                }}>
                  <Select.Option value='name'>Name</Select.Option>
                  <Select.Option value='createdAt'>Date Created</Select.Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="projects" onScroll={handleScroll}>
            {!loading &&
              assesements.map((x, i) => {
                return <div key={x._id} onClick={() => actions.setAssessment(x)}>
                  <AssessmentTileCA key={i}
                    assessment={x}
                    userType="CA"
                    onClick={() => history.push(`/company-admin/maturity-result/${x._id}`)}
                  />
                </div>
              })
            }

            <AddNewSub
              type="assessment"
              link={`/company-admin/add-maturity-assesment/${projectId}`}
              data={reducer.dataReducer.project}
            />

          </div>
        </div>
      );
    } else {
      return <div className="projects-page">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/company-admin">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/company-admin/projects">{rankingHierarchy[0].p}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/company-admin/project/${reducer.dataReducer.project._id}`}>{reducer.dataReducer.project.projectName}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Assessments
          </Breadcrumb.Item>
        </Breadcrumb>
        <EmptyPage
          imageRef={require('./empty-assesment.svg')}
          header="Need a fresh start"
          subtext="Seems like you don't have an assessement yet, click on the add assessment to get started"
          primaryButtonText="Add Assessment"
          data={reducer.dataReducer.project}
          primaryButtonLink={`/company-admin/add-maturity-assesment/${projectId}`}
          helpLink=""
        />
      </div>
    }
  }
};


AssesmentList.propTypes = {
  actions: PropTypes.any,
  reducer: PropTypes.object
}


function mapStateToProps(state) {
  return {
    reducer: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssesmentList);


