import { Button } from 'antd';
import React from 'react';
import matuirity from './matuirity.svg'
import { Link, useParams } from 'react-router-dom';
const MaturitySubmitted = () => {

    const {assesmentId} = useParams();

    return (
        <section style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
            <div style={{display: 'flex', flexDirection:'column', height: 300, justifyContent: 'space-between'}}>
                <img src={matuirity} alt="maturity" style={{height: 190}} />
                <h1 style={{fontSize: 30}}>Assessment Successfully Submitted</h1>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={`/company-portal/assesment/${assesmentId}`}>
                        <Button type='default'>Retake Assessment</Button>
                    </Link>
                    <Link to={`/company-portal/assessment-result/${assesmentId}`}>
                        <Button type='primary' style={{marginLeft: 10}}>View Result</Button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default MaturitySubmitted;