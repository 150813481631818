/* eslint-disable react/jsx-filename-extension */
import React, {useState,useContext} from 'react';
import { Button, Col, Input, Row , Form, Breadcrumb } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import './add-p.scss';
import { Link } from 'react-router-dom';
import {CustomerAdminContext} from '../../CustomerAdminContext';


const stateX = {
  validateMessages:  {
      required: '${label} is required!',
      types: {
        email: '${label} is not validate email!',
        number: '${label} is not a validate number!',
      },
      number: {
        range: '${label} must be between ${min} and ${max}',
      },
  }
}

const AddFeature = ({feature, type, onSaveFeature, onCancel, projectId, projectName, programName, opportunitieName, initiativeName, navigate }) => {

  const [disabled, setDisabled] = useState(false);
  const {rankingHierarchy} = useContext(CustomerAdminContext);
  
  function saveFeature(data) {
    setDisabled(true);
    if(type === 'POST'){
      onSaveFeature({
        ...data.feature,
        createdAt: new Date(),
      })
    } else {
      onSaveFeature({
        feature: data.feature.feature,
        description: data.feature.description,
      })
    }
  }


  return (
    <div className={type === 'PUT' ? `projects-page add-program` : 'add-program'}>
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-admin">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/company-admin/projects">
                      {rankingHierarchy[0].p}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>navigate('program')}>
                    <a>{programName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>navigate('opportunity')}>
                    <a>{opportunitieName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>navigate('inititive')}>
                    <a>{initiativeName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>{
                  navigate('');
                  setTimeout(()=>{
                    navigate('feature')
                  },0)
                }}>
                    <a>{rankingHierarchy[4].p}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a>{type === 'PUT' ? `Edit ${rankingHierarchy[4].s}` : `Add ${rankingHierarchy[4].s}`}</a>
                </Breadcrumb.Item>
            </Breadcrumb>
        <h1>
          {type === 'PUT' ? `Edit ${feature.feature}` : `Create ${rankingHierarchy[4].s}`}
        </h1>

        <div className="form">
            <Form
                name="nest-messages"
                autoComplete="off"
                className="onboard-form"
                onFinish={saveFeature}
                validateMessages={stateX.validateMessages}
                initialValues={{
                  feature: feature
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item name={['feature', 'feature']} label={`${rankingHierarchy[4].s} Name`} rules={[{ required: true }]}>
                            <Input placeholder={`Please enter ${rankingHierarchy[4].s.toLowerCase()} name`} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                  <Col span={8}>
                        <Form.Item name={['feature', 'description']} label="Description" rules={[]}>
                            <TextArea placeholder="Please enter description" />
                        </Form.Item>
                  </Col>
                </Row>

                
                <Row>

                  <Form.Item style={{marginTop: 20}}>
                      <Button type="primary" htmlType="submit" disabled={disabled}>
                        {disabled ? `Saving ${rankingHierarchy[4].s}...` : `Save ${rankingHierarchy[4].s}`}
                      </Button>
                      <Button style={{marginLeft: 10}} onClick={onCancel} type="default">Cancel</Button>
                  </Form.Item>
                </Row>
                
            </Form>
        </div>
    </div>
  );
};

AddFeature.propTypes ={
    onSaveFeature: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    feature: PropTypes.object,
    type: PropTypes.string,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    programName: PropTypes.string,
    opportunitieName: PropTypes.string,
    initiativeName: PropTypes.string,
    navigate: PropTypes.func,
}

AddFeature.defaultProps = {
    feature: null,
    type: 'POST'
}

export default AddFeature;
