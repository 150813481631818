import { Button, Progress } from 'antd';
import React from 'react';
import moment from 'moment';
import Avatar from '../Avatar/Avatar';
import PropTypes from 'prop-types';

const MaturityFinalTop = ({ maturityPer, no_of_questions, assesment, openProductLaunch }) => {

    function daysRemaining(edate) {
        var eventdate = moment(edate);
        var todaysdate = moment();
        return eventdate.diff(todaysdate, 'days');
    }

    return (
        <div className="maturity-kpi">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                    <Progress type="circle" className="progress-green" percent={maturityPer} />
                    <Button onClick={openProductLaunch} type="link" style={{ marginTop: 5 }}>Model Details</Button>
                </div>
                {/* <div className="kpi">
                    <h1>{status}</h1>
                    <span>Status</span>
                </div> */}
                <div className="kpi">
                    <h1>{no_of_questions}</h1>
                    <span>Number of Questions</span>
                </div>
                <div className="kpi">
                    <h1>{assesment.userResponded.length}</h1>
                    <span>Number of Responses</span>
                </div>
                <div className="kpi">
                    <h1>{daysRemaining(assesment.validUpto)}</h1>
                    <span>Will expire in(days)</span>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4>Assesment completed by</h4>
                <div style={{ display: 'flex' }}>
                    {
                        assesment.userResponded.map((user) =>
                            <Avatar url={user.picture} alt={user.name} size={30} key={user.user_id} />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

MaturityFinalTop.propTypes = {
    maturityPer: PropTypes.string,
    status: PropTypes.string,
    no_of_questions: PropTypes.number,
    no_of_sections: PropTypes.number,
    assesment: PropTypes.object,
    openProductLaunch: PropTypes.func
}

export default MaturityFinalTop;