/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import _ from 'underscore';
import { Button, message } from 'antd';
import Axios from 'axios';
import { SettingOutlined } from '@ant-design/icons';
import { FlexBox, WelcomeUser, NeoButton } from '../../../../../components/elements/elements';
import '../programs.scss';
import Form from './Form';
import config from '../../../../../config/config';
import AddModel from '../../Dashboard/AddModel';
import AdvanceSettingsModal from './AdvanceSettingsModal';

const initialState = {
  programName: '',
  description: '',
  objective: '',
  kpi: undefined,
  opportunities: [
  ],
};

const initConfigs = {
  dependency: 100,
  competitive: 130,
  innovation: 85,
  implementation: 100,
  integration: 100,
  maintainance: 85,
  cost: 100,
  reusability: 85,
  audienceSize: 125,
  strategic: 140,
  duration: 120,
  timeUnit: 'Q',
};

const AddProgram = (props) => {
  const [program, setProgram] = useState(initialState);
  const [advanceModal, setAdvanceModal] = useState(false);
  const [configs, setConfigs] = useState(initConfigs);
  function changeVal(key, val) {
    const cloned = _.clone(program);
    cloned[key] = val;
    setProgram(cloned);
  }

  function saveProgram() {
    const loading = message.loading('Saving the program...');
    Axios.post(`${config.api}/programs`, {
      ...program,
      configs,
      projectId: props.match.params.projectId,
    }).then((resp) => {
      loading();
      message.success('Proram Saved successfully!');
      props.history.push(`/company-admin/programs/ranking/${props.match.params.projectId}`);
    });
  }

  function isDisabled() {
    return program.programName.length === 0 || program.description.length === 0 || program.objective.length === 0 || program.kpi == undefined;
  }

  function changeConfig(key, val) {
    const newConfigs = _.clone(configs);
    newConfigs[key] = val;
    setConfigs(newConfigs);
  }

  return (
    <div className="add-program">
      <FlexBox flexDirection="column" className="program-flex">
        <div>
          <WelcomeUser>
            Add New Program
          </WelcomeUser>
          <div className="devider" />
        </div>

        <Form
          program={program}
          changeForm={(key, val) => changeVal(key, val)}
          history={props.history}
        />
        <div>
          <Button type="link" className="adv-btn" onClick={() => setAdvanceModal(true)}>
            <SettingOutlined />
            Advanced Settings
          </Button>
        </div>

        <div>
          <div className="devider" />
          <div>
            <NeoButton onClick={saveProgram} disabled={isDisabled()} primary style={{ float: 'right' }}>
              Add Program
            </NeoButton>
          </div>
        </div>
      </FlexBox>
      <AddModel
        title="Advanced Settings"
        visible={advanceModal}
        handleCancel={() => setAdvanceModal(false)}
      >
        <AdvanceSettingsModal
          configs={configs}
          changeConfig={changeConfig}
          handleCancel={() => setAdvanceModal(false)}
        />
      </AddModel>
    </div>
  );
};

export default AddProgram;
