import React from 'react';
import PropTypes from 'prop-types';

const ProgramRow = ({index, row}) => {
    return (
        <tr key={index} className={`${row.type}_row`}>
            <td style={{}}>{row.program}</td>
            <td>{row.opportunity}</td>
            <td>{row.initiative}</td>
            <td>{row.feature}</td>

            <td className="inp-per">{row.config.dependency}%</td>
            <td className="inp-per">{row.config.competitive}%</td>
            <td className="inp-per">{row.config.innovation}%</td>
            <td className="inp-per">{row.config.implementation}%</td>
            <td className="inp-per">{row.config.integration}%</td>
            <td className="inp-per">{row.config.cost}%</td>
            <td className="inp-per">{row.config.audienceSize}%</td>
            <td className="inp-per">{row.config.duration}%</td>
            <td className="inp-per">{row.config.strategic}%</td>
            <td className="inp-per">{row.config.reusability}%</td>
            <td className="inp-per">{row.config.maintainance}%</td>

            <td className="score">{
                row.programRank
                // !row.programRank && isNaN(row.programRank) ? 0 : row.programRank.toFixed(1)
            }</td>
            <td className="score">{row.opportunityRank}</td>
            <td className="score">{row.initiativeRank}</td>
            <td className="score">{row.featureRank}</td>
        </tr>
    );
};

ProgramRow.propTypes = {
    index: PropTypes.number,
    row: PropTypes.object
}

export default ProgramRow;