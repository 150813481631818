import React from 'react';
import styled from 'styled-components';
import { NeoButton } from '../../elements/elements';
import { Progress } from 'antd';
const MC = styled.div`
  display: flex;
  height: 270px;
  width: 270px;
  border-radius: 16px;
  border-radius: 16px;
  /* background: #f2f3f6;
    box-shadow: 6px 6px 17px #d3d3d6, -6px -6px 17px rgba(255, 255, 255, 0); */

  background: linear-gradient(145deg,#e6eaf2,#f5f5f9);
  box-shadow:  7px 7px 31px #c4c5c7, 
              -7px -7px 31px rgba(255, 255, 255, 0);
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
`;

const HeadText = styled.h2`
    text-align: left;
    font-size:18px;
`
const SubText = styled.span`
    color: #575f6b;
    font-size: 12px;
`
const HeadPart = styled.div`
    display: flex;
    width: 100%;
    padding: 0 20px 0 20px;
    align-items: end;
`

const Icon = styled.i`
    color: #ff9e00;
    font-size: 50px;
    margin-right: 20px;
`

const ModuleCard = (props) => {
    return (
      <MC>
        <HeadPart>
          <Icon className={props.icon}></Icon>
          <HeadText>{props.name}</HeadText>
        </HeadPart>
        <div>
          <Progress percent={props.percentage} showInfo={false} />
          <SubText>Your task completion status: {props.percentage}%</SubText>
        </div>
        <div>
          <NeoButton onClick={()=>props.history.push(`/company-portal/maturity-assesment/${props.projectId}/${props.name.toLowerCase()}`)} primary>
            {
              props.percentage > 0 ? "REASSESS" : "START"
            }
          </NeoButton>
        </div>
      </MC>
    );
};

export default ModuleCard;