import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import config from '../config/config';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      
      <Provider store={store}>
        <Auth0Provider
          domain={config.auth0.domain}
          clientId={config.auth0.clientId}
          redirectUri={window.location.origin + "/authorize"}>
          <ConnectedRouter history={history}>
            <App history={history} />
          </ConnectedRouter> 
          </Auth0Provider>
      </Provider>
     
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
