/* eslint-disable react/jsx-filename-extension */
import { Row, Select, Skeleton } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'underscore';
import Timeline from '../../../../components/common/Timeline/Timeline';
import './roadmap.scss';
import conf from '../../../../config/config';
import TimelineChart from './TimelineChart';
import { CustomerAdminContext } from '../../CustomerAdminContext';

const sorts = [{
  name: 'Module Rank',
  key: 'moduleRank',
}, {
  name: 'Dependency',
  key: 'dependency',
}, {
  name: 'Competitive',
  key: 'competitive',
}, {
  name: 'Innovation',
  key: 'innovation',
}, {
  name: 'Implementation',
  key: 'implementation',
}, {
  name: 'Integration',
  key: 'integration',
}, {
  name: 'Maintainance',
  key: 'maintainance',
}, {
  name: 'Cost',
  key: 'cost',
}, {
  name: 'Reusability',
  key: 'reusability',
}, {
  name: 'Audience Size',
  key: 'audienceSize',
}, {
  name: 'Strategic',
  key: 'strategic',
}, {
  name: 'Duration',
  key: 'duration',
}];

const Roadmap = ({ programId, opportunityIndex }) => {
  const { rankingHierarchy } = useContext(CustomerAdminContext);
  const [opportunity, setOpportunity] = useState(null);
  const [data, setData] = useState(null);
  const [selectedOpp, setSelectedOpp] = useState(null);
  const [sort, setSort] = useState('moduleRank');

  function filterData(oppName, dtx, st) {
    const cloned = _.clone(dtx);
    const filtered = _.filter(cloned.time, (x) => x.opportunityName === oppName);
    cloned.time = (_.sortBy(filtered, st)).reverse();
    setOpportunity(cloned);
  }

  function getOptions() {
    axios({
      method: 'POST',
      url: `${conf.api}/programs/get-opportunities`,
      data: {
        programId: programId,
      },
    }).then((resp) => {
      setSelectedOpp(resp.data.opportunities[opportunityIndex]);
      setData(resp.data);
      filterData(resp.data.opportunities[opportunityIndex], resp.data, sort);
    });
  }
  useEffect(() => {
    getOptions();
  }, []);

  function selectVal(val) {
    setSelectedOpp(val);
    filterData(val, data, sort);
  }

  function selectSort(val) {
    setSort(val);
    filterData(selectedOpp, data, val);
  }
  if (data) {
    return (
      <div className="roadmap">
        <Row>
          <div className="ls">
            {opportunity
              && (
                <section style={{ display: 'flex' }}>
                  <div className="sel_field">
                    <label>{rankingHierarchy[2].s}</label>
                    <Select
                      value={selectedOpp}
                      onChange={selectVal}
                      placeholder="Enterise Dashboard"
                    >
                      {
                        opportunity.opportunities.map((opp) => (
                          <Select.Option key={`${opp}`}>{opp}</Select.Option>
                        ))
                      }
                    </Select>
                  </div>


                  <div className="sel_field" style={{ marginLeft: 20 }}>
                    <label>Sort By</label>
                    <Select
                      value={sort}
                      onChange={selectSort}
                      placeholder="Sort by"
                    >
                      {
                        sorts.map((s) => (
                          <Select.Option key={`${s.key}`}>{s.name}</Select.Option>
                        ))
                      }
                    </Select>
                  </div>
                </section>
              )}
          </div>
        </Row>
        <Row className="flex-row mid-part">
          {
            opportunity
            &&
            <Timeline data={opportunity} sort={sort} />
            // <TimelineChart opportunity={opportunity} sort={sort} />
          }
        </Row>
      </div>
    );

  } else {
    return <Skeleton active paragraph={{ rows: 10 }} />;
  }

}

Roadmap.propTypes = {
  programId: PropTypes.string.isRequired,
  opportunityIndex: PropTypes.number
};


export default Roadmap;
