import { Breadcrumb, Button, Modal } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { FlxHrSB } from '../../../../components/elements/elements';
import './feature-rank.scss';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import NumberTile from '../../../../components/common/Tiles/Number/NumberTile';
import FeatureInputs from './FeatureInputs';
import _ from 'underscore';
import calculateKPI from './CalculateKPI';
import { Link } from 'react-router-dom';
import Roadmap from '../Roadmap/Roadmap';

import { CustomerAdminContext } from '../../CustomerAdminContext';

const FeatureRank = ({ program, opportunityIndex, initiativeIndex, featureIndex, updateProgram, setFeatureIndex, onCacel, projectId, projectName, programName, opportunitieName, navigate, initiativeName, featureName, programId }) => {

    const { rankingHierarchy } = useContext(CustomerAdminContext);
    const [cloneProgramKPI, setCloneProgramKPI] = useState(program);
    const [viewRoadmap, setViewRoadmap] = useState(false);
    const features = program.opportunities[opportunityIndex].modules[initiativeIndex].features;
    const [kpi, setKPI] = useState({
        featureRank: 0,
        moduleRank: 0,
        opportunityRank: 0,
    });

    useEffect(() => {
        const data = calculateKPI(program.config, program.opportunities[opportunityIndex], initiativeIndex, featureIndex);
        setKPI(data);
    }, [cloneProgramKPI, featureIndex]);

    function onSelect(index) {
        setFeatureIndex(index);
    }

    function submitResponse() { //e
        updateProgram(cloneProgramKPI);
    }

    function inputChanged(e) {
        const p = _.clone(cloneProgramKPI);
        if (p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses === undefined) {
            p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses = [{}];
        }

        Object.keys(e.responses).forEach(function (key) {

            try {
                p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses[0][key] = e.responses[key];
            } catch (e) {
                p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses[0] = { [key]: e.responses[key] };
            }

            if (p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses[0][key] === "") {
                p.opportunities[opportunityIndex].modules[initiativeIndex].features[featureIndex].responses[0][key] = 0;
            }

        });
        setCloneProgramKPI(p);
    }



    return (
        <div className="projects-page feature-rank">


            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-admin">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/company-admin/projects">
                        {rankingHierarchy[0].p}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('program')}>
                    <a>{programName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('opportunity')}>
                    <a>{opportunitieName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('inititive')}>
                    <a>{initiativeName}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {featureName}
                </Breadcrumb.Item>
            </Breadcrumb>

            <FlxHrSB>
                <h1>Scoring Rank</h1>
                <div>
                    <Button onClick={() => setViewRoadmap(true)} type="default">View Roadmap</Button>
                </div>
            </FlxHrSB>

            <section className="dash-area">
                <div className="menu">
                    <div style={{ marginLeft: 7, fontSize: 24 }}>
                        <span style={{ color: 'var(--primary-color)' }}>{features.length}</span> {rankingHierarchy[4].p}
                    </div>
                    <ol className="the-list">
                        {features.map((item, i) =>
                            <li key={i} className={featureIndex === i ? 'active' : ''} onClick={() => onSelect(i)} >
                                <div>
                                    <span>{item.feature}</span>
                                    <span className="chevron">
                                        <RightOutlined />
                                    </span>
                                </div>
                            </li>
                        )}
                    </ol>
                </div>
                <div className="inputs">
                    <div className="input-details">
                        <div>{rankingHierarchy[4].s}- {features[featureIndex].feature}</div>
                        <div>Description- {features[featureIndex].description}</div>
                        <div>Created on- {moment(features[featureIndex].createdAt).format('MMM DD, YYYY')}</div>
                    </div>
                    <div className="number-tiles">
                        <NumberTile number={kpi.opportunityRank} title={`${rankingHierarchy[2].p} Rank`} />
                        <NumberTile number={kpi.moduleRank} title={`${rankingHierarchy[3].p} Rank`} />
                        <NumberTile number={kpi.featureRank} title={`${rankingHierarchy[4].p} Rank`} />
                    </div>
                    <div className="form-inputs">
                        <FeatureInputs
                            submitResponse={submitResponse}
                            initialValues={
                                features[featureIndex].responses && features[featureIndex].responses
                            }
                            inputChanged={inputChanged}
                            onCacel={onCacel}
                        />
                    </div>
                </div>
            </section>
            <Modal
                title={<h1 style={{ textTransform: 'none', paddingTop: 15, marginBottom: 0 }}>Roadmap</h1>}
                centered
                className="lg-modal"
                visible={viewRoadmap}
                onOk={() => { }}
                onCancel={() => setViewRoadmap(false)}
            >
                <Roadmap programId={programId} opportunityIndex={opportunityIndex} />
            </Modal>
        </div>
    );
};

FeatureRank.propTypes = {
    program: PropTypes.object,
    opportunityIndex: PropTypes.number,
    initiativeIndex: PropTypes.number,
    featureIndex: PropTypes.number,
    updateProgram: PropTypes.func,
    setFeatureIndex: PropTypes.func,
    onCacel: PropTypes.func,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    opportunitieName: PropTypes.string.isRequired,
    initiativeName: PropTypes.string.isRequired,
    featureName: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
}

export default FeatureRank;