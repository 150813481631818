/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import config from '../../../../config/config';
import PropTypes from 'prop-types';

const { Dragger } = Upload;

const UploadPrograms = ({ projectId, token, handleSuccess, handleError }) => {


    const data = {
        name: 'file',
        multiple: false,
        action: `${config.api}/programs/upload?projectId=${projectId}&timeUnit=Q`,
        headers: {
            Authorization: `${token}`
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                handleSuccess()
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                handleError()
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    }

    return (
        <div>
            {
                data &&
                <Dragger {...data} style={{ height: 500 }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                    </p>
                </Dragger>
            }
        </div>
    );
};

UploadPrograms.propTypes = {
    projectId: PropTypes.string,
    token: PropTypes.string,
    handleError: PropTypes.func,
    handleSuccess: PropTypes.func
}

export default UploadPrograms;