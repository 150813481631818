import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { SetupQuestionsComp, } from '../../../../components/elements/elements';
import { Form, Input, Select, Row, Col, Button, Checkbox, Skeleton, DatePicker } from "antd";
import { useHistory } from "react-router-dom";
import "./pd.scss";
import "./setup-qs.scss";
import PropTypes from "prop-types";
import { FlxHrSB, QuestionHead } from "../../../../components/elements/elements";
import Axios from "axios";
import config from '../../../../config/config';
import _ from 'underscore';
import moment from "moment";
import * as FontIcon from 'react-icons/fa'
import { IconPicker } from 'react-fa-icon-picker';

const { TextArea } = Input;

function createIcon(icon) {
  // eslint-disable-next-line import/namespace
  return React.createElement(FontIcon[icon]);
}

export const ProjectDetailsComp = styled.div`
  /* padding: 0 80px 80px 80px; */
  text-align: center;
`;
const SetupQuestionsComp = styled.div`
  /* margin: 0 36px 0 38px; */
  text-align: left;
`;
const QuestionsComp = styled.div`
  height: 55vh;
  overflow-y: scroll;
`;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function ProjectDetails({ setAssesmentInfo, questionTypes, selectQuestion, users, loadingUsers, loadingQuestions, assesmentId, loading, editQuestions, updateQuestionTypes }) {

  const history = useHistory();
  const [form] = Form.useForm();
  const [assesment, setAssesment] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [mode, setMode] = useState('view');
  const [userResponse, setUserResponse] = useState({ userResponded: [] });

  function finishSubmission(e) {
    if (assesmentId) {
      setAssesmentInfo({
        type: 'PUT',
        data: {
          ...e.assesment,
          validUpto: assesment.validUpto,
          projectId: assesment.projectId,
          questions: assesment.questions,
        }
      });
    } else {
      setAssesmentInfo({
        type: 'POST',
        data: e.assesment
      });
    }

  }

  useEffect(() => {
    if (assesmentId) {
      getAssesment(assesmentId);
      getResponses(assesmentId);
    }
  }, []);

  function selectAll(e) {
    let qtCopy = _.clone(questionTypes)
    for (let i = 0; i < qtCopy.length; i++) {
      for (let j = 0; j < qtCopy[i].questions.length; j++) {
        qtCopy[i].questions[j].selected = e.target.checked;
      }
    }
    console.log(qtCopy)
    updateQuestionTypes(qtCopy);
  }

  async function getAssesment(id) {
    const assesment = await Axios.get(`${config.api}/assesments/${id}`).then(x => x.data);
    setAssesment(assesment);
    form.setFieldsValue({
      assesment: {
        name: assesment.name,
        location: assesment.location,
        type: assesment.type,
        description: assesment.description,
        users: _.pluck(assesment.users, 'email'),
      }
    })
    setLocalLoading(false);
  }

  function editQuestions_forUpdate(index, qIndex, value) {
    let qt = _.clone(assesment)
    assesment.questions[index].questions[qIndex].question = value;
    setAssesment(qt);
  }

  async function getResponses(id) {
    const resp = await Axios.get(`${config.api}/maturity-questions/response/${id}`).then(x => x.data);
    setUserResponse(resp);
    console.log(resp)
  }

  function selectAssesmentQuestion(pIndex, cIndex, status) {
    let assesmentCopy = _.clone(assesment);
    assesmentCopy.questions[pIndex].questions[cIndex].selected = status;
    setAssesment(assesmentCopy);
  }

  function updateQuestions() {
    setMode(mode === 'edit' ? 'view' : 'edit');
  }

  function setIcon(icon, index) {
    let questionTypesCopy = _.clone(questionTypes);
    questionTypesCopy[index].icon = icon;
    updateQuestionTypes(questionTypesCopy);
  }

  function updateTitle(questionType, index) {
    let questionTypesCopy = _.clone(questionTypes);
    questionTypesCopy[index].questionType = questionType;
    updateQuestionTypes(questionTypesCopy);
  }
  function setIcon_Assessment(icon, index) {
    let assessmentCOPY = _.clone(assesment);
    assessmentCOPY.questions[index].icon = icon;
    setAssesment(assessmentCOPY);
  }
  function updateTitle_Assessment(questionType, index) {
    let assessmentCOPY = _.clone(assesment);
    assessmentCOPY.questions[index].questionType = questionType;
    setAssesment(assessmentCOPY);
  }

  return (
    <div className="pdd">
      <h1>New Assessment</h1>
      <p>Please enter information regarding the assessment to be created.</p>
      <div className="flex-container">
        <div className="info-area">
          <h2>Assessment Details</h2>
          <Form
            form={form}
            autoComplete="off"
            onFinish={finishSubmission}
            validateMessages={validateMessages}
            className="onboard-form"
          // initialValues={assesmentInfo}
          >
            <Row>
              <Col span={8}>
                <Form.Item name={["assesment", "name"]} label="Assessment Name" rules={[{ required: true }]} >
                  <Input max={50} className="neo" placeholder="Please enter assessment name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  name={["assesment", "type"]}
                  label="Assessment Type"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Please select assessment type">
                    <Select.Option value="Initiatives">Initiatives</Select.Option>
                    <Select.Option value="Products">Products</Select.Option>
                    <Select.Option value="Strategy &amp; Roadmap">Strategy &amp; Roadmap</Select.Option>
                    <Select.Option value="Solutions">Solutions</Select.Option>
                    <Select.Option value="Customer Experience">Customer Experience</Select.Option>
                    <Select.Option value="Technology">Technology</Select.Option>
                    <Select.Option value="Sales &amp; Marketing">Sales &amp; Marketing</Select.Option>
                    <Select.Option value="Culture">Culture</Select.Option>
                    <Select.Option value="Operations">Operations</Select.Option>
                    <Select.Option value="Performance">Performance</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  name={["assesment", "description"]}
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <TextArea max={120} placeholder="Please enter a description" rows={2}></TextArea>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={16}>
                {!assesmentId ?
                  <Form.Item name={["assesment", "validUpto"]} label="Valid until" rules={[{ required: true }]}>
                    <DatePicker format="MM/DD/YYYY" />
                  </Form.Item>
                  :
                  <>
                    {
                      assesment &&
                      <DatePicker format="MM/DD/YYYY" value={moment(assesment.validUpto)} onChange={(e) => {
                        let x = _.clone(assesment);
                        x.validUpto = e;
                        setAssesment(x);
                      }} />
                    }
                  </>

                }
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Form.Item name={["assesment", "users"]} label="Users">
                  {!loadingUsers ? (
                    <Select
                      placeholder="Please select users"
                      style={{ width: "100%" }}
                      mode="multiple"
                      optionLabelProp="label"
                    >
                      {users.map((user) => (
                        <Select.Option
                          key={user.email}
                          // value={JSON.stringify(user)}
                          value={user.email}
                          label={user.name}
                          style={{ display: "flex" }}
                        >
                          <img
                            style={{ height: 30, borderRadius: "50%" }}
                            src={user.picture}
                            alt="user"
                          />
                          <span style={{ marginLeft: 10 }}>{user.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Skeleton paragraph={{ rows: 0 }} active />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? <>Saving Assessment</> : 'Save Assessment'}
                </Button>
                <Button className="outline" onClick={() => history.goBack()} style={{ marginLeft: 10 }}>
                  Cancel
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
        <div className="questions-area">
          {
            !assesmentId &&
            <SetupQuestionsComp className="qs-page">
              <FlxHrSB>
                <div>
                  <h2>Questionnaire</h2>
                  <Checkbox disabled={userResponse.userResponded.length > 0} onChange={selectAll}>Select All</Checkbox>
                </div>
                <Button onClick={updateQuestions} type='default'>{
                  mode === 'view' ? 'Edit Questionnaire' : 'Save Questionnaire'
                }</Button>
              </FlxHrSB>
              {!loadingQuestions && (
                <QuestionsComp>
                  {questionTypes &&
                    questionTypes.length > 0 &&
                    questionTypes.map((qt, index) => (
                      <div key={index}>

                        {
                          mode === 'view' ?
                            <QuestionHead>
                              {/* <i
                            style={{ marginRight: "10px" }}
                            className={qt.icon}
                          ></i> */}
                              {
                                createIcon(qt.icon)
                              }
                              <span style={{ paddingLeft: 5 }}>{qt.questionType}</span>
                            </QuestionHead>
                            :
                            <div className="title-sec">
                              <IconPicker value={qt.icon} onChange={(v) => setIcon(v, index)} />
                              <Input type="text" value={qt.questionType} onChange={(e) => updateTitle(e.target.value, index)} placeholder="Enter tittle" />
                            </div>
                        }

                        <>
                          {
                            qt.questions.map((question, qIndex) => (
                              <Row className={mode === 'view' ? 'check-row' : 'check-row edit-qs'} key={question._id}>
                                <Checkbox checked={question.selected} onChange={(e) => selectQuestion(question, qIndex, qt, index, e.target.checked)} key={qIndex}>
                                  {
                                    mode === 'view' ? question.question : <TextArea onChange={(e) => editQuestions(index, qIndex, e.target.value)} rows={2} value={question.question} />
                                  }
                                </Checkbox>
                              </Row>
                            ))
                          }
                        </>
                      </div>
                    ))}
                </QuestionsComp>
              )}
              {loadingQuestions && <Skeleton active paragraph={{ rows: 15 }} />}
            </SetupQuestionsComp>
          }

          {/* On Edit Option */}
          {
            (assesment && !localLoading) &&
            <SetupQuestionsComp className="qs-page">
              <FlxHrSB>
                <div>
                  <h2>Questionnaire</h2>
                  <Checkbox disabled={userResponse.userResponded.length > 0} onChange={selectAll}>Select All</Checkbox>
                </div>
                <Button onClick={updateQuestions} disabled={userResponse.userResponded.length > 0} type='default'>{
                  mode === 'view' ? 'Edit Questionnaire' : 'Save Questionnaire'
                }</Button>
              </FlxHrSB>
              <QuestionsComp>
                {
                  assesment.questions.map((qt, index) => (
                    <div key={index}>
                      {
                        mode === 'view' ?
                          <QuestionHead>
                            {/* <i
                            style={{ marginRight: "10px" }}
                            className={qt.icon}
                          ></i> */}
                            {
                              createIcon(qt.icon)
                            }
                            <span style={{ paddingLeft: 5 }}>{qt.questionType}</span>
                          </QuestionHead>
                          :
                          <div className="title-sec">
                            <IconPicker value={qt.icon} onChange={(v) => setIcon_Assessment(v, index)} />
                            <Input type="text" value={qt.questionType} onChange={(e) => updateTitle_Assessment(e.target.value, index)} placeholder="Enter tittle" />
                          </div>
                      }
                      <>
                        {
                          assesment.questions[index].questions.map((question, qIndex) => (
                            <Row className={mode === 'view' ? 'check-row' : 'check-row edit-qs'} key={question._id}>
                              <Checkbox
                                disabled={userResponse.userResponded.length > 0}
                                checked={question.selected}
                                onChange={(e) => selectAssesmentQuestion(index, qIndex, e.target.checked)}
                                key={qIndex}>
                                {
                                  mode === 'view' ? question.question : <TextArea onChange={(e) => editQuestions_forUpdate(index, qIndex, e.target.value)} rows={2} value={question.question} />
                                }
                              </Checkbox>
                            </Row>
                          ))
                        }
                      </>
                    </div>
                  ))}
              </QuestionsComp>
              {localLoading && <Skeleton active paragraph={{ rows: 15 }} />}
            </SetupQuestionsComp>
          }

        </div>
      </div>
    </div>
  );
}

ProjectDetails.propTypes = {
  setAssesmentInfo: PropTypes.func,
  questionTypes: PropTypes.array,
  selectQuestion: PropTypes.func,
  users: PropTypes.array,
  loadingUsers: PropTypes.bool,
  loadingQuestions: PropTypes.bool,
  assesmentId: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  project: PropTypes.object,
  editQuestions: PropTypes.func,
  updateQuestionTypes: PropTypes.func,
}

export default ProjectDetails;
