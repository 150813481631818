import React, { Component } from "react";
import { Button, Input, message } from "antd";
import axios from "axios";
import config from "../../config/config";
import "../Login/login.scss";
import { Link } from "react-router-dom";
import { NeoButton } from "../../components/elements/elements";

class SetPassword extends Component {
  state = {
    cpassword: "",
    password: "",
    err: false,
    name: null
  };

  componentDidMount(){
    axios({
      method: 'POST',
      url: `${config.api}/users/check-password`,
      data:{
        userId: this.props.match.params.userId,
        projectId: this.props.match.params.projectId
      }
    }).then((resp)=>{
      if(resp.data.msg == "TRUE" || resp.data.msg == "DOES_NOT_EXISTS"){
        this.props.history.push("/company-portal/project/"+this.props.match.params.projectId)
      } else if (resp.data.msg == "FALSE"){
        this.setState({
          name: resp.data.name
        })
      } 
    }).catch(e=>{
      console.log(e)
    })
  }

  // Login
  setPassword() {
    this.setState({
        signIn: "LOGING IN.."
    })
    axios({
      method: "POST",
      url: `${config.api}/users/set-password`,
      data: {
        userId: this.props.match.params.userId,
        password: this.state.cpassword,
        doLogin: true
      },
    }).then((resp) => {
      if(typeof resp.data == 'object'){
        localStorage.setItem("cxpx", JSON.stringify(resp.data));
        axios.defaults.headers.common["Authorization"] = `Bearer ${resp.data.token}`;
        this.props.history.push(`/company-portal/project/${this.props.match.params.projectId}`)
      } else {
        message.error("Error occured while setting the password")
      }
      
    }).catch((e)=>{
        this.setState({
            err: true,
            signIn: "SIGN IN"
        })
    })
  }

  cachedUrl = () => {
    let url = sessionStorage.getItem("url-to-go");
    if(url){
      sessionStorage.removeItem("url-to-go")
      window.location.href = url
    }
  }


  keyDown(e){
    if(event.key === 'Enter'){
      if(this.state.password === this.state.cpassword){
        this.setPassword()
      } else {
        this.setState({
          err: true
        })
      }
    }
  }

  render() {
    return (
      <div className="Aligner">
            <div className="leff-items">
              <div>
                <img src={require('../../assets/icons/WelcomeText.png')} style={{height:'324px'}} />
              </div>
            </div>
            <div className="container cards Aligner-item">
                <div className="section">
                    <div className="row">
                    <div className="col s12 m4">
                        <div className="card">
                            <div className="icon-block">
                                <span className="Sign-in">
                                  {
                                    this.state.name ? 
                                    <>
                                      Welcome, {this.state.name} <br/>
                                      Please set your password
                                    </>
                                    :
                                    null
                                  }
                                    
                                </span>
                                <div className="Sign-in-to-start-your-assessment">
                                    {/* Please create your password bellow */}
                                </div>
                            </div>
                            <div className="input-items">

                                <div className="group">      
                                    <input onKeyDown={this.keyDown.bind(this)} onChange={(e)=>this.setState({password: e.target.value})} type="password" required />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Password</label>
                                </div>

                                <div className="group">      
                                    <input onKeyDown={this.keyDown.bind(this)} onChange={(e)=>this.setState({cpassword: e.target.value})} type="password" required />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Confirm Password</label>
                                </div>

                            </div>
                            <div className="invalid" style={{marginTop: 0}}>
                                {
                                    <span style={this.state.err ? {visibility: 'visible'} : {visibility: 'hidden'}}>Password is mismatched with confirm password</span>
                                }
                            </div>
                            <div className="area">
                                <NeoButton onClick={()=>this.setPassword()} disabled={this.state.password.length == 0 || (this.state.password != this.state.cpassword)}  primary>
                                  SET PASSWORD
                                </NeoButton>
                            </div>
                            <div className="copy">
                                <p>
                                {/* By signing up, you are agree to 
                                Honeycom3 Terms and Conditions &amp; Privacy Policy */}
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}

export default SetPassword;
