import { Breadcrumb, Form, Row, Col, Input, Button, message, Radio, Skeleton } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import config from '../../../config/config';

const stateX = {
    validateMessages: {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    }
}

const AddCompanyUser = () => {

    const history = useHistory();
    const { userId } = useParams();
    const [buttonName, setButtonName] = useState('Create User');
    const [disabled, setDisabled] = useState(false);
    const [initialValues, setInitialValues] = useState(null)

    async function onFinish(e) {
        setDisabled(true);
        setButtonName(userId ? 'Updating user...' : 'Creating User...');
        let type = {};
        if (e.user.type === 'COMPANY_ADMIN') {
            type = {
                type: 'COMPANY_ADMIN',
                role: 'CustomerAdmin'
            }
        } else {
            type = {
                role: 'CustomerUser',
                type: 'COMPANY_USER'
            }
        }

        const user = await Axios({
            method: 'POST',
            url: userId ? `${config.api}/auth0/auth0-update-user` : `${config.api}/auth0/auth0-create-user`,
            data: {
                email: e.user.email,
                name: e.user.name,
                ...type
            }
        }).then(r => r.data);
        setDisabled(false);
        setButtonName('Create User');

        if (user.statusCode === 409) {
            message.error('User already exists');
        } else {
            message.success(userId ? 'User updated successfully, it might take a little while to reflect' : 'User created successfully, it might take a little while to reflect');
            history.push('/company-admin/users');
        }
    }


    useEffect(() => {
        if (userId) {
            setButtonName('Update User');
            Axios.post(`${config.api}/auth0/auth0-user-by-id?userId=${atob(userId)}`).then(x => {
                console.log({
                    name: x.data.name,
                    email: x.data.email,
                    type: x.data.user_metadata.type
                });
                setInitialValues({
                    user: {
                        name: x.data.name,
                        email: x.data.email,
                        type: x.data.user_metadata.type
                    }
                })
            })
        } else {
            setInitialValues({
                user: {
                    name: '',
                    email: '',
                    type: 'COMPANY_USER'
                }
            })
        }
    }, [])

    return (
        <div className="projects-page">
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-admin">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/company-admin/users">Users</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {
                        userId ? 'Update User' : 'Create User'
                    }
                </Breadcrumb.Item>
            </Breadcrumb>
            <h1>
                {
                    userId ? 'Edit User' : 'Create User'
                }
            </h1>
            <div>
                {
                    initialValues ?
                        <Form
                            name="nest-messages"
                            autoComplete="off"
                            className="onboard-form"
                            onFinish={onFinish}
                            validateMessages={stateX.validateMessages}
                            initialValues={initialValues}
                        >
                            <Row>
                                <Col span={8}>
                                    <Form.Item name={['user', 'name']} label="Name of the user" rules={[{ required: true }]}>
                                        <Input className="neo" placeholder="Please enter the name" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={8}>
                                    <Form.Item name={['user', 'email']} label="Email ID" rules={[{ required: true, type: "email", message: "Please enter a valid email id" }]}>
                                        <Input className="neo" type="email" placeholder="Please enter user email id" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={8}>
                                    <Form.Item name={['user', 'type']} label="Role" rules={[]}>
                                        <Radio.Group>
                                            <Radio value="COMPANY_USER" checked={true}>User</Radio>
                                            <Radio value="COMPANY_ADMIN">Admin</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={disabled}>
                                        {buttonName}
                                    </Button>
                                    <Link to="/company-admin/users">
                                        <Button className="outline" style={{ marginLeft: 10 }}>
                                            Cancel
                                        </Button>
                                    </Link>
                                </Form.Item>
                            </Row>

                        </Form>
                        :
                        <div style={{ width: '500px' }}>
                            <Skeleton paragraph={{ rows: 3 }} active={true} size={'small'} />
                        </div>
                }
            </div>
        </div>
    );
};

export default AddCompanyUser;