import React, { useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import PropTypes from 'prop-types';
import config from '../config/config';

function getRole(user) {
    const userRole = user[`${config.auth0.audience}/roles`];
    if (userRole.includes('SuperAdmin')) {
        return 'SuperAdmin';
    } else if (userRole.includes('CustomerAdmin')) {
        return 'CustomerAdmin';
    } else {
        return 'CustomerUser';
    }
}


const UtilComp = ({ user }) => {

    useEffect(() => {
        axios.interceptors.response.use(null, err => {
            if (err.response.status === 401) {
                message.error("Session expired, please login again");
                const role = getRole(user);

                if (role === 'CustomerUser') {
                    window.location.href = "/company-portal/unauthorized";
                } else if (role === 'CustomerAdmin') {
                    window.location.href = "/company-admin/unauthorized";
                } else {
                    window.location.href = "/super-admin/unauthorized";
                }
            }
            return Promise.reject(err);
        });
    }, [])
    return <>
    </>
}

UtilComp.propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}

export default UtilComp;