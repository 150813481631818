import { Breadcrumb, Button } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { InfoText } from '../../../../components/elements/elements';
import PropTypes from 'prop-types';
import './list.scss';
import AddTile from './Tiles/AddTile';
// import AddProgram from './AddProgram';
import { MenuOutlined } from '@ant-design/icons';
import OpportunityTile from './Tiles/OpportunityTile';
import AddOpportunity from './AddOpportunity';
import { CustomerAdminContext } from '../../CustomerAdminContext';


const Opportunities = ({ opportunities, setColView, onSaveOpportunity, setOpportunityIndex, configs, editOpportunity, deleteOpportunity, projectId, projectName, programName, navigate, accessFrom, showPrioritization }) => {
    const [mode, setMode] = useState('view');
    const { rankingHierarchy } = useContext(CustomerAdminContext);

    return (
        <div className="projects-page">
            {
                mode === 'view' ?
                    <section>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/company-admin">Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/company-admin/projects">{rankingHierarchy[0].p}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={navigate}>
                                <a>{programName}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {rankingHierarchy[2].p}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1>{rankingHierarchy[2].p}</h1>
                            <div>
                                {
                                    accessFrom === 'IR' && <Button type="default" title="List View" onClick={setColView}>
                                        <MenuOutlined /> Show as Cards
                                    </Button>
                                }
                            </div>
                        </div>
                        <InfoText>
                            You have {opportunities.length} <span style={{ color: 'var(--primary-color)' }}>active</span> {(rankingHierarchy[0].p).toLowerCase()}(s)
                        </InfoText>
                        <div className="list-area">
                            {
                                opportunities.map((opportunity, index) => <OpportunityTile
                                    opportunity={opportunity}
                                    key={index}
                                    onClick={() => {
                                        console.log("I am index", index);
                                        setOpportunityIndex(index)
                                    }}
                                    configs={configs}
                                    editOpportunity={() => editOpportunity(index)}
                                    deleteOpportunity={() => deleteOpportunity(index)}
                                    accessFrom={accessFrom}
                                    showPrioritization={() => {
                                        showPrioritization(index)
                                    }}
                                />)
                            }
                            <AddTile type={`${rankingHierarchy[2].s}`} onNewClick={() => setMode('add')} />
                        </div>
                    </section>
                    :
                    <AddOpportunity
                        onCancel={() => {
                            setMode('view')
                        }}
                        onSaveOpportunity={(data) => {
                            onSaveOpportunity(data);
                            setMode('view')
                        }}
                        projectName={projectName}
                        programName={programName}
                        navigate={navigate}
                        projectId={projectId}
                    />
            }
        </div>
    );
};

Opportunities.propTypes = {
    opportunities: PropTypes.array.isRequired,
    setColView: PropTypes.func.isRequired,
    onSaveOpportunity: PropTypes.func.isRequired,
    setOpportunityIndex: PropTypes.func.isRequired,
    configs: PropTypes.object.isRequired,
    editOpportunity: PropTypes.func.isRequired,
    deleteOpportunity: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    accessFrom: PropTypes.string,
    showPrioritization: PropTypes.func,
}


Opportunities.defaultProps = {
    accessFrom: 'IR'
}

export default Opportunities;
