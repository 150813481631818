import React, { useState, useEffect, useContext } from 'react';
import { ProjectDetailsComp, ResultArea, MaturityArea, FlxHrSB } from '../../../components/elements/elements';
import MaturityResultCard from '../../../components/common/Card/MaturityResultCard';
import Axios from 'axios';
import config from '../../../config/config';
import _ from 'underscore';
import MaturityDetailsModal from './MaturityDetailsModal';
import { Breadcrumb, Button, message, Modal } from 'antd';
import MaturityResultSketch from './MaturityResultSketch';
import ProductLaunchModal from './ProductLaunchModal';
import { Link, useParams, useHistory } from 'react-router-dom';
import './maturity-results.scss';
import MaturityFinalTop from '../../../components/common/Card/MaturityFinalTop';
import imageRef from "./AssesmentList/empty-assesment.svg";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/actions'
import PropTypes from 'prop-types';
import { shouldShow, countSections } from '../../../utils/assessmentFunction';
import { CustomerAdminContext } from '../CustomerAdminContext'

const { confirm } = Modal;

const MaturityResult = (props) => {


    const { assesmentId } = useParams();//"619a6f3c39715818cd6aa943";// 
    const [questions, setQuestions] = useState(null);
    const [modalDetails, setModalDetails] = useState(null)
    const [modalVisibility, setModalVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const [plModal, setPLModal] = useState(null);
    const [assesment, setAssesment] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [sectionCount, setSectionCounnt] = useState(0);
    const { rankingHierarchy } = useContext(CustomerAdminContext);


    const history = useHistory();

    useEffect(() => {
        //Get Project details

        Axios.get(`${config.api}/maturity-questions/all/${assesmentId}`).then(resp => {
            setQuestions(resp.data.data)
            setSectionCounnt(countSections(resp.data.data));
            getAssesmentDetails();
        })

    }, []);

    function getAssesmentDetails() {
        Axios.get(`${config.api}/maturity-questions/response/${assesmentId}`).then(resp => {
            setAssesment(resp.data);
            setLoading(false);
        });
    }

    function showConfirm() {
        confirm({
            title: 'Are you sure you want to delete this assesment?',
            icon: <ExclamationCircleOutlined />,
            content: 'You will not be able to recover this later',
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk() {
                setDeleting(true);
                Axios.delete(`${config.api}/assesments/${assesment._id}`).then(() => {
                    message.success('Assessment deleted successfully');
                    setDeleting(true);
                    history.push({
                        pathname: `/company-admin/maturity-assesments/${props.reducer.dataReducer.project._id}`
                    });
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function sendReminder() {
        Axios.post(`${config.api}/assesments/reminder`, {
            assesmentId: assesment._id
        }).then((resp) => {
            console.log(resp.data);
            message.success('Reminder sent successfully');
        });
    }

    return (
        <section className="projects-page maturity-results">

            {
                loading ?
                    <MaturityResultSketch />
                    :
                    <>
                        {assesment && questions && questions.length && !loading > 0 ?
                            <ProjectDetailsComp id="comp-xhp" style={{ textAlign: 'left', padding: 0 }}>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item>
                                        <Link to="/company-admin">Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to="/company-admin/projects">
                                            {rankingHierarchy[0].p}
                                        </Link>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item>
                                        <Link to={`/company-admin/project/${props.reducer.dataReducer.project._id}`}>{props.reducer.dataReducer.project.projectName}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={{
                                            pathname: `/company-admin/maturity-assesments/${props.reducer.dataReducer.project._id}`
                                        }}>Assessments</Link>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item>
                                        {assesment.name}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h1>{
                                        assesment.name
                                    }</h1>

                                    <div style={{ display: 'flex' }}>
                                        <Link to={{
                                            pathname: `/company-admin/edit-assesment/${assesmentId}`
                                        }}>
                                            <Button type="default">
                                                <EditOutlined /> Edit
                                            </Button>
                                        </Link>

                                        <Button type="default" onClick={showConfirm} disabled={deleting} style={{ marginLeft: 10 }}>
                                            <DeleteOutlined /> {
                                                deleting ? 'Deleting...' : 'Delete'
                                            }
                                        </Button>
                                        <Button style={{ marginLeft: 10 }} onClick={() => {
                                            // printDiv('resultArea')
                                            window.print()
                                        }} type="default">Download PDF</Button>
                                    </div>

                                </div>

                                <MaturityFinalTop
                                    maturityPer={
                                        (_.reduce(questions, (memo, num) => (memo + num.percantage), 0) / sectionCount).toFixed(0)
                                    }
                                    status="UN"
                                    no_of_questions={
                                        _.reduce(questions, (memo, num) => {
                                            const questionCount = _.filter(num.questions, x => x.selected)
                                            return memo + questionCount.length
                                        }, 0)
                                    }
                                    no_of_sections={sectionCount}
                                    index={1}
                                    openProductLaunch={() => setPLModal({
                                        percantage: (_.reduce(questions, (memo, num) => (memo + num.percantage), 0) / sectionCount).toFixed(0),
                                        showModal: true,
                                        thresold: 100
                                    })}
                                    assesment={assesment}
                                />
                                <ResultArea id="resultArea">
                                    <MaturityArea className="maa">

                                        {
                                            questions &&
                                            questions.map((qs, index) => (
                                                shouldShow(qs) &&
                                                <MaturityResultCard
                                                    maturityPer={qs.percantage.toFixed(0)}
                                                    name={qs.questionType}
                                                    thresholdPer={qs.thresold}
                                                    index={index + 1}
                                                    key={index}
                                                    noS={sectionCount}
                                                    openModal={() => {
                                                        setModalDetails(qs)
                                                        setModalVisibility(true)
                                                    }}
                                                />
                                            ))
                                        }

                                    </MaturityArea>
                                </ResultArea>

                                {modalDetails &&
                                    <MaturityDetailsModal
                                        visible={modalVisibility}
                                        modalDetails={modalDetails}
                                        onOk={() => { }}
                                        noOfSets={sectionCount}
                                        onCancel={() => { setModalVisibility(false) }}
                                    />}
                                {
                                    plModal &&
                                    <ProductLaunchModal
                                        visible={plModal.showModal}
                                        modalDetails={plModal}
                                        onOk={() => { }}
                                        onCancel={() => setPLModal({ showModal: false, percantage: 0, thresold: 0 })}
                                    />
                                }
                            </ProjectDetailsComp>
                            :
                            <div className="no-result">
                                {props.reducer && props.reducer.dataReducer && props.reducer.dataReducer.project &&
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item>
                                            <Link to="/company-portal">Home</Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to="/company-admin/projects">
                                                {rankingHierarchy[0].p}
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={`/company-admin/project/${props.reducer.dataReducer.project._id}`}>{props.reducer.dataReducer.project.projectName}</Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={{
                                                pathname: `/company-admin/maturity-assesments/${props.reducer.dataReducer.project._id}`,
                                                state: props.reducer.dataReducer.project
                                            }}>Assessments</Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            {assesment.name}
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                }
                                <FlxHrSB>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <h1>{assesment.name}</h1>

                                        <div>
                                            <Link to={{
                                                pathname: `/company-admin/edit-assesment/${assesmentId}`
                                            }}>
                                                <Button type="default">
                                                    <EditOutlined /> Edit
                                                </Button>
                                            </Link>

                                            <Button type="default" onClick={showConfirm} disabled={deleting} style={{ marginLeft: 10 }}>
                                                <DeleteOutlined /> {
                                                    deleting ? 'Deleting...' : 'Delete'
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </FlxHrSB>
                                <div className="empty-hm">
                                    <div className="illustration">
                                        <img src={imageRef} alt="star" />
                                    </div>
                                    <div className="greeting">
                                        <h1>No response</h1>
                                        <p>No response has been captured, click &apos;Send Reminder`&apos; to notify all</p>
                                        <div>
                                            <Button onClick={sendReminder} type="primary">Send Reminder</Button>
                                            <Link to={`/company-admin/assessment/${assesmentId}`}>
                                                <Button className="outline" style={{ marginLeft: 10 }}>Take Assesment Survey</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
            }


            <div id="printArea">

            </div>

        </section>
    );
};


MaturityResult.propTypes = {
    actions: PropTypes.func,
    reducer: PropTypes.object
}


function mapStateToProps(state) {
    return {
        reducer: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaturityResult);

