import { Input } from 'antd';
import React, {useState,useEffect} from 'react';
import './dashboard.scss';

const ScoreInputs = (props) => {
    return <div className="wd-91">
        <div className="input-score-label">Please Input Score</div>
        <div className="flex-x">
                <div className="inp-score">
                    <label>Dependency</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        type="number"
                        max={5}
                        placeholder="Dependency"
                        value={props.responses.dependency}
                        onChange={(e)=>props.changeResponse('dependency',e.target.value)}
                    />
                </div>  
                <div className="inp-score">
                    <label>Competitive</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Competitive"
                        value={props.responses.competitive}
                        onChange={(e)=>props.changeResponse('competitive',e.target.value)}
                    />
                </div> 
                <div className="inp-score">
                    <label>Innovation</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Innovation"
                        value={props.responses.innovation}
                        onChange={(e)=>props.changeResponse('innovation',e.target.value)}
                    />
                </div>  
                <div className="inp-score">
                    <label>Implementation</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Implementation"
                        value={props.responses.implementation}
                        onChange={(e)=>props.changeResponse('implementation',e.target.value)}
                    />
                </div>  
                <div className="inp-score">
                    <label>Integration</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Integration"
                        value={props.responses.integration}
                        onChange={(e)=>props.changeResponse('integration',e.target.value)}
                    />
                </div>  
                <div className="inp-score">
                    <label>Maintainance</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Maintainance"
                        value={props.responses.maintainance}
                        onChange={(e)=>props.changeResponse('maintainance',e.target.value)}
                    />
                </div> 
                <div className="inp-score">
                    <label>Cost</label>
                    <Input 
                        className="neo neo-cost neo-color-input"
                        tabIndex={1}
                        placeholder="Cost"
                        value={props.responses.cost}
                        onChange={(e)=>props.changeResponse('cost',e.target.value)}
                    />
                </div> 
               
                <div className="inp-score">
                    <label>Reusability</label>
                    <Input 
                        className="neo neo-blue neo-color-input"
                        tabIndex={1}
                        placeholder="Reusability"
                        value={props.responses.reusability}
                        onChange={(e)=>props.changeResponse('reusability',e.target.value)}
                    />
                </div> 
                <div className="inp-score">
                    <label>Audience Size</label>
                    <Input 
                        className="neo neo-red neo-color-input"
                        tabIndex={1}
                        placeholder="Audience Size"
                        value={props.responses.audienceSize}
                        onChange={(e)=>props.changeResponse('audienceSize',e.target.value)}
                    />
                </div> 
                <div className="inp-score">
                    <label>Strategic</label>
                    <Input 
                        className="neo neo-green neo-color-input"
                        tabIndex={1}
                        placeholder="Strategic"
                        value={props.responses.strategic}
                        onChange={(e)=>props.changeResponse('strategic',e.target.value)}
                    />
                </div> 
                <div className="inp-score">
                    <label>Duration</label>
                    <Input 
                        className="neo neo-dependency neo-color-input"
                        tabIndex={1}
                        placeholder="Duration"
                        value={props.responses.duration}
                        onChange={(e)=>props.changeResponse('duration',e.target.value)}
                    />
                </div> 
        </div>
    </div>
}

export default ScoreInputs;