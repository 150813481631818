import { message, Skeleton } from 'antd';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import config from "../../../config/config";
import AddOpportunity from '../PrioritizeRanking/LaunchPad/AddOpportunity';
import AddProgram from '../PrioritizeRanking/LaunchPad/AddProgram';
import Programs from '../PrioritizeRanking/LaunchPad/Programs';
import _ from 'underscore';
import Opportunities from '../PrioritizeRanking/LaunchPad/Opportunities';
import InitiativePrioritizationHome from './InitiativePrioritizationHome';
import IpBubble from './IpBubble';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const IPHome = ({ reducer }) => {

    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [programIndex, setProgramIndex] = useState(0);
    const [opportunityIndex, setOpportunityIndex] = useState(0);
    const [mode, setMode] = useState('program');

    const { projectId } = useParams();
    const project = reducer.dataReducer.project;

    const getPrograms = async (projectId) => {
        setLoading(true);
        const programs = await Axios.post(`${config.api}/programs/by-project`, {
            projectId,
        });
        setLoading(false);
        return programs.data;
    }

    useEffect(() => {
        getPrograms(projectId).then(x => {
            setPrograms(x);
        });
    }, []);

    function onSaveOpportunity(opportunity) {
        let p = programs[programIndex];
        p.opportunities.push(opportunity);
        Axios.put(`${config.api}/programs/${programs[programIndex]._id}`, p).then(x => {
            let pCopy = _.clone(programs)
            pCopy[programIndex] = x.data;
            setPrograms(pCopy);
            message.success('Scenario created successfully!');
        });
    }


    function renderMode() {
        switch (mode) {
            case 'program':
                return <Programs
                    programs={programs}
                    projectId={projectId}
                    onAddProgram={() => {
                        getPrograms(projectId).then(x => setPrograms(x))
                    }}
                    setProgramIndex={(index) => {
                        setMode('opportunity');
                        setProgramIndex(index)
                    }}
                    editProgram={(index) => {
                        setProgramIndex(index);
                        setMode('editProgram')
                    }}
                    deleteProgram={async (index) => {
                        await Axios.delete(`${config.api}/programs/${programs[index]._id}`);
                        message.success('Program deleted successfully');
                        let pCopy = _.clone(programs);
                        pCopy.splice(index, 1);
                        setPrograms(pCopy);
                        setMode('program');
                    }}
                    projectName={project.projectName}
                    navigateTo={() => {
                        setMode('program');
                    }}
                    accessFrom={'IP'}
                />
            case 'editProgram':
                return <AddProgram
                    program={programs[programIndex]}
                    type="PUT"
                    onCacel={() => {
                        setMode('program');
                    }}
                    onAddProgram={(px) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex] = px;
                        setPrograms(pCopy);
                        setMode('program');
                    }}
                    projectName={project.projectName}
                />
            case 'opportunity':
                return <Opportunities
                    opportunities={programs[programIndex].opportunities}
                    onSaveOpportunity={onSaveOpportunity}
                    configs={programs[programIndex].config}
                    setOpportunityIndex={(index) => {
                        console.log(index)
                        setOpportunityIndex(index);
                        setMode('inititive');
                    }}
                    editOpportunity={() => setMode('editOpportunity')}
                    deleteOpportunity={async (index) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities.splice(index, 1);
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('opportunity');
                        message.success('Opportunity deleted successfully');
                    }}
                    projectName={project.projectName}
                    projectId={projectId}
                    programName={programs[programIndex].programName}
                    navigate={() => setMode('program')}
                    accessFrom={'IP'}
                    showPrioritization={(index) => {
                        setOpportunityIndex(index);
                        setMode('prioritization');
                    }}
                />
            case 'editOpportunity':
                return <AddOpportunity
                    opportunity={programs[programIndex].opportunities[opportunityIndex]}
                    type="PUT"
                    onCacel={() => { }}
                    projectName={reducer.dataReducer.project.projectName}
                    programName={programs[programIndex].programName}
                    projectId={projectId}
                    onCancel={() => setMode('opportunity')}

                    onSaveOpportunity={async (opportunity) => {
                        let pCopy = _.clone(programs);
                        pCopy[programIndex].opportunities[opportunityIndex] = opportunity;
                        const newProgram = await Axios.put(`${config.api}/programs/${pCopy[programIndex]._id}`, pCopy[programIndex]).then(x => x.data);
                        pCopy[programIndex] = newProgram;
                        setPrograms(pCopy);
                        setMode('opportunity');
                        message.success('Scenario updated successfully');
                    }}

                />

            case 'prioritization':
                return <InitiativePrioritizationHome
                    programId={programs[programIndex]._id}
                    oppIndex={opportunityIndex}
                    showBubbleChart={() => setMode('bubbleChart')}
                    projectId={project._id}
                    projectName={project.projectName}
                    navigate={(type) => setMode(type)}
                    opportunityIndex={opportunityIndex}
                    programIndex={programIndex}
                />
            case 'bubbleChart':
                return <IpBubble

                />
            default: return null;
        }

    }

    if (loading) {
        return <div className="projects-page">
            <Skeleton active paragraph={{ rows: 10 }} />
        </div>
    } else {
        return (
            <div>
                {renderMode()}
            </div>
        );
    }

};

IPHome.propTypes = {
    reducer: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        reducer: state
    };
}


export default connect(
    mapStateToProps
)(IPHome);

