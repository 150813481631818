import _ from 'underscore'

// Function to determine wheather the section should be show in Maturity assessment results
function shouldShow(section){
    const f = _.filter(section.questions, x => x.selected);
    return f.length > 0;
}


// function that returns no of sections that has questions selected
function countSections(sections){
    let count = 0;
    for(let i = 0; i < sections.length; i++){
        let shouldCount = shouldShow(sections[i]);
        if(shouldCount){
            count ++
        }
    }
    return count;
}

export {
    shouldShow,
    countSections
}