/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './timeline.scss';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'underscore';

const colors = {
  dependency: '#9CD34D ',
  competitive: '#9CD34D ',
  innovation: '#9CD34D ',
  implementation: '#9CD34D ',
  integration: '#9CD34D ',
  maintainance: '#9CD34D ',
  cost: '#E6E65C',
  reusability: '#84A2EF',
  audienceSize: '#F2B960',
  strategic: '#67F263',
  duration: '#EAE64E',
};

const sorts = {
  dependency: 'Dependency',
  competitive: 'Competitive',
  innovation: 'Innovation',
  implementation: 'Implementation',
  integration: 'Integration',
  maintainance: 'Maintainance',
  cost: 'Cost',
  reusability: 'Reusability',
  audienceSize: 'Audience Size',
  strategic: 'Strategic',
  duration: 'Duration',
};

const viewportWidth = 1300;

const Timeline = ({ data, sort }) => {
  function createQuater(quater, type) {
    const arr = [];
    for (let i = 0; i < quater; i += 1) {
      if (type === 'text') {
        arr.push(
          <div key={i} className="quater">
            {
              i > 0 && `${data.unit}${i}`
            }
          </div>,
        );
      } else {
        arr.push(<div key={`grid_${i}`} className="internal-flexbox" />);
      }
    }
    return arr;
  }

  function getBarWidth(duration, starts) {
    const marginLeft = quaterLeft(starts);
    const quaterWidth = viewportWidth / data.quater;
    const barLen = (quaterWidth * ((duration / data.quater) * 4));
    const totalBar = barLen + marginLeft
    // return barLen;
    if (totalBar > viewportWidth) {
      let l = totalBar - viewportWidth;
      return barLen - l;
    }
    return barLen
  }

  function quaterLeft(dateX) {
    const quaterWidth = viewportWidth / data.quater;
    const date = new Date(dateX);
    const month = date.getMonth() + 1; // It was date.getMonth() + 1
    const year = date.getFullYear();
    const quater = (year - data.firstYear) * 4;
    return (quaterWidth * quater) + (month / 4) * quaterWidth;
  }

  function weekLeft(dateX) {
    // get first date;
    const dx = _.sortBy(data.time, 'starts');
    const startsWith = moment(dx[0].starts);
    const dt = moment(dateX);
    const diff = dt.diff(startsWith, 'weeks');
    const quaterWidth = viewportWidth / data.quater;
    return diff * quaterWidth;
  }

  function monthLeft(dateX) {
    // get first date;
    const dx = _.sortBy(data.time, 'starts');
    const startsWith = moment(dx[0].starts);
    const dt = moment(dateX);
    const diff = dt.diff(startsWith, 'months');
    const quaterWidth = viewportWidth / data.quater;
    return diff * quaterWidth;
  }

  function getLeftMargin(dateX) {
    if (data.unit === 'Q') {
      return quaterLeft(dateX);
    } if (data.unit === 'W') {
      return weekLeft(dateX);
    } if (data.unit === 'M') {
      return monthLeft(dateX);
    }
    return null;
  }

  function generateContent(line) {
    const arr = [];
    const ax = Object.keys(line);
    for (let i = 0; i < ax.length; i += 1) {
      if (ax[i] === 'moduleName' || ax[i] === 'lastTime' || ax[i] === 'opportunityName'
        || ax[i] === 'starts' || ax[i] === 'moduleRank'
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }
      arr.push(
        <div className="grid">
          <div className="box" style={{ height: 20, width: 20, background: colors[ax[i]] }} />
          <div className="text">
            {sorts[ax[i]]}
            {' '}
            {
              line[ax[i]]
              && (line[ax[i]]).toFixed(1)
            }
          </div>
        </div>,
      );
    }

    return (
      <div className="pop-card">
        {
          arr
        }
      </div>
    );
  }

  function getTimeLineCss() {
    return {
      minHeight: `${5.57 * data.time.length}vh`,
    };
  }

  return (
    <>
      <div className="timeline">


        {/* <div className="y-axis">
          <div className="high"></div>
          <div className="opp">
            <div>
              
            </div>
          </div>
          <div className="low"></div>
        </div> */}


        <div className="neo-base">
          <div className="timeline">
            {/* style={getTimeLineCss()} */}
            {
              createQuater(data.quater, 'grid')
            }

            <div className="times">
              {
                data.time.map((line, index) => (

                  <div
                    key={index}
                    className="x1"
                    style={{
                      marginLeft: getLeftMargin(line.starts),
                    }}
                  >
                    <label>
                      {line.moduleName}
                      &nbsp;[
                      {
                        line[sort]
                        && (line[sort]).toFixed(1)
                      }
                      ]
                    </label>
                    <Popover content={generateContent(line)} title={`${line.moduleName}(${line.moduleRank})`}>
                      <div
                        style={{ width: getBarWidth(line.duration, line.starts) }}
                        className="barX animate__animated animate__lightSpeedInLeft"
                      />
                    </Popover>
                  </div>

                ))
              }

            </div>

          </div>
          <div className="neo-legend">
            {
              createQuater(data.quater, 'text')
            }
          </div>
        </div>
      </div>

    </>
  );
};

Timeline.propTypes = {
  data: PropTypes.shape({
    firstYear: PropTypes.number,
    lastYear: PropTypes.number,
    programName: PropTypes.string,
    quater: PropTypes.number,
    time: [],
    opportunities: [],
    unit: PropTypes.string,
  }),
  sort: PropTypes.string,
};

Timeline.defaultProps = {
  data: {},
  sort: 'moduleRank',
};

export default Timeline;
