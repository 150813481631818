/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const AddModel = ({
  visible, handleCancel, handleOk, children, title,
}) => (
  <Modal
    title={title}
    centered
    className="md-modal"
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    {children}
  </Modal>
);

AddModel.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};
AddModel.defaultProps = {
  visible: false,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  children: '',
  title: 'Add Something',
};

export default AddModel;
