import React from 'react';
import PropTypes from 'prop-types';
import './user-tile.scss';
import moment from 'moment';
import { Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import config from '../../../config/config';
import { Link } from 'react-router-dom';

const UserTile = ({ user, onDelete, index }) => { //, 

    function cancel() {

    }

    function confirm() {
        Axios.post(`${config.api}/auth0/auth0-delete-user`, {
            id: user.user_id
        }).then(resp => {
            console.log(resp);
            onDelete(index);
            message.success('User deleted successfully');
        }).catch(err => {
            message.error("Could not able to delete the user")
            console.log(err);
        })
    }


    return (
        <Link to={`/company-admin/edit-user/${btoa(user.email)}`}>
            <div className="subs-tile user-tile">
                <div className="head-text">
                    <img src={user.picture} alt="user" />
                    <span>{user.name}</span>
                </div>

                <div style={{ alignItems: 'flex-start' }}>
                    <p className="">Role: {
                        user.user_metadata.type === 'COMPANY_ADMIN' ? 'Admin' : 'User'
                    }</p>
                    <p>
                        {user.email}
                    </p>
                    <p>
                        Last Login: {
                            user.last_login ?
                                moment(user.last_login).fromNow()
                                :
                                'Not Signed up yet'
                        }
                    </p>
                    <p>
                        Created on: {moment(user.created_at).format('MMM Do YYYY')}
                    </p>
                    <Popconfirm
                        title="Are you sure want to delete this user?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <span style={{ color: "#ff9e00" }}>
                            <DeleteOutlined /> Delete User
                        </span>
                    </Popconfirm>
                </div>
            </div>
        </Link>
    );
};

UserTile.propTypes = {
    user: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}

export default UserTile;