import { Breadcrumb, Button, Skeleton } from 'antd';
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FlxHrSB } from '../../../../components/elements/elements';
import config from '../../../../config/config';
import MainTable from './MainTable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UnorderedListOutlined } from '@ant-design/icons';
import AntTable from './AntTable';
import { CustomerAdminContext } from '../../CustomerAdminContext';



const TabularReport = ({ reducer }) => {
    const { rankingHierarchy } = useContext(CustomerAdminContext);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [printView, setPrintView] = useState(false);
    const [programKeys, setProgramKeys] = useState([]);

    const getPrograms = async () => {
        setLoading(true);
        const programs = await Axios.post(`${config.api}/projects/scoring-report`, {
            projectId: reducer.dataReducer.project._id
        });
        setRows(programs.data.data);
        setProgramKeys(programs.data.programKeys);
        setLoading(false)
    }


    window.addEventListener('afterprint', (e) => {
        printView && setTimeout(() => { setPrintView(false); }, 100)
        console.log(e)
    });



    useEffect(() => {
        getPrograms();
    }, [])

    return (
        <div className='projects-page'>
            <div>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to="/company-admin">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/company-admin/projects">
                            {rankingHierarchy[0].p}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/company-admin/project/${reducer.dataReducer.project._id}`}>{reducer.dataReducer.project.projectName}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Scoring Rank Summary
                    </Breadcrumb.Item>
                </Breadcrumb>
                <FlxHrSB>
                    <h1>
                        Scoring Rank Summary
                    </h1>
                    <div>
                        {/* <Button onClick={() => {
                            setPrintView(true);
                            setTimeout(() => {
                                window.print();
                            }, 100)
                        }} type='default'>Print/Download</Button> */}
                        <Link style={{ marginLeft: 10 }} to={`/company-admin/initiative-ranking/${reducer.dataReducer.project._id}`}>
                            <Button type="default" title="List View">
                                <UnorderedListOutlined /> Show {rankingHierarchy[1].p}
                            </Button>
                        </Link>
                    </div>
                </FlxHrSB>
            </div>
            <div>
                {!loading ?
                    (printView ? <MainTable rows={rows} /> : <AntTable programKeys={programKeys} rows={rows} />)
                    :
                    <Skeleton active paragraph={{ rows: 5 }} />
                }

            </div>
        </div>
    );
};

TabularReport.propTypes = {
    reducer: PropTypes.object
}


function mapStateToProps(state) {
    return {
        reducer: state
    };
}


export default connect(
    mapStateToProps
)(TabularReport);
