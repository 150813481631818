import { Spin } from 'antd';
import React from 'react';
import '../Authorize/authorize.scss'
import loading from './undraw_relaunch_day_902d.svg';

const AppLoading = () => {
    return (
        <div className="authorize">
            <img src={loading} style={{height:300}} alt="authorize" />
            <h3 style={{paddingTop: 20}} >
                <Spin />
                &nbsp; Preparing your space time...
            </h3>
        </div>
    );
};

export default AppLoading;