import { Button, DatePicker, Form, Input } from "antd";
import React from "react";
import PropTypes from "prop-types";
import magic from "./assets/magic.png";
const { TextArea } = Input;

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const GFrom = ({ onFinish }) => {
  return (
    <div style={{ marginLeft: "1%", marginTop: "20%" }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          timeline: 10,
          initiative: 8,
        }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label="Problem Statement"
          name="problem"
          rules={[
            {
              required: true,
              message: "Please input your problem statement!",
            },
          ]}
        >
          <TextArea rows={2} placeholder="Describe the problem(within 100 words)" />
        </Form.Item>

        <Form.Item
          label="Duration(in weeks)"
          name="timeline"
          rules={[
            {
              required: true,
              message: "Please input your timeline!",
            },
          ]}
        >
          <Input placeholder="In weeks" />
        </Form.Item>

        <Form.Item
          label="Initiatives"
          name="initiative"
          rules={[
            {
              required: true,
              message: "Please input initiative count!",
            },
          ]}
        >
          <Input placeholder="Initiative count" />
        </Form.Item>

        <Form.Item label="Start Date" name="startDate" {...config}>
          <DatePicker />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            <img src={magic} height={20} width={20} alt="magic" style={{ marginRight: 5 }} />
            Generate Initiatives
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

GFrom.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default GFrom;
