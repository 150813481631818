import { CaretUpOutlined } from "@ant-design/icons";
import { Progress, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  //   BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  //   CartesianGrid,
  XAxis,
  YAxis,
  // Legend,
  Line,
  ComposedChart,
  //   Tooltip,
} from "recharts";

import "./assessment-trends.scss";
import AssTileX from "./AssTileX.jsx";
import propTypes from "prop-types";
import Axios from "axios";
import config from "../../../../config/config";
import moment from "moment";
import _ from "underscore";
import UserList from "./UserList.jsx";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function daysRemaining(edate) {
  var eventdate = moment(edate);
  var todaysdate = moment();
  return eventdate.diff(todaysdate, "days");
}

const AssessmentTrends = ({ projects, currentProject, selectProject }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [assessments, setAssessments] = useState([]);
  const [showUserList, setShowUserList] = useState(false);

  function handleClick(data, index) {
    setActiveIndex(index);
  }

  async function getAssessments() {
    const { data } = await Axios.post(
      `${config.api}/dashboard/assessment-statistics/${currentProject._id}`
    );
    setAssessments(data);
    if (data.length > 0) {
      setActiveIndex(0);
    } else {
      setActiveIndex(-1);
    }
  }

  useEffect(() => {
    getAssessments();
  }, [currentProject]);

  return (
    <div className="assessment-trends-x">
      <div className="header-and-project">
        <h2>Assessment Trends</h2>
        <div className="select-box-project">
          <label>Project</label>
          <Select
            style={{ width: "200px" }}
            onChange={(e) => {
              setActiveIndex(-1);
              selectProject(e);
            }}
            defaultValue={currentProject._id}
          >
            {projects.map((project, index) => (
              <Select.Option key={index} value={project._id}>
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="bar-and-details">
        <div className="bar">
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart width={150} height={40} data={assessments}>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="name" />
              <YAxis />
              <Bar dataKey="maturityScore" onClick={handleClick}>
                {data.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={index === activeIndex ? "#FE5B02" : "#FF9E00"}
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
              <Line
                type="linear"
                dataKey="industryBenchmark"
                stroke="#079507"
                activeDot={{ r: 8 }}
                legendType="none"
                hide={false}
              />
              {/* <Line
                type="linear"
                dataKey="amt"
                stroke="#079507"
                activeDot={{ r: 8 }}
                legendType="none"
                hide={false}
              /> */}
            </ComposedChart>
          </ResponsiveContainer>
          {/* <p className="content">{`Uv of "${data[activeIndex].name}": ${data[activeIndex].uv}`}</p> */}
          <div className="legends-bar">
            <div className="legend-s">
              <CaretUpOutlined style={{ color: "#FFAD90" }} />
              <label>Industry Banchmark</label>
            </div>
            <div className="legend-s">
              <div
                style={{ height: 10, width: 10, backgroundColor: "#079507" }}
              ></div>
              <label>Assessment Score last FY</label>
            </div>
            <div className="legend-s">
              <div
                style={{ height: 10, width: 10, backgroundColor: "#FF9E00" }}
              ></div>
              <label>Industry Banchmark</label>
            </div>
          </div>
        </div>

        <div className="details">
          {activeIndex >= 0 && (
            <>
              <div className="details-header-and-pie">
                <div className="info">
                  <h3>{assessments[activeIndex].name}</h3>
                  <label>Project Name: {currentProject.projectName}</label>
                  <small>
                    Created at:{" "}
                    {moment(assessments[activeIndex].createdAt).format("LLL")}
                  </small>
                  <Link
                    to={`/company-admin/maturity-result/${assessments[activeIndex]._id}`}
                  >
                    Open Assessment Details
                  </Link>
                </div>
                <div className="pie">
                  <div>
                    <Progress
                      className="large-progress"
                      type="circle"
                      percent={30}
                      width={100}
                    />
                    <Progress
                      className="small-progress"
                      type="circle"
                      percent={assessments[activeIndex].maturityScore.toFixed(
                        0
                      )}
                      width={80}
                    />
                  </div>
                </div>
              </div>
              <div className="numbers-snap">
                <AssTileX
                  name="Number of Questions"
                  value={_.reduce(
                    assessments[activeIndex].questions,
                    (memo, num) => {
                      const questionCount = _.filter(
                        num.questions,
                        (x) => x.selected
                      );
                      return memo + questionCount.length;
                    },
                    0
                  )}
                  linkName="Edit Questionnaire"
                  to={`/company-admin/edit-assesment/${assessments[activeIndex]._id}`}
                />
                <AssTileX
                  name="Participants Invited"
                  value={assessments[activeIndex].users.length}
                  linkName="View All"
                  to={"NULL"}
                  onClick={() => {
                    setShowUserList(true);
                  }}
                />
                <AssTileX
                  name="Response Collected"
                  value={assessments[activeIndex].response.length}
                  linkName="Send Reminder"
                />
                <AssTileX
                  name="Will expire in(days)"
                  value={daysRemaining(assessments[activeIndex].validUpto)}
                  linkName="Extend Deadline"
                  to={`/company-admin/edit-assesment/${assessments[activeIndex]._id}`}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {activeIndex >= 0 && (
        <UserList
          isModalVisible={showUserList}
          handleOk={() => setShowUserList(false)}
          handleCancel={() => setShowUserList(false)}
          assessment={assessments[activeIndex]}
        />
      )}
    </div>
  );
};

AssessmentTrends.propTypes = {
  projects: propTypes.array,
  currentProject: propTypes.object,
  selectProject: propTypes.func,
};

export default AssessmentTrends;
