import React from 'react';

const KPI =  (props) => {
    return <>
        <div className="score">
            <div className="opp-rank">
                <div className="rect1">
                    {props.data.opportunityRank}
                </div>
                <span>Scenario Rank</span>
            </div>

            <div className="snd-line">
                <div>
                    <div className="rect">
                        {props.data.featureRank}
                    </div>
                    <span>Feature Rank</span>
                </div>
                <div>
                    <div className="rect">
                        {props.data.moduleRank}
                    </div>
                    <span>Module Rank</span>
                </div>
            </div>

        </div>
    </>
}

export default KPI;