import React, { useContext } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import './ant-table.scss';
import { CustomerAdminContext } from '../../CustomerAdminContext';



const AntTable = ({ rows, programKeys }) => {
    const { rankingHierarchy } = useContext(CustomerAdminContext);
    const render = (text, record) => {
        if (typeof text === 'number') {
            text = text.toFixed(1);
        }

        if (record.type === 'PROGRAM') {
            return {
                props: {
                    style: { background: 'var(--gray2)' },
                    fontWeight: 'bold'
                },
                children: <div>
                    {text}
                </div>
            };
        } else if (record.type === 'OPPORTUNITY') {
            return {
                props: {
                    style: { background: '#CBF1FF' },
                    fontWeight: 'bold'
                },
                children: <div>
                    {text}
                </div>
            };
        } else if (record.type === 'INITIATIVE') {
            return {
                props: {
                    style: { background: '#FDEFDD' }
                },
                children: <div>
                    {text}
                </div>
            };
        } else {
            return {
                props: {
                    style: { background: 'rgb(235 255 252)' },
                    fontWeight: 'bold'
                },
                children: <div>
                    {text}
                </div>
            };
        }
    }


    const columns = [
        { title: rankingHierarchy[1].s, dataIndex: 'program', key: 'program', render, width: 250 }, //,fixed: 'left'
        { title: rankingHierarchy[2].s, dataIndex: 'opportunity', key: 'opportunity', render, width: 250 }, //,fixed: 'left'
        { title: rankingHierarchy[3].s, dataIndex: 'initiative', key: 'initiative', render, width: 250 }, //,fixed: 'left'
        { title: rankingHierarchy[4].s, dataIndex: 'feature', key: 'feature', render, width: 250 }, //,fixed: 'left'

        { title: 'Dependency', dataIndex: 'dependency', key: 'dependency', render, align: 'center', width: 100 },
        { title: 'Competitive', dataIndex: 'competitive', key: 'competitive', render, align: 'center', width: 100 },
        { title: 'Innovation', dataIndex: 'innovation', key: 'innovation', render, align: 'center', width: 100 },
        { title: 'Implementation', dataIndex: 'implementation', key: 'implementation', render, align: 'center', width: 110 },
        { title: 'Integration', dataIndex: 'integration', key: 'integration', render, align: 'center', width: 100 },
        { title: 'Cost', dataIndex: 'cost', key: 'cost', render, align: 'center', width: 100 },
        { title: 'Audience Size', dataIndex: 'audienceSize', key: 'audienceSize', render, align: 'center', width: 100 },
        { title: 'Duration', dataIndex: 'duration', key: 'duration', render, align: 'center', width: 100 },
        { title: 'Strategic', dataIndex: 'strategic', key: 'strategic', render, align: 'center', width: 100 },
        { title: 'Reusability', dataIndex: 'reusability', key: 'reusability', render, align: 'center', width: 100 },
        { title: 'Maintainance', dataIndex: 'maintainance', key: 'maintainance', render, align: 'center', width: 100 },

        { title: `${rankingHierarchy[1].s} Rank`, dataIndex: 'programRank', key: 'programRank', render, align: 'center', width: 100 },// fixed: 'right',
        { title: `${rankingHierarchy[2].s} Rank`, dataIndex: 'opportunityRank', key: 'opportunityRank', render, align: 'center', width: 100 },// fixed: 'right',
        { title: `${rankingHierarchy[3].s} Rank`, dataIndex: 'initiativeRank', key: 'initiativeRank', render, align: 'center', width: 120 },// fixed: 'right',
        { title: `${rankingHierarchy[4].s} Rank`, dataIndex: 'featureRank', key: 'featureRank', render, align: 'center', width: 100 },// fixed: 'right',
    ]

    return (
        <section className='report-view'>
            <Table
                columns={columns}
                dataSource={rows}
                pagination={false}
                // scroll={{ y: 600 }}
                scroll={{ x: 3000, y: 600 }}
            // expandable={{ expandedRowKeys: programKeys, }}
            />
        </section>

    );
};

AntTable.propTypes = {
    rows: PropTypes.array.isRequired,
    programKeys: PropTypes.array.isRequired,
}

export default AntTable;