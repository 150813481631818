import React from 'react';
import {  ProjectDetailsComp } from '../../../components/elements/elements';
import {Skeleton} from 'antd';


const MaturityResultSketch = () => {
    return (
        <section>
            <ProjectDetailsComp style={{textAlign:'left',padding:0}}>
                <Skeleton active />
                <Skeleton active paragraph={{rows:5}} />
                <Skeleton active paragraph={{rows:5}} />
            </ProjectDetailsComp>
        </section>
    );
};

export default MaturityResultSketch;