import React, { useEffect, useState } from 'react';
import '../Programs/programs.scss';
import _ from 'underscore';
import { Button, message, Popconfirm } from 'antd';
import Axios from 'axios';
import PropTypes from 'prop-types';
import config from '../../../../config/config';
import AddModuleForm from './AddModuleForm';
import { FlexBox, NeoButton } from '../../../../components/elements/elements';

const initialState = {
  moduleName: '',
  description: '',
  starts: null,
};

const AddModule = ({
  programId, closeModal, opportunityIndex, type, moduleIndex,
  featureIndex, timeUnit, opp, moduleX, isAdd,
  feature, onDelete,
}) => {
  const [module, setModule] = useState(initialState);

  function changeVal(key, val) {
    const cloned = _.clone(module);
    cloned[key] = val;
    setModule(cloned);
  }
  useEffect(() => {
    if (opp) {
      setModule({
        moduleName: opp.opportunityName,
        description: opp.description,
      });
    } else if (moduleX) {
      setModule({
        moduleName: moduleX.moduleName,
        description: moduleX.description,
        starts: moduleX.starts,
      });
    } else if (feature) {
      setModule({
        moduleName: feature.feature,
        description: feature.description,
      });
    } else {
      setModule(initialState);
    }
    if (isAdd) {
      setModule(initialState);
    }
  }, []);

  async function saveModule() {
    const loading = message.loading('Adding module to the program...');
    const getP = await Axios.get(`${config.api}/programs/${programId}`);
    const program = getP.data;
    if (type === 'OPPORTUNITY') {
      if (opp) {
        program.opportunities[opportunityIndex].opportunityName = module.moduleName;
        program.opportunities[opportunityIndex].description = module.description;
      } else {
        program.opportunities.push({
          opportunityName: module.moduleName,
          description: module.description,
          modules: [],
        });
      }
    } else if (type === 'MODULE') {
      if (moduleX) {
        program.opportunities[opportunityIndex].modules[moduleIndex].moduleName = module.moduleName;
        program.opportunities[opportunityIndex].modules[moduleIndex]
          .description = module.description;
        program.opportunities[opportunityIndex].modules[moduleIndex].starts = module.starts;
      } else {
        program.opportunities[opportunityIndex].modules.push({
          ...module,
          features: [],
        });
      }
    } else if (type === 'FEATURE') {
      if (feature) {
        program.opportunities[opportunityIndex]
          .modules[moduleIndex]
          .features[featureIndex].feature = module.moduleName;
        program.opportunities[opportunityIndex]
          .modules[moduleIndex]
          .features[featureIndex].description = module.description;
      } else {
        program.opportunities[opportunityIndex].modules[moduleIndex].features.push({
          feature: module.moduleName,
          description: module.description,
        });
      }
    }

    Axios.put(`${config.api}/programs/${programId}`,
      program).then((resp) => {
      loading();
      message.success('Proram Saved successfully!');
      setModule(initialState);
      closeModal(resp.data);
    });
  }

  function isDisabled() {
    if (type === 'MODULE') {
      return module.moduleName.length === 0 || !module.starts;
    }
    return module.moduleName.length === 0;
  }

  async function deleteIt() {
    onDelete(type, 0);
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="add-program">
      <FlexBox flexDirection="column" className="program-flex">
        <AddModuleForm
          module={module}
          changeForm={(key, val) => changeVal(key, val)}
          type={type.toLowerCase()}
          timeUnit={timeUnit}
        />

        <div>
          <div className="devider" />
          <div>
            {!isAdd && (
            <Popconfirm
              title="Are you sure delete this?"
              onConfirm={deleteIt}
              okText="Yes"
              cancelText="No"
            >

              <NeoButton>
                Delete
              </NeoButton>
            </Popconfirm>
            )}
            <NeoButton onClick={saveModule} disabled={isDisabled()} primary style={{ float: 'right' }}>
              Save
              {' '}
              {type}
            </NeoButton>

          </div>
        </div>
      </FlexBox>
    </div>
  );
};

AddModule.propTypes = {
  programId: PropTypes.string,
  closeModal: PropTypes.func,
  opportunityIndex: PropTypes.number,
  type: PropTypes.string.isRequired,
  moduleIndex: PropTypes.number,
  timeUnit: PropTypes.string,
};

AddModule.defaultProps = {
  programId: '',
  closeModal: PropTypes.func,
  opportunityIndex: 0,
  moduleIndex: 0,
  timeUnit: '',
};

export default AddModule;
