import React, { Component } from 'react';
import Axios from 'axios';
import config from '../../../config/config';
import { WelcomeUser,ModuleArea, ButtonArea,ProjectDetailsComp } from '../../../components/elements/elements';
import NoAccess from '../../../components/common/NoAccess/NoAccess';
import ModuleCard from '../../../components/common/Card/ModuleCard';
import _ from 'underscore';
import ClientHomeSketch from '../ClientHomeSketch';


class ProjectHome extends Component {

    state = {
        user: null,
        project: null,
        err: null,
        loading: true
    }

    componentDidMount(){
        let store = localStorage.getItem("cxpx");
        store = JSON.parse(store);
        if(store){
            this.setState({
                user: store.user
            })
        }

        //Get Project details
        Axios.get(`${config.api}/maturity-questions/by-project/${this.props.match.params.projectId}`).then(resp=>{

            if(resp.data.code === "NO_ACCESS"){
                this.setState({
                    err: resp.data.msg,
                    loading: false
                })
            } else {
                this.setState({
                    project: resp.data,
                    loading: false
                })
            }
        })
    }

    getCompletionPer(questions){
      const answeredQ = (_.filter(questions,(x)=>x.value)).length
      const total = questions.length;
      return ((answeredQ/total)*100).toFixed(0)
    }


    render() {
        const {user,project,err} = this.state;
        return (
          <>
            {
              this.state.loading ?
              <ClientHomeSketch />
              :
              <ProjectDetailsComp>
                <div>
                  <WelcomeUser>Welcome {user && user.name}!</WelcomeUser>
                  {project && (
                    <div className="welcome-subtext">
                      You have {project.questions.length} modules assigned to you!
                    </div>
                  )}
                  <hr className="devider" />
                </div>

                <div className="mxx">
                  {err ? (
                    <NoAccess />
                  ) : (
                    <>
                      <ModuleArea>
                        {project && project.questions.map((px, index) => (
                          <ModuleCard
                            key={index}
                            icon={px.icon}
                            name={px.questionType}
                            completion={0}
                            history={this.props.history}
                            projectId={this.props.match.params.projectId}
                            percentage={
                              // ((px.score/ (px.questions.length*5))*100).toFixed(0)
                              this.getCompletionPer(px.questions)
                            }
                          />
                        ))}
                      </ModuleArea>

                      {/* <ModuleArea>
                        {project && project.questions.map((px, index) => (
                          index >= 4 &&
                          <ModuleCard
                            key={index}
                            icon={px.icon}
                            name={px.questionType}
                            completion={0}
                            history={this.props.history}
                            projectId={this.props.match.params.projectId}
                            percentage={
                              this.getCompletionPer(px.questions)
                              //((px.score/ (px.questions.length*5))*100).toFixed(0)
                            }
                          />
                        ))}
                      </ModuleArea> */}
                    </>
                  )}
                </div>

                <div>
                  <hr className="devider" />
                  <ButtonArea>
                    {/* <NeoButton>
                      Invite Someone to Help in completing the task
                    </NeoButton> */}
                  </ButtonArea>
                </div>
              </ProjectDetailsComp>
            }
          </>
        );
    }
}

export default ProjectHome;