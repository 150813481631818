import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import './projects.scss';
import config from '../../../../config/config';
import SubsTile from '../../../../components/common/Tiles/SubsTile/SubsTile';
import SubsTileLoading from '../../../../components/common/Tiles/SubsTile/SubsTileLoading';
import AddNewSub from '../../../../components/common/Tiles/SubsTile/AddNewSub';
import CompanyHomeEmpty from '../../CompanyHome/CompanyHomeEmpty';
import { Breadcrumb, Input, Select, Skeleton } from 'antd';
import _ from 'underscore'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/actions'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CustomerAdminContext } from '../../CustomerAdminContext';

const LIMIT = 10;

const Projects = ({ actions }) => {
  const [projects, setProjects] = useState([]);
  const numberArray = [1, 2, 3, 4, 5, 6];
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(LIMIT);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [sort, setSort] = useState('projectName');
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('')

  const { rankingHierarchy } = useContext(CustomerAdminContext);



  function getProjects(sort, l, s, isAppend = true, searchTerm = '') {
    Axios.get(`${config.api}/projects?limit=${l}&skip=${s}&sort=${sort}&searchTerm=${searchTerm}`).then(r => {
      setLoading(false);
      setCount(r.data.count);
      if (isAppend) {
        const newP = projects.concat(r.data.data);
        setProjects(newP);
      } else {
        setProjects(r.data.data);
      }
      if (r.data.data.length < LIMIT) {
        setReachedEnd(true);
      }
    })
  }

  useEffect(() => {
    getProjects(sort, LIMIT, 0)
  }, []);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !reachedEnd) {
      const newSkip = skip + limit;
      const newLimit = skip + LIMIT;
      getProjects(sort, newSkip, newLimit, true);
      setSkip(newSkip);
      setLimit(newLimit);
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      getProjects(sort, LIMIT, 0, false, searchTerm);
      // Send Axios request here
    }, 1300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  function getModules(mods) {
    var mox = _.filter(mods, function (mod) {
      return mod.status && mod.selected
    })

    let x = (_.pluck(mox, 'name')).toString();
    x = x.replace("Ranking", "Scoring");
    return x;
  }

  if (loading) {
    return <div className="projects-page">
      <div style={{ width: 400 }}><Skeleton active /></div>
      <div className="projects-loading">
        {
          numberArray.map((x, i) => <SubsTileLoading key={i} />)
        }
      </div>
    </div>
  } else {

    if (count > 0) {
      return (
        <div className="projects-page">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to="/company-admin">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {rankingHierarchy[0].p}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>{rankingHierarchy[0].p}</h1>
          <div className='active-and-sort'>
            <div className='counts-active'>
              You have {count} <a href="#">Active</a> {rankingHierarchy[0].p}
            </div>
            <div className='sort-by'>
              <div className='search' style={{ marginRight: 10 }}>
                <label>Search</label>
                <Input placeholder="Search" autoComplete='off' onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
              <div className='sort-select'>
                <label>Sort By</label>
                <Select defaultValue={sort} style={{ width: 160 }} onChange={(e) => {
                  setLimit(LIMIT);
                  setSkip(0);
                  getProjects(e, LIMIT, 0, false);
                  setSort(e);
                  setReachedEnd(false);
                }}>
                  <Select.Option value='projectName'>Name</Select.Option>
                  <Select.Option value='createdAt'>Date Created</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <div className="projects" onScroll={handleScroll}>

            {!loading &&
              projects.map((x, i) => {
                return <div onClick={() => actions.setProject(x)} key={x._id}>
                  <SubsTile key={i}
                    name={x.projectName}
                    link={`/company-admin/project/${x._id}`}
                    modules={getModules(x.modules)}
                    topUser={[]}
                    plan={null}
                    createdAt={x.createdAt}
                  />
                </div>
              })
            }

            <AddNewSub type={rankingHierarchy[0].s} link="/company-admin/new-project" />

          </div>
        </div>
      );
    } else {
      return <CompanyHomeEmpty
        subText={`You don't have any ${rankingHierarchy[0].s} so far, please add a ${rankingHierarchy[0].s} to start with.`}
        rankingHierarchy={rankingHierarchy}
      />
    }
  }
}

Projects.propTypes = {
  actions: PropTypes.func,
  reducer: PropTypes.object
}


function mapStateToProps(state) {
  return {
    reducer: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);

