import React from 'react';
import './bc.scss';

const Heatmap = ({ heatmapChecked }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }} className="flx22">
            <div className='row1'>
                <div className='bg-flex the-right-border'>
                    {
                        heatmapChecked &&
                        <>
                            <div className='row'>
                                <div className='child yellow'></div>
                                <div className='child yellow'></div>
                            </div>
                            <div className='row'>
                                <div className='child green'></div>
                                <div className='child yellow'></div>
                            </div>
                        </>
                    }
                </div>

                <div className='bg-flex the-left-border'>
                    {
                        heatmapChecked && <>
                            <div className='row'>
                                <div className='child red'></div>
                                <div className='child red'></div>
                            </div>
                            <div className='row'>
                                <div className='child yellow'></div>
                                <div className='child red'></div>
                            </div>
                        </>
                    }
                </div>
            </div>

            <div className='row2'>
                <div className='bg-flex'>
                    {heatmapChecked &&
                        <>
                            <div className='row'>
                                <div className='child green'></div>
                                <div className='child green'></div>
                            </div>
                            <div className='row'>
                                <div className='child green'></div>
                                <div className='child green'></div>
                            </div>
                        </>
                    }
                </div>

                <div className='bg-flex the-other-border'>
                    {
                        heatmapChecked &&
                        <>
                            <div className='row'>
                                <div className='child yellow'></div>
                                <div className='child yellow'></div>
                            </div>
                            <div className='row'>
                                <div className='child green'></div>
                                <div className='child yellow'></div>
                            </div>
                        </>
                    }
                </div>
            </div>

        </div>
    );
};

export default Heatmap;