/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import '../programs.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Input, Select } from 'antd';

const { Option } = Select;

const Form = (props) => (
  <div className="form-area">
    <div className="fl-item">
      <div className="inp-group">
        <label>Name</label>
        <Input
          className="neo"
          tabIndex={1}
          placeholder="Program Name"
          value={props.program.programName}
          onChange={(e) => props.changeForm('programName', e.target.value)}
        />
      </div>
      <div className="inp-group">
        <label>Descripion</label>
        <TextArea
          className="neo"
          tabIndex={3}
          placeholder="Please enter program description"
          value={props.program.description}
          onChange={(e) => props.changeForm('description', e.target.value)}
        />
      </div>
    </div>

    <div className="fl-item">

      <div className="inp-group">
        <label>Type</label>

        <Input
          className="neo"
          tabIndex={2}
          placeholder="Add Type"
          value={props.program.type}
          onChange={(e) => props.changeForm('type', e.target.value)}
        />
      </div>
      <div className="inp-group">
        <label>Objective</label>
        <TextArea
          className="neo"
          tabIndex={4}
          placeholder="Please enter program objective"
          value={props.program.objective}
          onChange={(e) => props.changeForm('objective', e.target.value)}
        />
      </div>
    </div>

    <div className="fl-item">

      <div className="inp-group" style={{ minHeight: '85px' }} />
      <div className="inp-group" style={{ marginBottom: '10px' }}>
        <label>Behavior/Data</label>
        <Input
          className="neo"
          placeholder="URL:"
          tabIndex={5}
        />
      </div>
      <div className="inp-group">
        <label>KPI</label>
        <Select
          tabIndex={6}
          className="neo-select"
          value={props.program.kpi}
          placeholder="Please select KPI"
          value={props.program.kpi}
          onChange={(e) => props.changeForm('kpi', e)}
        >
          <Option key={1} value="Technology">Technology</Option>
          <Option key={2} value="Retail">Retail</Option>
        </Select>
      </div>

    </div>
  </div>
);

export default Form;
