import React, { useState } from 'react';
import { Menu } from 'antd';
import './neo-collaspe.scss';
import PropTypes from 'prop-types';

const { SubMenu } = Menu;

const NeoCollaspe = (props) => {
  const [openKey, setOpenKey] = useState(['mx_0']);

  function onOpenChange(openKeys) {
    const rootSubmenuKeys = [];
    props.menu.forEach((element, index) => {
      rootSubmenuKeys.push(`mx_${index}`);
    });
    const latestOpenKey = openKeys.find((key) => openKey.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKey(openKeys);
    } else {
      setOpenKey(
        latestOpenKey ? [latestOpenKey] : [],
      );
    }
  }

  return (
    <div className="neo-collaspe" id="style-2">
      <Menu
        mode="inline"
        openKeys={openKey}
        onOpenChange={onOpenChange}
        style={{ width: 256 }}
      >
        {
            props.menu.map((mx, ix) => (
              <SubMenu key={`mx_${ix}`} title={mx[props.menuKey]} onTitleClick={() => props.menuClick(mx, ix)}>
                {
                        mx[props.subMenuObj].map((sx, six) => (
                          <Menu.Item
                            onClick={() => props.subMenuClick(sx, six)}
                            key={`sx_${six}_${ix}`}
                          >
                            {sx[props.subMenuKey]}
                          </Menu.Item>
                        ))
                    }
                {
                        props.addWhenEmpty
                        && (
                        <Menu.Item key={`add-empty_${ix}`}>
                          <span style={{ color: '#ff9e00' }} onClick={props.onAdd}>
                            {props.addButton}
                          </span>
                        </Menu.Item>
                        )
                    }
              </SubMenu>
            ))
        }
      </Menu>
    </div>
  );
};

NeoCollaspe.propTypes = {
  match: PropTypes.shape({
    params: {
      programId: PropTypes.string.isRequired,
    },
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
NeoCollaspe.defaultProps = {
  match: {},
  history: {},
};

export default NeoCollaspe;
