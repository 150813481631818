import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumb, Button, message, Modal } from 'antd';
import '../../SuperAdmin/Subscriptions/subs.scss';
import { Link, useParams, useHistory } from 'react-router-dom';
import Axios from 'axios';
import config from '../../../config/config';
import NumberTile from '../../../components/common/Tiles/Number/NumberTile';
import NumberTileLoading from '../../../components/common/Tiles/Number/NumberTileLoading';
import ProjectModule from '../../../components/common/Card/ProjectModule';
import { FlxHrSB } from '../../../components/elements/elements';
import _ from 'underscore';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './project-modules.scss';
import { CustomerAdminContext } from '../CustomerAdminContext';
import rodi from './rodi.svg';
import innovation from './innovation.svg';
import traction from './tractiongap_icon.png';

const { confirm } = Modal;

const mods = {
    "assesment": "Assess",
    "ir": "Ideate",
    "ip": "Prioritize",
}


const NumArray = [1, 2, 3, 4];

function getUrl(id, module) {
    switch (module) {
        case 'assesment': return `/company-admin/maturity-assesments/${id}`;
        case 'ip': return `/company-admin/initiative-prioritisation/${id}`;
        case 'ir': return `/company-admin/initiative-ranking/${id}`;
    }
}

function filteredModules(modules) {
    return _.filter(modules, x => x.selected)
}

const ProjectDetails = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const history = useHistory();
    const { rankingHierarchy } = useContext(CustomerAdminContext);


    useEffect(() => {

        Axios.get(`${config.api}/projects/${projectId}`).then((resp) => {
            setProject(resp.data);
            setLoading(false);
        });
    }, []);

    function showConfirm() {
        confirm({
            title: `Are you sure you want to delete this ${rankingHierarchy[0].s}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'You will not be able to recover this later',
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk() {
                setDeleting(true);
                Axios.delete(`${config.api}/projects/${projectId}`).then(() => {
                    message.success(`${rankingHierarchy[0].s} deleted successfully`);
                    setDeleting(true);
                    history.push(`/company-admin/projects`);
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    return (
        <div className="subscription-page project-modules">
            <FlxHrSB>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to="/company-admin">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/company-admin/projects">{rankingHierarchy[0].s}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{!loading && project.projectName}</Breadcrumb.Item>
                </Breadcrumb>
                {
                    projectId &&
                    <div>
                        <Link to={`/company-admin/edit-project/${projectId}`}>
                            <Button type="default">
                                <EditOutlined /> Edit
                            </Button>
                        </Link>

                        <Button type="default" onClick={showConfirm} disabled={deleting} style={{ marginLeft: 10 }}>
                            <DeleteOutlined /> {
                                deleting ? 'Deleting...' : 'Delete'
                            }
                        </Button>
                    </div>
                }
            </FlxHrSB>
            <h1>{!loading && project.projectName}</h1>

            <div className="numbers">
                {!loading &&
                    <>
                        <NumberTile key={1} number="Industry" title={project.industry} />
                        <NumberTile key={2} number="Location" title={project.location} />
                    </>
                }
                {
                    loading &&
                    NumArray.map((x, i) => {
                        return <NumberTileLoading key={i} />
                    })
                }
            </div>

            <div className="modules" style={{ display: 'flex', flexFlow: 'wrap' }}>

                <>
                    <ProjectModule
                        comingSoon={false}
                        key={22}
                        linkTo={"/company-admin/gen-p"}
                        src={rodi}
                        name={"Generate"}
                        buttonText="View Result"
                        project={project}
                        subText={"Helps organization to create a roadmap of initiative from a set of ideas."}
                    />
                </>
                {
                    !loading && project !== null &&
                    <>
                        {
                            filteredModules(project.modules).map((x, i) => (
                                <ProjectModule
                                    key={i}
                                    linkTo={getUrl(project._id, x.id)}
                                    src={x.img}
                                    name={mods[x.id]}
                                    buttonText="View Result"
                                    project={project}
                                    subText={x.description}
                                />

                            ))
                        }
                    </>
                }
                <>

                    <ProjectModule
                        comingSoon={true}
                        key={22}
                        linkTo={"/company-admin/innovation-mapper"}
                        src={innovation}
                        name={"Innovation Mapping"}
                        buttonText="View Result"
                        project={project}
                        subText={"Helps you design engagement. Models for Programs, Components and capabilities lifecycle to personas and segmentations"}
                    />
                    <ProjectModule
                        comingSoon={true}
                        key={22}
                        linkTo={"/company-admin/traction-gap"}
                        src={traction}
                        name={"Return on Prioritization Intelligence"}
                        buttonText="View Result"
                        project={project}
                        subText={"Invaluable both as a navigational instrument to chart courses through the unpredictable currents of disruptive innovation"}
                    />
                </>

            </div>

        </div>
    );
};

export default ProjectDetails;