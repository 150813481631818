import React from 'react';
import PropTypes from 'prop-types';

const InitiativeRow = ({index, row}) => {
    return (
        <tr key={index} className={`${row.type}_row`}>

            <td>{row.program}</td>
            <td>{row.opportunity}</td>
            <td style={{ fontWeight: 'bold'}}>{row.initiative}</td> 
            {/* width: 100, */}
            <td>{row.feature}</td>
            <td className="inp-per">{row.dependency}</td>
            <td className="inp-per">{row.competitive}</td>
            <td className="inp-per">{row.innovation}</td>
            <td className="inp-per">{row.implementation}</td>
            <td className="inp-per">{row.integration}</td>
            <td className="inp-per">{row.cost}</td>
            <td className="inp-per">{row.audienceSize}</td>
            <td className="inp-per">{row.duration}</td>
            <td className="inp-per">{row.strategic}</td>
            <td className="inp-per">{row.reusability}</td>
            <td className="inp-per">{row.maintainance}</td>

            <td className="score">{
                row.programRank
            }</td>
            <td className="score">{row.opportunityRank}</td>
            <td className="score">{
                row.initiativeRank
            }</td>
            <td className="score">{row.featureRank}</td>
        </tr>
    );
};

InitiativeRow.propTypes = {
    index: PropTypes.number,
    row: PropTypes.object
}

export default InitiativeRow;