import React, { useEffect, useState } from 'react';
import { ProjectDetailsComp, ResultArea, MaturityArea } from '../../../../components/elements/elements';
import MaturityResultCard from '../../../../components/common/Card/MaturityResultCard';
import Axios from 'axios';
import config from '../../../../config/config';
import _ from 'underscore';
import MaturityDetailsModal from '../../../CompanyAdmin/MaturityResult/MaturityDetailsModal';
import { Breadcrumb, Button } from 'antd';
import MaturityResultSketch from '../../../CompanyAdmin/MaturityResult/MaturityResultSketch';
import ProductLaunchModal from '../../../CompanyAdmin/MaturityResult/ProductLaunchModal';
import { Link, useParams } from 'react-router-dom';
import '../../../CompanyAdmin/MaturityResult/maturity-results.scss';
import MaturityFinalTop from '../../../../components/common/Card/MaturityFinalTop';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/actions'
import PropTypes from 'prop-types';
import { shouldShow, countSections } from '../../../../utils/assessmentFunction';

const MaturityResult = () => {


    const { assesmentId } = useParams();//"619a6f3c39715818cd6aa943";// 
    const [questions, setQuestions] = useState(null);
    const [modalDetails, setModalDetails] = useState(null)
    const [modalVisibility, setModalVisibility] = useState(false);
    // const [noOfSets, setNoOfSets] = useState(0);
    const [loading, setLoading] = useState(true);
    const [plModal, setPLModal] = useState(null);
    const [assesment, setAssesment] = useState(null);
    const [sectionCount, setSectionCounnt] = useState(0)


    useEffect(() => {
        //Get Project details

        Axios.get(`${config.api}/maturity-questions/all/${assesmentId}`).then(resp => {
            setQuestions(resp.data.data)
            // setNoOfSets(resp.data.noOfSets);
            setSectionCounnt(countSections(resp.data.data));
            getAssesmentDetails();
        })

    }, []);

    function getAssesmentDetails() {
        Axios.get(`${config.api}/maturity-questions/response/${assesmentId}`).then(resp => {
            setAssesment(resp.data);
            setLoading(false);
        });
    }



    return (
        <section className="projects-page maturity-results">

            {
                loading ?
                    <MaturityResultSketch />
                    :
                    <>
                        <ProjectDetailsComp id="comp-xhp" style={{ textAlign: 'left', padding: 0 }}>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/company-admin">Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to="/company-portal/assesments">Assessments</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {assesment.projectName}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {assesment.name}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h1>{assesment.name} Result</h1>
                                <div style={{ display: 'flex' }}>
                                    <Link to={`/company-portal/assesment/${assesmentId}/retake`}>
                                        <Button type='default'>Retake Assessment</Button>
                                    </Link>
                                    <Button style={{ marginLeft: 10 }} onClick={() => {
                                        window.print()
                                    }} type="default">Download PDF</Button>
                                </div>

                            </div>

                            <MaturityFinalTop
                                maturityPer={
                                    (_.reduce(questions, (memo, num) => (memo + num.percantage), 0) / sectionCount).toFixed(0)
                                }
                                status="UN"
                                no_of_questions={
                                    _.reduce(questions, (memo, num) => {
                                        const questionCount = _.filter(num.questions, x => x.selected)
                                        return memo + questionCount.length
                                    }, 0)
                                }
                                no_of_sections={sectionCount}
                                index={1}
                                openProductLaunch={() => setPLModal({
                                    percantage: (_.reduce(questions, (memo, num) => (memo + num.percantage), 0) / sectionCount).toFixed(0),
                                    showModal: true,
                                    thresold: 100
                                })}
                                assesment={assesment}
                            />
                            <ResultArea id="resultArea">
                                <MaturityArea className="maa">

                                    {
                                        questions &&
                                        questions.map((qs, index) => (
                                            shouldShow(qs) &&
                                            <MaturityResultCard
                                                maturityPer={qs.percantage.toFixed(0)}
                                                name={qs.questionType}
                                                thresholdPer={qs.thresold}
                                                index={index + 1}
                                                key={index}
                                                noS={sectionCount}
                                                openModal={() => {
                                                    setModalDetails(qs)
                                                    setModalVisibility(true)
                                                }}
                                            />
                                        ))
                                    }

                                </MaturityArea>
                            </ResultArea>

                            {modalDetails &&
                                <MaturityDetailsModal
                                    visible={modalVisibility}
                                    modalDetails={modalDetails}
                                    onOk={() => { }}
                                    noOfSets={sectionCount}
                                    onCancel={() => { setModalVisibility(false) }}
                                />}
                            {
                                plModal &&
                                <ProductLaunchModal
                                    visible={plModal.showModal}
                                    modalDetails={plModal}
                                    onOk={() => { }}
                                    onCancel={() => setPLModal({ showModal: false, percantage: 0, thresold: 0 })}
                                />
                            }
                        </ProjectDetailsComp>
                    </>
            }
            <div id="printArea">

            </div>

        </section>
    );
};


MaturityResult.propTypes = {
    actions: PropTypes.func,
    reducer: PropTypes.object
}


function mapStateToProps(state) {
    return {
        reducer: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaturityResult);

