import React from 'react';
import styled from 'styled-components';
import { SVGImage } from '../../elements/elements';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const MC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: 20px;
  background-color: var(--background);
  color: var(--text-color);
  margin-right: 20px;
  width: 300px;
  height: 271px;
  padding: 24px 12px 9px 14px;
  box-shadow: var(--shadow);
  &:hover{
    background-color: var(--hover-background);
    box-shadow: var(--shadow);
    cursor: pointer;
  }
`;

const HeadText = styled.h5`
    text-align: left;
    font-size:16px;
    font-weight: bold;
    font-family: Lato;
`

const HeadPart = styled.div`
    display: flex;
    width: 100%;
    padding: 0 20px 0 20px;
    align-items: center;
    justify-content: space-between;
`

const ProjectModule = ({ linkTo, project, name, src, subText, comingSoon = false }) => {
  const history = useHistory();

  return (
    <MC onClick={() => {
      history.push({
        pathname: linkTo,
        state: project
      })
    }}>
      <HeadPart>
        <HeadText>{name}</HeadText>
        <SVGImage
          src={src}
        />
      </HeadPart>
      <div>

        <div style={{ padding: '0 20px 20px 20px' }}>{subText}</div>
      </div>
      {
        comingSoon &&
        <div className='coming-soon'>
          Coming soon...
        </div>
      }
    </MC>
  );
};

ProjectModule.propTypes = {
  linkTo: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool
}

export default ProjectModule;