import React, {useState} from 'react';
import NumberTile from '../../../components/common/Tiles/Number/NumberTile';
import AddNewSub from '../../../components/common/Tiles/SubsTile/AddNewSub';
import SubsTile from '../../../components/common/Tiles/SubsTile/SubsTile';
import  Axios from 'axios';
import './subs.scss';
import config from '../../../config/config';
import _ from 'underscore';
import SubsTileLoading from '../../../components/common/Tiles/SubsTile/SubsTileLoading';
import NumberTileLoading from '../../../components/common/Tiles/Number/NumberTileLoading';



const Subscriptions = () => {
  const [kpi, setKpi] = useState([
    {name: "All Subscription(s)", count: null,active: true},
    {name: "Free", count: null,active: false},
    // {name: "Past Subscription", count: null,active: false},
    // {name: "In Pipeline", count: null,active: false}
  ]);

  const numberArray = [ 1,2,3,4,5,6 ];
  const NumArray = [1,2,3,4];

  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);


  function getOrgs(){

        Axios.get(`${config.api}/organizations`).then(r=>{
            setOrgs(r.data);
            let allsubs = r.data.length;
            let inprogress = (_.filter(r.data, {'plan': 'FREE'})).length;
            // let pastsubs = _.filter(r.data, {'status': false}).length;
            // let inpipeline = allsubs - inprogress - pastsubs;
            let kpiX = _.clone(kpi);
            kpiX[0].count = allsubs;
            kpiX[1].count = inprogress;
            // kpiX[2].count = pastsubs;
            // kpiX[3].count = inpipeline;
            setKpi(kpiX);
            setLoading(false);
        })
  }

  useState(()=>{  
    setTimeout(()=>{ 
        getOrgs()
    },0)
   },[]);

    return (
      <div className="subscription-page">
        <h1>Subscriptions</h1>
        <span className="small-txt">
          You have {kpi[0].count} <a href="#">Active</a> Subscription(s)
        </span>
        <div className="numbers">
          { !loading &&
            kpi.map((x,i)=>{
              return <NumberTile key={i} number={x.count} title={x.name} />
            })
          }
          {
            loading &&
            NumArray.map((x,i)=>{  
              return <NumberTileLoading key={i} />
            })
          }
        </div>

        <div className="subscriptions">
          
          { !loading &&
            orgs.map((x,i)=>{
              return <SubsTile key={i} 
                name={x.name} 
                modules={(_.pluck((_.filter(x.modules, z => z.status)), 'name')).toString()}
                topUser={[]}
                link={`/super-admin/edit-org/${x._id}`}
                data={x}
                plan={x.plan} />
            })
          }

          {
            loading &&
            numberArray.map((x,i)=>{
              return <SubsTileLoading key={i} />
            })
          }


          <AddNewSub type="subscription" link="/super-admin/onboard-org"  />

        </div>
      </div>
    );
}

export default Subscriptions;