import React, { Component } from 'react';
import { Table, Popconfirm,message } from 'antd';
import styled from 'styled-components';
import {Logo} from '../../../components/elements/elements';
import Axios from 'axios';
import config from '../../../config/config';


class Organizations extends Component {
    state = {
        orgs: null,
        columns:[
            {
              title: 'Organization',
              dataIndex: 'name',
              key: 'name',
              render: (text,record) => {
                  return (
                      <div style={{display:'flex',alignItems:'center'}}>
                        <Logo>
                            <img style={{height:'20px'}} src={record.logo} /> 
                        </Logo>
                        <div style={{float:'left',marginLeft:'10px'}}>{record.name}</div>
                      </div>
                  )
              }
            },
            {
                title: 'Industry',
                dataIndex: 'industry',
            },
            {
              title: 'Location',
              dataIndex: 'location',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (x)=> x ? "Active" : "In Active"
            },
            // {
            //     title: "Actions",
            //     render:(record)=> {
            //         return (
            //             <Popconfirm
            //             title="Are you sure delete this organization?"
            //             onConfirm={()=>this.confirm(record)}
            //             onCancel={this.cancel.bind(this)}
            //             okText="Yes"
            //             cancelText="No"
            //         >
            //             <a href="#">Delete</a>
            //         </Popconfirm>
            //         )
            //     }
            // } 
          ]
    }

    confirm(e) {
        const msg = message.loading(`Deleting ${e.name}...`,0);
        Axios.delete(`${config.api}/organizations/${e._id}`).then(resp=>{
            msg()
            message.success('Organization deleted successfully');
            this.props.refreshOrgs()
        })
      }
      
    cancel(e) {
        console.log(e);
    }
   
    render() {
        return (
            <div className="org-table">
                {
                    <Table 
                        dataSource={this.props.orgs} 
                        columns={this.state.columns} 
                        onRow={(record, rowIndex) => ({
                            onClick: () => {
                                this.props.history.push(`/super-admin/edit-org/${record._id}`);
                            },
                        })}
                    />
                }
            </div>
        );
    }
}

export default Organizations;


  