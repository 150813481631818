import React, {useState} from 'react';
import { Menu } from 'antd';
import './super-admin-sub-nav.scss';
import { Link } from 'react-router-dom';


const CustomerPortalNav = () => {

    const [current,setCurrent] = useState('home');

    function handleClick(e){
        setCurrent(e.key);
        // if(e.key === 'help'){
        //     history.push('/super-admin/help')
        // } else if(e.key === 'subscription'){
        //     history.push('/super-admin')
        // }
    }

    return (
      <div className="sasn">
        <Menu selectedKeys={[current]} mode="horizontal" onClick={handleClick}>
          <Menu.Item key="home">
            <Link to="/company-portal">Home</Link>
          </Menu.Item>
          <Menu.Item key="launchpad">
            <Link to="/company-portal/assesments">Assessments</Link>
          </Menu.Item>
          <Menu.Item key="help">
            <Link to="/company-portal/help">Help</Link>
          </Menu.Item>
        </Menu>
      </div>
    );
}

export default CustomerPortalNav;