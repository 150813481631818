import React from 'react';
import Icon from '@ant-design/icons';

const JIRA = () => (
    <svg width="15" height="15" viewBox="0 0 749 748">
        <defs>
            <clipPath id="clip-Instagram_Story">
                <rect width="749" height="748" />
            </clipPath>
        </defs>
        <g id="Instagram_Story" data-name="Instagram Story" clipPath="url(#clip-Instagram_Story)">
            <rect width="749" height="748" fill="#fff" />
            <g id="jira-1">
                <path id="Path_1" data-name="Path 1" d="M481.244,0H121.707a162.3,162.3,0,0,0,162.3,162.3h66.231v63.947a162.3,162.3,0,0,0,162.2,162.2V31.19A31.19,31.19,0,0,0,481.244,0Z" transform="translate(234.192)" opacity="0.85" />
                <path id="Path_2" data-name="Path 2" d="M420.406,61.262H60.872a162.3,162.3,0,0,0,162.2,162.2H289.3v64.152A162.3,162.3,0,0,0,451.6,449.7V92.458a31.194,31.194,0,0,0-31.193-31.193Z" transform="translate(117.132 117.882)" opacity="0.85" />
                <path id="Path_3" data-name="Path 3" d="M359.537,122.489H0a162.3,162.3,0,0,0,162.3,162.3h66.438v63.944a162.3,162.3,0,0,0,161.99,162.2V153.682a31.194,31.194,0,0,0-31.193-31.193Z" transform="translate(0 235.697)" fill="rgba(0,0,0,0.85)" />
            </g>
        </g>
    </svg>

)

const JiraIcon = (props) => {
    return (
        <Icon component={JIRA} {...props} />
    );
};

export default JiraIcon;