import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/common/Nav/Nav';
import CustomerAdminSubNav from '../../components/common/SubNav/CustomerAdminSubNav';
import Projects from './Project/Projects/Projects';
// import InitiativePrioritizationHome from './InitiativePrioritization/InitiativePrioritizationHome';
import IpBubble from './InitiativePrioritization/IpBubble';
import AssesmentList from './MaturityResult/AssesmentList/AssesmentList';
import MaturityResult from './MaturityResult/MaturityResult';
import NewAssesment from './MaturityResult/NewAssesment/NewAssesment';
import AddModule from './PrioritizeRanking/Dashboard/AddModule';
import Dashboard from './PrioritizeRanking/Dashboard/Dashboard';
import AddProgram from './PrioritizeRanking/Programs/AddProgram/AddProgram';
// import Programs from './PrioritizeRanking/Programs/Programs';
import Roadmap from './PrioritizeRanking/Roadmap/Roadmap';
import CreateProject from './Project/CreateProject/CreateProject';
import ProjectDetails from './ProjectModules/ProjectDetails';
import Questions from './Questions/Questions';
import PropTypes from 'prop-types';
import CompanyHome from './Home/CompanyHome';
import CompanyUsers from './CompanyUsers/CompanyUsers';
import AddCompanyUser from './CompanyUsers/AddCompanyUser';
// import LaunchPad from './PrioritizeRanking/LaunchPad/LaunchPad';
import PRHome from './PrioritizeRanking/LaunchPad/PRHome';
import IPHome from './InitiativePrioritization/IPHome';
import HelpPage from '../../pages/Help/HelpPage';
import TabularReport from './PrioritizeRanking/TabularReport/TabularReport';
import { CustomerAdminContext } from './CustomerAdminContext';
import Axios from 'axios';
import appConfig from '../../config/config';
import Cleanup from '../../utils/Cleanup/Cleanup';
import Unauthorize from '../../pages/Unauthorize/Unauthorize';
import SnapshotDashboard from './Dashboard/Snapshot/SnapshotDashboard.jsx';
import RoDI from './RoDI/RoDI.jsx';
import InnovationMapper from './innovationMapper/innovationMapper.jsx';
import TractionGap from './TractionGap/TractionGap';
import MaturityAssesment from '../ClientApp/MaturityAssesment/MaturityAssesment';
import GenerativePrioritisation from './GenerativePrioritisation/GenerativePrioritisation.jsx';
import GenInitiatives from './GenerativePrioritisation/GenInitiatives.jsx';


const CompanyAdminRoutes = ({ url, history }) => {

    const [config, setConfig] = useState(null);
    useEffect(() => {
        getConfig();
    }, []);

    async function getConfig() {
        const { data } = await Axios.post(`${appConfig.api}/organizations/config`);
        setConfig(data);
    }

    if (config) {
        return (
            <CustomerAdminContext.Provider value={config}>
                <Nav history={history} />
                <CustomerAdminSubNav />
                <div className="customer-admin-container">
                    <Route path={`${url}/`} component={CompanyHome} exact />
                    {/* <Route path={`${url}/`} component={GenInitiatives} exact /> */}
                    <Route path={`${url}/dashboard`} component={SnapshotDashboard} />
                    <Route path={`${url}/projects`} component={Projects} />
                    <Route path={`${url}/edit-project/:projectId`} component={CreateProject} />


                    <Route path={`${url}/users`} component={CompanyUsers} />
                    <Route path={`${url}/add-user`} component={AddCompanyUser} />
                    <Route path={`${url}/edit-user/:userId`} component={AddCompanyUser} />
                    <Route path={`${url}/new-project`} component={CreateProject} />
                    <Route path={`${url}/project/:projectId`} component={ProjectDetails} />
                    <Route path={`${url}/maturity-assesments/:projectId`} component={AssesmentList} />
                    <Route path={`${url}/edit-assesment/:assesmentId`} component={NewAssesment} />


                    <Route path={`${url}/questions`} component={Questions} />
                    <Route path={`${url}/maturity-result/:assesmentId`} component={MaturityResult} />
                    <Route path={`${url}/add-maturity-assesment/:projectId`} component={NewAssesment} />
                    <Route path={`${url}/initiative-ranking/:projectId`} component={PRHome} />
                    <Route path={`${url}/initiative-prioritisation/:projectId`} component={IPHome} />

                    <Route path={`${url}/add-program/:projectId`} component={AddProgram} />
                    <Route path={`${url}/dashboard/:programId`} component={Dashboard} />
                    <Route path={`${url}/bubble-graph/:programId`} component={IpBubble} />
                    <Route path={`${url}/roadmap/:programId`} component={Roadmap} />
                    <Route path={`${url}/add-module/:programId`} component={AddModule} />
                    <Route path={`${url}/help`} component={HelpPage} />
                    <Route path={`${url}/score-ranking-summary/:projectId`} component={TabularReport} />

                    <Route path={`${url}/cleanup`} component={Cleanup} />
                    <Route path={`${url}/unauthorized`} component={Unauthorize} />

                    <Route path={`${url}/rodi`} component={RoDI} />
                    <Route path={`${url}/innovation-mapper`} component={InnovationMapper} />
                    <Route path={`${url}/traction-gap`} component={TractionGap} />

                    <Route path={`${url}/assessment/:assesmentId/:retake?`} component={MaturityAssesment} />

                    {/* Generative Priritisation */}

                    <Route path={`${url}/gen-p`} component={GenerativePrioritisation} />
                    <Route path={`${url}/gen-initiatives/:problem/:timeline/:initiative/:startDate`} component={GenInitiatives} />


                </div>
            </CustomerAdminContext.Provider>
        );
    } else {
        return null
    }

};

CompanyAdminRoutes.propTypes = {
    url: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
}

export default CompanyAdminRoutes;