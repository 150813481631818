import React, {useContext} from 'react';
import './tile.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlxHrSB } from '../../../../../components/elements/elements';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import moment from 'moment';
import {CustomerAdminContext} from '../../../CustomerAdminContext';

import {  Modal } from 'antd';

const { confirm } = Modal;


function featureRank(feature, masterScores) {
    if (feature.responses === undefined) {
      return 0;
    }
    const resp = Object.keys(feature.responses[0]);
    let sum = 0;
    for (let i = 0; i < resp.length; i += 1) {
      sum+= (parseFloat(masterScores[resp[i]]) / 100)* parseFloat(feature.responses[0][resp[i]]);
    }
    return parseFloat(sum / resp.length).toFixed(1);
  }

const FeatureTile = ({ feature, onClick, configs, editFeature, deleteFeature }) => {
  const {rankingHierarchy} = useContext(CustomerAdminContext);

    function showConfirm() {
        confirm({
          title: `Are you sure you want to delete this ${rankingHierarchy[4].s.toLowerCase()}?`,
          icon: <ExclamationCircleOutlined />,
          content: `You will not be able to recover this ${rankingHierarchy[4].s.toLowerCase()}`,
          okText: 'Yes',
          cancelText: 'No',
          centered: true,
          onOk() {
            deleteFeature();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }


    return (
        <div onClick={onClick} className="tile" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <FlxHrSB>
                  <span className="title">{feature.feature}</span>
                  <div className="action-icons" style={{marginRight: 10}}>
                      <EditOutlined style={{marginRight: 10}} onClick={(e)=>{
                        editFeature();
                        e.stopPropagation();
                      }} />

                     <DeleteOutlined onClick={(e)=>{
                          showConfirm();
                          e.stopPropagation();
                      }} />
                  </div>
                </FlxHrSB>
                <div style={{height:20}}></div>
                <div className="">
                    <span style={{fontWeight: 'bold', fontSize: 18}}>{
                        (parseFloat(featureRank(feature,configs))).toFixed(1)
                    }</span>
                    <span> {rankingHierarchy[4].s} Rank</span>
                </div>
            </div>

            <div style={{marginBottom: 15, display: 'flex', justifyContent: 'space-between'}}>
                <span style={{color: 'var(--text-color'}}>
                    Created - {moment(feature.createdAt).format('MMM DD yy')}
                </span>
                <span style={{color: 'var(--primary-color', marginRight: 10}}>Active</span>
            </div>
        </div>
    );
};

FeatureTile.propTypes ={
    feature: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    configs: PropTypes.object.isRequired,
    editFeature: PropTypes.func.isRequired,
    deleteFeature: PropTypes.func.isRequired
}

export default FeatureTile;