function calculateKPI(masterScores,opportunity,initiativeIndex,featureIndex) {
    function featureRank(feature) {
        if (feature.responses === undefined) {
          return 0;
        }
        const resp = Object.keys(feature.responses[0]);
        let sum = 0;
        for (let i = 0; i < resp.length; i += 1) {
          sum
            += (parseFloat(masterScores[resp[i]]) / 100)
            * parseFloat(feature.responses[0][resp[i]]);
        }
        return parseFloat(sum / resp.length).toFixed(1);
      }
    
      function moduleRank(module) {
        if (module.features === undefined || module.features.length === 0) {
          return 0;
        }
        let sum = 0;
        for (let i = 0; i < module.features.length; i += 1) {
          const fa = featureRank(module.features[i]);
          const isNumber = (value) => !Number.isNaN(Number(value));
          sum += !isNumber(fa) ? 0 : parseFloat(fa);
        }
        return (sum / module.features.length).toFixed(1);
      }
    
      function opportunityRank(opportunity) {
        if (opportunity.modules === undefined || opportunity.modules.length === 0) {
          return 0;
        }
        let sum = 0;
        for (let i = 0; i < opportunity.modules.length; i += 1) {
          const moduleR = moduleRank(opportunity.modules[i]);
          sum += parseFloat(moduleR);
        }
        return (sum / opportunity.modules.length).toFixed(1);
      }
    
      /* Todo: Need to work on aggregate result set */
      function calculateKPI(opportunity,initiativeIndex,featureIndex) {
        // get feature rank
        const feature = opportunity.modules[initiativeIndex].features[featureIndex];
    
        return {
          featureRank: featureRank(feature),
          moduleRank: moduleRank(opportunity.modules[initiativeIndex]),
          opportunityRank: opportunityRank(opportunity),
        };
      }

    return calculateKPI(opportunity,initiativeIndex,featureIndex);
}

export default calculateKPI;