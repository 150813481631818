import { Skeleton } from 'antd';
import React from 'react';
import './number-tile.scss';


const NumberTileLoading = () => {
    return <div className="number-tile">
        <Skeleton paragraph={{ rows: 1 }} active={true} size={'small'} />
    </div>
}

export default NumberTileLoading;

