import React, { useEffect, useState } from 'react';
import { message, Breadcrumb, Row, Col, Input, Button, Form, Select, Checkbox } from 'antd';
import Axios from 'axios';
import config from '../../../config/config';
import './org.scss';
// import OnBoardForm from './OnBoardForm';
import _ from 'underscore';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonArea } from '../../../components/elements/elements';
import ModuleCard from '../../../components/common/ModuleCard/ModuleCard';
import { DeleteOutlined } from '@ant-design/icons';

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not validate email!',
        number: '${label} is not a validate number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
}

const OnboardOrg = () => {
    const [modules, setModules] = useState([]);
    const history = useHistory();
    const { orgId } = useParams();
    const [subscription, setSubscription] = useState(null);
    const [form] = Form.useForm();
    const [users, setUsers] = useState([{ name: '', email: '', status: 'ADDED', disabled: false }]);
    const [randomNumber, setRandomNumber] = useState(Math.floor(Math.random() * 100000));
    const [disabled, setDisabled] = useState(false);
    const customerAdmin = true;
    const [welcomeEmail, setWelcomeEmail] = useState(true)

    const onFinish = values => {
        setDisabled(true);
        // const msg = message.loading("Saving the organization...");

        let data = null;

        if (orgId) {
            data = {
                _id: subscription._id,
                "name": values.org.name,
                "domainName": values.org.domainName,
                "description": values.org.description,
                "status": values.org.status,
                "location": values.org.location,
                "logo": values.org.logo,
                "industry": values.org.industry,
                "companyAdmin": users,
                config: {
                    jiraUsername: values.org.jiraUsername,
                    jiraApiToken: values.org.jiraApiToken,
                    jiraBaseurl: values.org.jiraBaseurl,
                },
                modules: subscription.modules,
                databaseURL: subscription.databaseURL
            }
        } else {
            data = {
                "name": values.org.name,
                "domainName": values.org.domainName,
                "description": values.org.description,
                "databaseURL": values.org.name.split(" ").join(),
                "status": values.org.status,
                "location": values.org.location,
                "logo": values.org.logo,
                "industry": values.org.industry,
                "companyAdmin": users,
                config: {
                    jiraUsername: values.org.jiraUsername,
                    jiraApiToken: values.org.jiraApiToken,
                    jiraBaseurl: values.org.jiraBaseurl,
                },
                modules: modules,
                welcomeEmail: welcomeEmail
            }
        }

        Axios({
            method: orgId ? 'PUT' : 'POST',
            url: orgId ? `${config.api}/organizations/${orgId}` : `${config.api}/organizations`,
            data: data
        }).then(() => {
            // msg();
            message.success("Subscription created successfully");
            history.push("/super-admin");
        }).catch(() => {
            // message.error("Error occured while performing the operation");
        })
    };

    useEffect(() => {
        // scroll to the top of the page    
        window.scrollTo(0, 0);
        getModules();
        if (orgId) {
            setSubscription(history.location.state);
            form.setFieldsValue({ org: history.location.state });
            getUsers();
        }
    }, []);


    async function getUsers() {
        const auth0Users = await Axios({
            method: 'POST',
            url: `${config.api}/auth0/auth0-users`,
            data: {
                db: history.location.state.databaseURL,
                userType: 'COMPANY_ADMIN'
            }
        }).then(x => x.data);

        for (let i = 0; i < auth0Users.length; i++) {
            auth0Users[i].disabled = true;
            auth0Users[i].status = 'ADDED';
        }
        setUsers(auth0Users);
    }



    async function getModules() {
        const mods = await Axios.get(`${config.api}/modules`);
        let x = [];
        for (let i = 0; i < mods.data.length; i++) {
            x.push({
                ...mods.data[i],
                selected: false
            })
        }
        setModules(x);
    }

    function selectModule(index) {
        let mods = _.clone(modules);
        mods[index].selected = !mods[index].selected;
        setModules(mods);
    }


    function selectModuleForEdit(index) {
        let pCopy = _.clone(subscription);
        pCopy.modules[index].selected = !pCopy.modules[index].selected;
        setSubscription(pCopy);
        console.log(subscription);
    }

    function deleteUser(index) {
        setRandomNumber(Math.floor(Math.random() * 100000));
        let uCopy = _.clone(users);
        uCopy[index].status = 'DELETED';
        setUsers(users);
    }

    function addUser() {
        setRandomNumber(Math.floor(Math.random() * 100000));
        let uCopy = _.clone(users);
        uCopy.push({ name: '', email: '', disabled: false, status: 'ADDED' });
        setUsers(uCopy);
    }

    function renderUsers() {
        const x = []
        for (let i = 0; i < users.length; i++) {
            if (users[i].status == 'ADDED') {
                x.push(
                    <Row key={`${i}${randomNumber}`}>
                        <Col span={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Name</label>
                            <Input onChange={e => {
                                let uCopy = _.clone(users);
                                uCopy[i].name = e.target.value;
                                setUsers(uCopy);
                            }} disabled={users[i].disabled} className="neo" placeholder="Enter name" value={users[i].name} />
                        </Col>
                        <Col span={6} style={{ display: 'flex', flexDirection: 'column', marginLeft: 35 }}>
                            <label>Email ID</label>
                            <Input onChange={e => {
                                let uCopy = _.clone(users);
                                uCopy[i].email = e.target.value;
                                setUsers(uCopy);
                            }} disabled={users[i].disabled} className="neo" placeholder="Enter email id" value={users[i].email} />
                        </Col>

                        {
                            !welcomeEmail &&
                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', marginLeft: 35 }}>
                                <label>Password</label>
                                <Input onChange={e => {
                                    let uCopy = _.clone(users);
                                    uCopy[i].password = e.target.value;
                                    setUsers(uCopy);
                                }} disabled={users[i].disabled} className="neo" placeholder="Enter password" value={users[i].password} />
                            </Col>
                        }
                        <Col onClick={() => deleteUser(i)} span={2} style={{ display: 'flex', alignItems: 'center', marginLeft: 30, marginTop: 20 }}>
                            <DeleteOutlined />
                        </Col>
                    </Row>
                )
            }
        }
        return x;
    }


    return (
        <div className="onboard-page">
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/super-admin">
                        Subscriptions
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Subscription</Breadcrumb.Item>
            </Breadcrumb>
            <h1>{orgId ? 'Edit Subscription' : 'Create Subscription'}</h1>
            <Form
                form={form}
                autoComplete="off"
                className="onboard-form"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item name={['org', 'name']} label="Organization Name" rules={[{ required: true }]}>
                            <Input className="neo" placeholder="Please enter organization name" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['org', 'location']} label="Location" rules={[{ required: true }]}>
                            <Input className="neo" placeholder="eg. USA" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['org', 'industry']} label="Industry" rules={[{ required: true }]}>
                            {/* <Input className="neo" placeholder="eg. Information technology" /> */}
                            <Select
                                placeholder="Select a option from"
                            // onChange={this.onGenderChange}
                            >
                                <Select.Option value="High Technology">High Technology</Select.Option>
                                <Select.Option value="Insurance &amp; Healthcare">Insurance &amp; Healthcare</Select.Option>
                                <Select.Option value="Financial Services">Financial Services</Select.Option>
                                <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                                <Select.Option value="Agricultural ">Agricultural</Select.Option>
                                <Select.Option value="Utilities &amp; Energy">Utilities &amp; Energy</Select.Option>
                                <Select.Option value="Retail">Retail</Select.Option>
                                <Select.Option value="Consumer Goods">Consumer Goods</Select.Option>
                                <Select.Option value="Life Sciences">Life Sciences</Select.Option>
                                <Select.Option value="Education &amp; Institutions">Education &amp; Institutions</Select.Option>
                                <Select.Option value="Other">Other</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item name={['org', 'logo']} label="Logo URL" rules={[{ required: false }]}>
                            <Input className="neo" placeholder="eg. https://www.pixer.com/logo.png" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['org', 'domainName']} label="Domain Name" rules={[{ required: true }]}>
                            <Input className="neo" placeholder="eg. company.com" />
                        </Form.Item>
                    </Col>
                    <Col span={8} />
                </Row>
                <Row span={8}>
                    <h1>Customer Admin</h1>
                </Row>
                <Row span={8}>
                    <h1><Checkbox defaultChecked={welcomeEmail} onChange={(e) => setWelcomeEmail(e.target.checked)}>Send default onboard email</Checkbox></h1>
                </Row>
                {
                    customerAdmin && (
                        <>

                            {
                                renderUsers()
                            }
                            <Button type="default" onClick={addUser} style={{ marginTop: 10 }}>+Add more</Button>
                        </>
                    )
                }

                <Row>
                    <hr style={{ width: '100%' }} className="devider" />
                    <h1>Modules</h1>
                </Row>

                <Row>
                    <Col span={24} style={{ display: 'flex' }}>
                        {!subscription ?
                            modules.map((module, index) => (
                                module.status && <ModuleCard
                                    module={module}
                                    key={module.id}
                                    index={index}
                                    selectModule={selectModule}
                                />
                            ))
                            :
                            subscription.modules.map((module, index) => (
                                module.status
                                && <ModuleCard
                                    module={module}
                                    key={module.id}
                                    index={index}
                                    selectModule={selectModuleForEdit}
                                />
                            ))
                        }
                    </Col>
                </Row>

                <Row>
                    <hr style={{ width: '100%' }} className="devider" />
                    <h1>JIRA Details(Optional)</h1>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name={['org', 'jiraUsername']} label="JIRA Email id" rules={[{ type: 'email' }]}>
                            <Input placeholder="john@domain.com" className="neo" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['org', 'jiraApiToken']} label="JIRA Api Token">
                            <Input placeholder="API Token from JIRA" className="neo" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={['org', 'jiraBaseurl']} label="JIRA Base URL" rules={[{ type: 'url' }]}>
                            <Input placeholder="eg. https://company.atlassian.net" className="neo" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <hr style={{ width: '100%' }} className="devider" />
                    <ButtonArea>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={disabled}>
                                {disabled ? 'Saving Subscription...' : 'Save Subscription'}
                            </Button>
                            <Link to="/super-admin">
                                <Button type="default" style={{ marginLeft: 10 }}>Cancel</Button>
                            </Link>
                        </Form.Item>
                    </ButtonArea>
                </Row>

            </Form>
        </div>
    );
}

export default OnboardOrg;