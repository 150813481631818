import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './subs-tile.scss';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const AddNewSub = ({type, link, data}) => {

    // create a function 

    function getTo(){
        if(data){
            return {
                pathname: link,
                state: data
            }
        } else {
            return {
                pathname: link
            }
        }
    }
    return (
        <Link to={getTo()}>
            <div className="subs-tile">
                <div className="new-subs">
                    <span>New {capitalizeFirstLetter(type)}</span>
                </div>
                <span className="small-txt">Click here to add new {type}</span>
                <div>
                    
                        <Button type="primary" className="custom-add">Add {type}</Button>
                </div>
            </div>
        </Link>
    );
};

AddNewSub.propTypes = {
    type: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    data: PropTypes.object
}

export default AddNewSub;