import { Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import "./user-list-o.scss";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  ClockCircleFilled,
  ClockCircleOutlined,
} from "@ant-design/icons";

const UserList = ({ isModalVisible, handleOk, handleCancel, assessment }) => {
  const ifExists = (value) => {
    return (
      _.findIndex(assessment.response, (x) => {
        return x.userid === value;
      }) !== -1
    );
  };

  return (
    <Modal
      title="Participants"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      className="md-modal-x"
    >
      {assessment.users.map((user, index) => {
        return (
          <div key={index} className="user-list-o">
            <img src={user.picture} alt="profile_img" />
            <div className="info-o">
              <label>{user.name}</label>
              <label style={{ fontSize: "smaller" }}>{user.email}</label>
              {ifExists(user.email) ? (
                <label>
                  <CheckCircleFilled
                    style={{
                      color: "#52c41a",
                      left: "-18px",
                      top: "-24px",
                      position: "relative",
                    }}
                  />
                </label>
              ) : (
                <label>
                  <ClockCircleFilled
                    style={{
                      left: "-20px",
                      top: "-24px",
                      position: "relative",
                      color: "var(--gray1)",
                    }}
                  />
                </label>
              )}
            </div>
          </div>
        );
      })}
    </Modal>
  );
};

UserList.propTypes = {
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  isModalVisible: PropTypes.bool,
  assessment: PropTypes.object,
};

export default UserList;
