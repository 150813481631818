import { Button } from 'antd';
import React from 'react';
import '../../modules/CompanyAdmin/CompanyHome/empty-hm.scss';
import {Link} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const EmptyPage = ({imageRef, header, subtext, primaryButtonText, primaryButtonLink, helpLink, data}) => {

    const history = useHistory();

    function getTo(){
        if(data){
            history.push({
                pathname: primaryButtonLink,
                state: data
            })
        } else {
            history.push({
                pathname: primaryButtonLink
            });
        }
    }

    return (
        <div className="empty-hm">
            <div className="illustration">
                <img src={imageRef} alt="star" />
            </div>
            <div className="greeting">
                <h1>{header}</h1>
                <p>{subtext}</p>
                <div>
                     <Button onClick={getTo} type="primary">{primaryButtonText}</Button>
                    <Link to={`${helpLink}`}>
                        <Button className="outline" style={{marginLeft: 10}}>Need Help?</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EmptyPage;