/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import {
  Input, message, Row, Menu, Dropdown, Button,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { DownOutlined, EllipsisOutlined, CloudSyncOutlined } from '@ant-design/icons';
import _ from 'underscore';
import Axios from 'axios';
import PropTypes from 'prop-types';
import NeoCollaspe from '../../../../components/common/NeoCollaspe/NeoCollaspe.jsx';
import {
  DefaultRoundButton,
  NeoButton,
  NeoFillButton,
  YellowHead,
} from '../../../../components/elements/elements';
import './dashboard.scss';
import config from '../../../../config/config';
import EmptyAdd from '../../../../components/EmptyAdd/EmptyAdd';
import KPI from './KPI';
import Score from './Score';
import ScoreInputs from './ScoreInputs';
import OppKPI from './OppKPI';
import AddModel from './AddModel.js';
import AddModule from './AddModule.js';
import BarView from './BarView.jsx';
import JiraModal from './JiraModal.js';

const Dashboard = (props) => {
  const [px, setPx] = useState(null);
  const [selectedOpportunityIndex, setSelectedOpportunity] = useState(-1);
  const [selectedModuleIndex, setSelectedModue] = useState(-1);
  const [featureIndex, setFeatureIndex] = useState(0);
  const [masterScores, setMasterScores] = useState(null);
  const [addModule, setAddModule] = useState(false);
  const [addFeatureModal, setFeatureModal] = useState(false);
  const [addOppModal, setOppModal] = useState(false);
  const [view, setView] = useState('SCORE');
  const [opp, setOpp] = useState(null);
  const [module, setModule] = useState(null);
  const [feature, setFeature] = useState(null);
  const [isAdd, setIsAdd] = useState(true);
  const [jiraModal, setJiraModal] = useState(false);

  // Get Master Scores
  async function getMasterScore() {
    /* const masterScoresX = await Axios.post(
      `${config.api}/programs/get-master-scores`,
    );
    setMasterScores(masterScoresX.data); */
  }

  // Get Program details

  async function getProgram() {
    const program = await Axios.get(
      `${config.api}/programs/${props.match.params.programId}`,
    );
    setPx(program.data);
    setMasterScores(program.data.config);
    setSelectedOpportunity(0);
  }
  useEffect(() => {
    getProgram();
    getMasterScore();
  }, []);

  function menuClick(mx, ix) {
    setSelectedOpportunity(ix);
    setSelectedModue(-1);
    setFeatureIndex(0);
  }

  function subMenuClick(sx, ix) {
    setSelectedModue(ix);
    setFeatureIndex(0);
  }

  function getScore(data) {
    let featureCount = 0;
    for (let i = 0; i < data.modules.length; i += 1) {
      featureCount += data.modules[i].features.length;
    }

    return {
      featureCount,
      moduleCount: data.modules.length,
    };
  }

  function selectFeature(index) {
    setFeatureIndex(index);
  }

  function generateMenu(features) {
    const menu = [];
    for (let i = 0; i < features.length; i += 1) {
      menu.push(
        <Menu.Item key={i} onClick={() => selectFeature(i)}>
          {features[i].feature}
        </Menu.Item>,
      );
    }
    return <Menu>{menu}</Menu>;
  }

  /* Todo: Need to work on aggregate result set */
  function formInputScore(responses) {
    if (responses === undefined) {
      return {
        dependency: null,
        competitive: null,
        innovation: null,
        implementation: null,
        integration: null,
        maintainance: null,
        cost: null,
        reusability: null,
        audienceSize: null,
        strategic: null,
        duration: null,
      };
    }
    return responses[0];
  }

  function conditionUpdate(val, features, key) {
    if (val > 5) {
      if (features.responses[0][key] === undefined) {
        return null;
      }
      return features.responses[0][key];
    }
    return val;
  }

  /* Todo: Need to work on aggregate result set */
  function changeResponse(key, val) {
    const clonedPX = _.clone(px);
    const features = clonedPX.opportunities[selectedOpportunityIndex].modules[
      selectedModuleIndex
    ].features[featureIndex];
    if (features.responses === undefined) {
      features.responses = [{}];
    }
    features.responses[0][key] = conditionUpdate(val, features, key);
    setPx(clonedPX);
  }

  /* Todo: Need to work on aggregate result set */
  function updateProgram() {
    const loading = message.loading('Saving capability details...', 0);
    Axios.put(`${config.api}/programs/${props.match.params.programId}`, px)
      .then(() => {
        loading();
        message.success('Feature score saved successfully!');
      })
      .catch((e) => {
        message.error(`Error ocured while performing the operation${e}`);
      });
  }

  function featureRank(feature) {
    if (feature.responses === undefined) {
      return 0;
    }
    const resp = Object.keys(feature.responses[0]);
    let sum = 0;
    for (let i = 0; i < resp.length; i += 1) {
      sum
        += (parseFloat(masterScores[resp[i]]) / 100)
        * parseFloat(feature.responses[0][resp[i]]);
    }
    return parseFloat(sum / resp.length).toFixed(1);
  }

  function moduleRank(module) {
    if (module.features === undefined || module.features.length === 0) {
      return 0;
    }
    let sum = 0;
    for (let i = 0; i < module.features.length; i += 1) {
      const fa = featureRank(module.features[i]);
      const isNumber = (value) => !Number.isNaN(Number(value));
      sum += !isNumber(fa) ? 0 : parseFloat(fa);
    }
    return (sum / module.features.length).toFixed(1);
  }

  function opportunityRank(opportunity) {
    if (opportunity.modules === undefined || opportunity.modules.length === 0) {
      return 0;
    }
    let sum = 0;
    for (let i = 0; i < opportunity.modules.length; i += 1) {
      const moduleR = moduleRank(opportunity.modules[i]);
      sum += parseFloat(moduleR);
    }
    return (sum / opportunity.modules.length).toFixed(1);
  }

  /* Todo: Need to work on aggregate result set */
  function calculateKPI(opportunity) {
    // get feature rank
    const feature = opportunity.modules[selectedModuleIndex].features[featureIndex];

    return {
      featureRank: featureRank(feature),
      moduleRank: moduleRank(opportunity.modules[selectedModuleIndex]),
      opportunityRank: opportunityRank(opportunity),
    };
  }

  function changeView() {
    if (view === 'SCORE') {
      setView('CHART');
    } else {
      setView('SCORE');
    }
  }
  function setEdit(val) {
    setIsAdd(false);
    if (val.key === 'opportunity') {
      const x = px.opportunities[selectedOpportunityIndex];
      setOpp({
        opportunityName: x.opportunityName,
        description: x.description,
      });
      setOppModal(true);
    } else if (val.key === 'module') {
      const x = px.opportunities[selectedOpportunityIndex].modules[selectedModuleIndex];
      setModule({
        moduleName: x.moduleName,
        description: x.description,
        starts: x.starts,
      });
      setAddModule(true);
    } else if (val.key === 'feature') {
      const x = px.opportunities[selectedOpportunityIndex]
        .modules[selectedModuleIndex]
        .features[featureIndex];
      setFeature({
        feature: x.feature,
        description: x.description,
      });
      setFeatureModal(true);
    }
  }
  function getEditMenu() {
    return (
      <Menu>
        <Menu.Item key="opportunity" onClick={setEdit}>
          <span>Edit Opportunity</span>
        </Menu.Item>
        <Menu.Item key="module" onClick={setEdit}>
          <span>Edit Module</span>
        </Menu.Item>
        <Menu.Item key="feature" onClick={setEdit}>
          <span>Edit Feature</span>
        </Menu.Item>
      </Menu>
    );
  }

  async function deleteOMF(type) {
    if (type === 'OPPORTUNITY') {
      setSelectedOpportunity(selectedOpportunityIndex - 1);
      px.opportunities.splice(selectedOpportunityIndex, 1);
      message.success('Scenario has been deleted successfully');
      setOppModal(false);
      setOpp(null);
    } else if (type === 'MODULE') {
      setSelectedModue(selectedModuleIndex - 1);
      px.opportunities[selectedOpportunityIndex].modules.splice(selectedModuleIndex, 1);
      message.success('Module has been deleted successfully');
      setAddModule(false);
      setModule(null);
    } else if (type === 'FEATURE') {
      setFeatureIndex(featureIndex - 1);
      px.opportunities[selectedOpportunityIndex]
        .modules[selectedModuleIndex].features.splice(featureIndex, 1);
      message.success('Feature has been deleted successfully');
      setFeatureModal(false);
      setFeature(null);
    }

    const loading = message.loading('Deleting...', 0);

    Axios.put(`${config.api}/programs/${props.match.params.programId}`,
      px).then((resp) => {
      loading();
    });
  }

  function uploadToJira() {
    Axios.post('http://localhost:5786/api/programs/jira', {
      programId: '5fb001d5289ab70c3bc89998',
      jiraProjectKey: 'REST',
    }).then((resp) => {
      console.log(resp);
    });
  }

  return (
    <div className="pi-dashboard">
      {px && (
        <>
          <div className="top-head-features">
            <YellowHead>
              {px.programName.toUpperCase()}
              {' '}
              CAPABILITY SCORING RANK
            </YellowHead>
            <Button onClick={() => setJiraModal(true)} type="link">
              <CloudSyncOutlined />
              {' '}
              Sync with JIRA
            </Button>
          </div>

          {px.opportunities.length === 0 ? (
            <>
              <EmptyAdd
                style={{ height: '58vh' }}
                buttonText="Add Scenario"
                text="You need to add an scenario to continue"
                onClick={() => {
                  // props.history.push(
                  //   `/company-admin/add-module/${props.match.params.programId}`,
                  // );
                  setOppModal(true);
                }}
              />
            </>
          ) : (
            <div className="contents-pf">
              <div className="modules">
                <Input
                  className="neo"
                  placeholder="Search.."
                  style={{ width: '100px' }}
                />
                <Row className="add-feature" onClick={() => { setOppModal(true); setIsAdd(true); }} style={{ marginTop: '13px', marginLeft: '10px' }}>
                  + Add opportunity
                </Row>
                <NeoCollaspe
                  menu={px.opportunities}
                  menuKey="opportunityName"
                  subMenuKey="moduleName"
                  subMenuObj="modules"
                  addWhenEmpty
                  addButton="+ Add Module"
                  menuClick={(mx, ix) => menuClick(mx, ix)}
                  subMenuClick={(sx, ix) => subMenuClick(sx, ix)}
                  onAdd={() => { setIsAdd(true); setAddModule(true); }}
                />
              </div>
              <div className="kpis">
                {selectedOpportunityIndex >= 0 && selectedModuleIndex === -1 && (
                  <>
                    <div className="row-0">
                      <OppKPI
                        data={opportunityRank(
                          px.opportunities[selectedOpportunityIndex],
                        )}
                      />
                    </div>

                    <div className="row-1">
                      <Score
                        data={getScore(
                          px.opportunities[selectedOpportunityIndex],
                        )}
                      />
                    </div>

                    <div>
                      <div className="add-wrap">
                        <div className="btx">
                          <DefaultRoundButton
                            onClick={() => {
                              setAddModule(true);
                            }}
                            className="large"
                            completed
                          >
                            <i className="fa fa-plus" />
                          </DefaultRoundButton>
                          <span>ADD MODULE</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {selectedOpportunityIndex >= 0 && selectedModuleIndex >= 0 && (
                  px.opportunities[selectedOpportunityIndex]
                    .modules[selectedModuleIndex].features.length === 0
                    ? (
                      <>
                        <EmptyAdd
                          style={{ height: '58vh' }}
                          buttonText="Add Capability"
                          text="You need to add capability to continue"
                          onClick={() => {
                            setIsAdd(true);
                            setIsAdd(true);
                            setFeatureModal(true);
                          }}
                        />
                      </>
                    )
                    : (
                      <>
                        <div className="row-0 row-01">
                          <div className="mf-area">
                            <Row style={{ justifyContent: 'space-between' }}>
                              <div>
                                <span>Module: &nbsp;</span>
                                <span>
                                  {
                                px.opportunities[selectedOpportunityIndex]
                                  .modules[selectedModuleIndex].moduleName
                                }
                                </span>
                              </div>
                              <div>
                                <Dropdown overlay={getEditMenu()} trigger={['click']}>
                                  <Button type="text" style={{ height: '37px' }} onClick={(e) => e.preventDefault()}>
                                    <span style={{ fontSize: '16px' }}>Edit</span>
                                    {' '}
                                    <EllipsisOutlined className="rotate-90" />
                                  </Button>
                                </Dropdown>
                              </div>
                            </Row>
                            <Row>
                              <span>Feature: &nbsp;</span>

                              <Dropdown
                                trigger={['click']}
                                overlay={generateMenu(
                                  px.opportunities[selectedOpportunityIndex]
                                    .modules[selectedModuleIndex].features,
                                )}
                              >
                                <span>
                                  {
                                px.opportunities[selectedOpportunityIndex]
                                  .modules[selectedModuleIndex].features[
                                    featureIndex
                                  ].feature
                              }
                                  {' '}
                                  <DownOutlined />
                                </span>
                              </Dropdown>
                            </Row>
                            <Row style={{ marginTop: '20px' }} onClick={() => { setFeatureModal(true); setIsAdd(true); }}>
                              <span className="add-feature">+ Add Feature</span>
                            </Row>
                          </div>
                          <KPI
                            data={calculateKPI(
                              px.opportunities[selectedOpportunityIndex],
                            )}
                          />
                        </div>
                        <div className="row-1">
                          <Button
                            onClick={changeView}
                            type="link"
                            style={{
                              position: 'absolute', marginLeft: '875px', fontWeight: 'bold', zIndex: 1000,
                            }}
                          >
                            {view === 'SCORE' ? 'Chart ' : 'Score '}
                            {' '}
                            View
                          </Button>
                          {
                            view === 'SCORE'
                              ? (
                                <ScoreInputs
                                  changeResponse={changeResponse}
                                  responses={formInputScore(
                                    px.opportunities[selectedOpportunityIndex].modules[
                                      selectedModuleIndex
                                    ].features[featureIndex].responses,
                                  )}
                                />
                              )
                              : (
                                <BarView
                                  data={
                                    px
                                      .opportunities[selectedOpportunityIndex]
                                      .modules[selectedModuleIndex]
                                      .features[featureIndex]
                                  }
                                  masterScores={masterScores}
                                />
                              )
                          }
                        </div>
                      </>
                    )
                )}
              </div>
            </div>
          )}

          <hr className="devider" />
          <div className="pf-button-area">
            <div>
              {/* <Popconfirm
                            title="Are you sure delete this program?"
                            onConfirm={() => confirm()}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" danger>
                                <i className="fa fa-trash-o"></i> &nbsp; Delete
                            </Button>
                        </Popconfirm> */}
              <NeoButton
                onClick={() => {
                  props.history.push(
                    `/company-admin/roadmap/${props.match.params.programId}`,
                  );
                }}
              >
                VIEW ROADMAP
              </NeoButton>
              <NeoButton
                onClick={() => {
                  props.history.push(
                    `/company-admin/bubble-graph/${props.match.params.programId}`,
                  );
                }}
              >
                PRIORITIZATION
              </NeoButton>
            </div>
            {px
              && px.opportunities[selectedOpportunityIndex]
              && px.opportunities[selectedOpportunityIndex].modules[
                selectedModuleIndex
              ] && (
                <div>
                  <NeoButton
                    disabled={featureIndex === 0}
                    onClick={() => setFeatureIndex(featureIndex - 1)}
                  >
                    <i className="fa fa-chevron-left" />
                    {' '}
                    &nbsp; PREV. FEATURE
                  </NeoButton>
                  <NeoButton
                    onClick={() => setFeatureIndex(featureIndex + 1)}
                    disabled={
                      px.opportunities[selectedOpportunityIndex].modules[
                        selectedModuleIndex
                      ].features.length - 1 === featureIndex
                    }
                  >
                    NEXT FEATURE &nbsp;
                    {' '}
                    <i className="fa fa-chevron-right" />
                  </NeoButton>
                  <NeoFillButton onClick={updateProgram}>SAVE</NeoFillButton>
                </div>
            )}
          </div>
        </>
      )}
      {
        addModule
        && (
        <AddModel
          visible={addModule}
          handleOk={() => { setAddModule(false); setModule(null); }}
          handleCancel={() => {
            setAddModule(false);
            setModule(null);
          }}
          title="Add Module"
        >
          {px && (
          <AddModule
            programId={props.match.params.programId}
            type="MODULE"
            closeModal={(updatedProgram) => {
              setAddModule(false);
              setPx(updatedProgram);
              setModule(null);
            }}
            timeUnit={px.config.timeUnit}
            opportunityIndex={selectedOpportunityIndex}
            moduleIndex={selectedModuleIndex}
            moduleX={module}
            isAdd={isAdd}
            onDelete={deleteOMF}
          />
          )}
        </AddModel>
        )
      }

      {
        addFeatureModal
        && (
        <AddModel
          visible={addFeatureModal}
          handleOk={() => { setFeatureModal(false); setFeature(null); }}
          handleCancel={() => { setFeatureModal(false); setFeature(null); }}
          title="Add Feature"
        >
          <AddModule
            programId={props.match.params.programId}
            type="FEATURE"
            closeModal={(updatedProgram) => {
              setFeatureModal(false);
              setPx(updatedProgram);
              setFeature(null);
            }}
            opportunityIndex={selectedOpportunityIndex}
            moduleIndex={selectedModuleIndex}
            isAdd={isAdd}
            feature={feature}
            featureIndex={featureIndex}
            onDelete={deleteOMF}
          />
        </AddModel>
        )
      }
      {addOppModal
      && (
      <AddModel
        visible={addOppModal}
        handleOk={() => { setOppModal(false); setOpp(null); }}
        handleCancel={() => { setOppModal(false); setOpp(null); }}
        title="Add Opportunity"
      >
        <AddModule
          programId={props.match.params.programId}
          type="OPPORTUNITY"
          closeModal={(updatedProgram) => {
            setOppModal(false);
            setPx(updatedProgram);
            setOpp(null);
          }}
          opportunityIndex={selectedOpportunityIndex}
          moduleIndex={selectedModuleIndex}
          opp={opp}
          isAdd={isAdd}
          onDelete={deleteOMF}
        />
      </AddModel>
      )}

      {
        jiraModal && (
          <AddModel
            visible={jiraModal}
            handleOk={uploadToJira}
            handleCancel={() => { setJiraModal(false); }}
            title="Sync with JIRA"
          >
            <JiraModal
              programId={props.match.params.programId}
              onFinish={(newProgram) => {
                setPx(newProgram);
              }}
            />
          </AddModel>
        )
      }

    </div>
  );
};

Dashboard.propTypes = {
  match: PropTypes.shape({
    params: {
      programId: PropTypes.string.isRequired,
    },
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
Dashboard.defaultProps = {
  match: {},
  history: {},
};

export default Dashboard;
