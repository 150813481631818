import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell,
} from 'recharts';

const colors = {
  dependency: '#9CD34D ',
  competitive: '#9CD34D ',
  innovation: '#9CD34D ',
  implementation: '#9CD34D ',
  integration: '#9CD34D ',
  maintainance: '#9CD34D ',
  cost: '#E6E65C',
  reusability: '#84A2EF',
  audienceSize: '#F2B960',
  strategic: '#67F263',
  duration: '#EAE64E',
};

const BarView = ({ masterScores, data }) => {
  function getVals() {
    const arr = [];
    if (data.responses && data.responses.length > 0) {
      const resp = data.responses[0];
      Object.keys(resp).forEach((elem) => {
        const val = ((parseFloat(resp[elem]) / 100) * masterScores[elem]).toFixed(1);
        arr.push({
          name: `${elem}`,
          score: val,
        });
      });
    }
    return arr;
  }
  return (
    <div>
      <BarChart
        width={1000}
        height={400}
        data={getVals()}
        margin={{
          top: 40, right: 30, left: 20, bottom: 80,
        }}
      >
        <CartesianGrid strokeDasharray="3" />
        <XAxis interval={0} dataKey="name" angle={-45} textAnchor="end" />
        <YAxis />
        <Tooltip />

        <Bar dataKey="score" radius={[15, 15, 0, 0]}>
          {
            getVals().map((matrix) => <Cell key={`cell-${matrix}`} fill={colors[matrix.name]} />)
          }
        </Bar>

      </BarChart>
    </div>
  );
};

BarView.propTypes = {
  masterScores: PropTypes.shape({
    all: PropTypes.string,
  }),
  data: PropTypes.shape({
    feature: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    responses: PropTypes.array,
  }),
};
BarView.defaultProps = {
  masterScores: {},
  data: {},
};
export default BarView;
