import React from "react";
import "./main_table.scss";
import PropTypes from 'prop-types'
import ProgramRow from "./ProgramRow";
import InitiativeRow from "./InitiativeRow";
import OpportunityRow from "./OpportunityRow";
import FeatureRow from "./FeatureRow";

const MainTable = ({rows}) => {


  const renderRow = (type, index, row) => {
      switch(type){
          case 'PROGRAM': return <ProgramRow index={index} row={row} />
          case 'OPPORTUNITY': return <OpportunityRow index={index} row={row} />
          case 'INITIATIVE': return <InitiativeRow index={index} row={row} />
          case 'FEATURE': return <FeatureRow index={index} row={row} />
          default: return null;
      }
  }

  return (
    <div className="main_table">
      <table width={"100%"}>
        <thead>
            <tr>
                <td>Program</td>
                <td>Scenario</td>
                <td>Initiative</td>
                <td>Feature</td>

                <td className="inps">Dependency</td>
                <td className="inps">Competitive</td>
                <td className="inps">Innovation</td>
                <td className="inps">Implementation</td>
                <td className="inps">Integration</td>
                <td className="inps">Cost</td>
                <td className="inps">Audience</td>
                <td className="inps">Duration</td>
                <td className="inps">Strategic</td>
                <td className="inps">Reusability</td>
                <td className="inps">Maintainance</td>

                <td>Program Scoring</td>
                <td>Scenario Scoring</td>
                <td>Initiative Scoring</td>
                <td>Feature Scoring</td>
            </tr>
        </thead>
        <tbody>
            {
                rows.map((row, index)=>(
                    renderRow(row.type, index, row)
                ))
            }
        </tbody>

</table>
    </div>
  );
};

MainTable.propTypes = {
    rows: PropTypes.array
}

export default MainTable;
