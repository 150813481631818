import React, {useContext} from 'react';
import './tile.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlxHrSB } from '../../../../../components/elements/elements';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {  Modal } from 'antd';
import {CustomerAdminContext} from '../../../CustomerAdminContext';

const { confirm } = Modal;
// import moment from 'moment';


function moduleRank(module, masterScores) {
    if (module.features === undefined || module.features.length === 0) {
      return 0;
    }
    let sum = 0;
    for (let i = 0; i < module.features.length; i += 1) {
      const fa = featureRank(module.features[i],masterScores);
      const isNumber = (value) => !Number.isNaN(Number(value));
      sum += !isNumber(fa) ? 0 : parseFloat(fa);
    }
    return (sum / module.features.length).toFixed(1);
  }
  function featureRank(feature, masterScores) {
    if (feature.responses === undefined) {
      return 0;
    }
    const resp = Object.keys(feature.responses[0]);
    let sum = 0;
    for (let i = 0; i < resp.length; i += 1) {
      sum+= (parseFloat(masterScores[resp[i]]) / 100)* parseFloat(feature.responses[0][resp[i]]);
    }
    return parseFloat(sum / resp.length).toFixed(1);
  }

const InitiativeTile = ({ initiative, onClick, configs, editInitiative, deleteInitiative }) => {

  const {rankingHierarchy} = useContext(CustomerAdminContext);

  function showConfirm() {
    confirm({
      title: `Are you sure you want to delete this ${rankingHierarchy[3].s}?`,
      icon: <ExclamationCircleOutlined />,
      content: `You will not be able to recover this ${rankingHierarchy[3].s}`,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteInitiative();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

    return (
        <div onClick={onClick} className="tile" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <FlxHrSB>
                  <span className="title">{initiative.moduleName}</span>
                  <div className="action-icons" style={{marginRight: 10}}>
                      <EditOutlined style={{marginRight: 10}} onClick={(e)=>{
                        editInitiative();
                        e.stopPropagation();
                      }} />

                     <DeleteOutlined onClick={(e)=>{
                          showConfirm();
                          e.stopPropagation();
                      }} />
                  </div>
                </FlxHrSB>
                <div style={{height:20}}></div>
                <div className="row__tile">
                    <span>{rankingHierarchy[4].p}</span>-
                    <span>{initiative.features.length}</span>
                </div>
                <div className="">
                    <span style={{fontWeight: 'bold', fontSize: 18}}>{
                        (parseFloat(moduleRank(initiative,configs))).toFixed(1)
                    }</span>
                    <span> {rankingHierarchy[3].s} Rank</span>
                </div>
            </div>

            <div style={{marginBottom: 15, display: 'flex', justifyContent: 'space-between'}}>
                <span style={{color: 'var(--text-color'}}>
                    Created - {moment(initiative.createdAt).format('MMM DD yy')}
                </span>
                <span style={{color: 'var(--primary-color', marginRight: 10}}>Active</span>
            </div>
        </div>
    );
};

InitiativeTile.propTypes ={
    initiative: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    configs: PropTypes.object.isRequired,
    editInitiative: PropTypes.func.isRequired,
    deleteInitiative: PropTypes.func.isRequired
}

export default InitiativeTile;