import React from 'react';
import {Button, Modal,Progress} from 'antd';
import { Bullet,Per,BuletText,MTX } from '../../../components/elements/elements';
import marker from '../../../assets/icons/marker.svg';
import PropTypes from 'prop-types';


const ProductLaunchModal = ({visible,onOk,onCancel,modalDetails}) => {

    return (
        <Modal
          title="Assessment Level Details"
          centered
          className="md-modal md-modal-px"
          visible={visible}
          onOk={() => onOk()}
          onCancel={() => onCancel()}
        >
          <div className="md-top-area">
              <div className="md-progres">
                  <div>
                    <Progress type="circle" className="progress-green" percent={modalDetails.percantage}  />
                  </div>
                  <div className="mtxx">
                    <MTX>
                        <Per>{modalDetails.percantage}%</Per>
                        <Bullet green></Bullet>
                        <BuletText>Maturity Threshold Percentage</BuletText>
                    </MTX>
                  </div>
              </div>
              <div className="md-label" style={{flex:2}}>
                
                {
                    <img 
                        src={marker} 
                        alt="Marker 1" 
                        className={`mark mark${Math.round((modalDetails.percantage/100)*10)}`}
                    />
                }

                <img src={require('../../../assets/images/maturity.svg')} style={{height: "342px"}} alt="Maurity image" />
              </div>

          </div>
          <div className="md-line" />
          <div className="md-top-area">
              
          </div>

          <div className="md-top-area" style={{justifyContent:'end'}}>
                <Button onClick={onCancel} type='primary' >Close</Button>
          </div>

        </Modal>
    );
};

ProductLaunchModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    modalDetails: PropTypes.object.isRequired
}


export default ProductLaunchModal;