import React from 'react';
import './empty_add.scss';
import PropTypes from 'prop-types';
import { DefaultRoundButton } from '../elements/elements';

const EmptyAdd = (props) => {
    return (
        <div className="empty-add" style={props.style}>
             <div className="text">
                 {props.text}
              </div>
              <div className="btns">
                <DefaultRoundButton className="xx" onClick={props.onClick} completed>
                    <i className="fa fa-plus" />
                </DefaultRoundButton>
                {props.buttonText}
              </div>
            
        </div>
    );
};

EmptyAdd.propTypes = {
    buttonText: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
}

export default EmptyAdd;