import React, { useContext, useState } from 'react';
import './launchpad.scss';
import { Breadcrumb, Button, Dropdown, Menu, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import KpiList from '../../../../components/common/KpiList/KpiList';
import PropTypes from 'prop-types';
import { DownOutlined, TableOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { CustomerAdminContext } from '../../CustomerAdminContext';
import JiraIcon from '../../../../components/common/CustomIcons/JiraIcon';
import AddModel from '../Dashboard/AddModel';
import JiraModal from '../Dashboard/JiraModal';


const LaunchPad = ({ programs, setColView, loading, onFeatureSelect, onOpportunitySelect, onInitiativeSelect, onProgramSelect, indexes, reducer, showAll, setShowModal }) => {

    const { rankingHierarchy } = useContext(CustomerAdminContext);
    const [jiraModal, setJiraModal] = useState(false)

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="jira" icon={<JiraIcon />}>
                Upload to JIRA
            </Menu.Item>
            <Menu.Item key="excel" icon={<UploadOutlined />}>
                Upload Excel
            </Menu.Item>
        </Menu>
    );


    function handleMenuClick(e) {
        console.log(e);
        if (e.key === 'excel') {
            setShowModal();
        } else {
            setJiraModal(true);
        }
    }

    function checkOpp() {
        try {
            let x = typeof programs[indexes.programIndex].opportunities;
            if (x === 'undefined') {
                return false;
            }
            return true;
        } catch (e) {
            return false
        }
    }
    function checkInitiative() {
        try {
            let x = typeof programs[indexes.programIndex].opportunities[indexes.opportunityIndex].modules;
            if (x === 'undefined') {
                return false;
            }
            return true;
        } catch (e) {
            return false
        }
    }
    function checkFeature() {
        try {
            let x = typeof programs[indexes.programIndex].opportunities[indexes.opportunityIndex].modules[indexes.inititiveIndex].features;
            if (x === 'undefined') {
                return false;
            }
            return true;
        } catch (e) {
            return false
        }
    }

    return (
        <div className="projects-page launchpad">
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-admin">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/company-admin/projects">
                        {rankingHierarchy[0].p}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/company-admin/project/${reducer.dataReducer.project._id}`}>{reducer.dataReducer.project.projectName}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {rankingHierarchy[1].p}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1>{rankingHierarchy[1].p}</h1>
                <div>
                    <Button type="default" title="List View" onClick={setColView}>
                        <UnorderedListOutlined /> Show as Cards
                    </Button>
                    <Link to={`/company-admin/score-ranking-summary/${reducer.dataReducer.project._id}`}>
                        <Button type="default" title="List View" style={{ marginLeft: 10 }}>
                            <TableOutlined /> Show Report
                        </Button>
                    </Link>
                    {/* <Button onClick={setShowModal} style={{ marginLeft: 10 }} type='primary'>
                        <UploadOutlined /> Upload Excel
                    </Button> */}
                    <Dropdown overlay={menu} >
                        <Button type="default" className='actions-buttons' style={{ marginLeft: 10 }}>
                            Upload <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>

            {
                !loading ?
                    <div style={{ display: 'flex' }}>
                        {programs.length > 0 ?
                            <KpiList
                                arr={programs}
                                title={`${rankingHierarchy[1].p}`}
                                nameKey="programName"
                                index={indexes.programIndex}
                                onSelect={(index) => {
                                    onOpportunitySelect(0);
                                    onInitiativeSelect(0);
                                    onProgramSelect(index)
                                }}
                                onClick={() => showAll('program')}
                            />
                            :
                            <KpiList
                                arr={[]}
                                title={`${rankingHierarchy[0].p}`}
                                nameKey="programName"
                                index={0}
                                onSelect={() => { }}
                            />
                        }
                        {checkOpp() ?
                            <KpiList
                                arr={programs[indexes.programIndex].opportunities}
                                title={`${rankingHierarchy[2].p}`}
                                nameKey="opportunityName"
                                index={indexes.opportunityIndex}
                                onSelect={(index) => {
                                    onInitiativeSelect(0);
                                    onOpportunitySelect(index);
                                    onOpportunitySelect(index)
                                }}
                                onClick={() => showAll('opportunity')}
                            />
                            :
                            <KpiList
                                arr={[]}
                                title={`${rankingHierarchy[2].p}`}
                                nameKey="opportunityName"
                                index={0}
                                onSelect={() => { }}
                            />
                        }
                        {checkInitiative() ?
                            <KpiList
                                arr={programs[indexes.programIndex].opportunities[indexes.opportunityIndex].modules}
                                title={`${rankingHierarchy[3].p}`}
                                nameKey="moduleName"
                                index={indexes.inititiveIndex}
                                onSelect={(index) => {
                                    onInitiativeSelect(index);
                                    onInitiativeSelect(index)
                                }}
                                onClick={() => showAll('inititive')}
                            /> :
                            <KpiList
                                arr={[]}
                                title={`${rankingHierarchy[3].p}`}
                                nameKey="moduleName"
                                index={0}
                                onSelect={() => { }}
                            />
                        }
                        {checkFeature() ?
                            <KpiList
                                arr={programs[indexes.programIndex].opportunities[indexes.opportunityIndex].modules[indexes.inititiveIndex].features}
                                title={`${rankingHierarchy[4].p}`}
                                nameKey="feature"
                                index={-1}
                                onSelect={(index) => {
                                    onFeatureSelect(index);
                                }}
                                onClick={() => showAll('feature')}
                            />
                            :
                            <KpiList
                                arr={[]}
                                title={`${rankingHierarchy[4].p}`}
                                nameKey="feature"
                                index={0}
                                onSelect={() => { }}
                            />
                        }
                    </div>
                    :
                    <div style={{ marginTop: 40, display: 'flex' }} className="ip-load">
                        <Skeleton active paragraph={{ rows: 10 }} />
                        <Skeleton active paragraph={{ rows: 10 }} />
                        <Skeleton active paragraph={{ rows: 10 }} />
                        <Skeleton active paragraph={{ rows: 10 }} />
                    </div>
            }
            <AddModel
                visible={jiraModal}
                handleOk={() => { }}
                handleCancel={() => { setJiraModal(false); }}
                title="Upload to JIRA"
            >
                <JiraModal
                    programs={programs}
                    onFinish={(newProgram) => {
                        // setPx(newProgram);
                        console.log(newProgram)
                    }}
                />
            </AddModel>
        </div>
    );
};

LaunchPad.propTypes = {
    programs: PropTypes.array,
    setColView: PropTypes.func,
    loading: PropTypes.bool,
    onFeatureSelect: PropTypes.func,
    onOpportunitySelect: PropTypes.func,
    onInitiativeSelect: PropTypes.func,
    onProgramSelect: PropTypes.func,
    indexes: PropTypes.object,
    project: PropTypes.object,
    reducer: PropTypes.object,
    showAll: PropTypes.func,
    setShowModal: PropTypes.func
}


function mapStateToProps(state) {
    return {
        reducer: state
    };
}


export default connect(
    mapStateToProps
)(LaunchPad);
