import React from 'react';

function NoAccess(props) {
    return (
        <div>
            <h3>You don't have access to this project</h3>
        </div>
    );
}

export default NoAccess;