import React from 'react';
import { FlexBox, ProjectDetailsComp, WelcomeUser } from '../../components/elements/elements';
import Skeleton from 'react-loading-skeleton';



const ClientHomeSketch = (props) => {

   
    return (
      <div className="cusomer-admin-dashboard">
        <ProjectDetailsComp>
          <WelcomeUser>
              <Skeleton 
                count={1}
              />
          </WelcomeUser>
          <div className="welcome-subtext">
            
          </div>
          <hr className="devider" style={{marginBottom:0}} />

          <FlexBox center height={'68vh'} flexDirection="row">
                 {
                     [1,2,3].map((assx,index)=>(
                        <Skeleton height={300} width={300} key={index} style={{margin:'20px'}}>

                        </Skeleton>
                     ))
                 }
                
          </FlexBox>

        </ProjectDetailsComp>
      </div>
    );
};

export default ClientHomeSketch;