const config = {
  api: 'https://dev-api.prioriti.ai/api',
  // api: "https://nectar.honeycom3.com:8443/api"
  auth0: {
    domain: "nectar-dev.us.auth0.com",
    clientId: 'JFluM5bZrkDie2CQG2YR38Q3DuzR3q6O',
    audience: 'http://my-app'
  },
  env: 'DEV'
};


export default config;
