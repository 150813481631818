/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router';
import BubbleChart from '../../../components/BubbleChart/BubbleChart';
import './ip-bubble.scss';
import { moduleRank, getScoreMatrix } from '../../../utils/programScores';
// import config from '../../../config/config';
import PropTypes from 'prop-types';
import { Checkbox, Select } from 'antd';

const IpBubble = ({ opportunityIndex, programData }) => {
  const { programId } = useParams();
  const [program, setProgram] = useState(null);
  const [oppIndex, setOppIndex] = useState(opportunityIndex);
  const [keyIndex, setKeyIndex] = useState(0);
  const [bubbleData, setDataBubble] = useState([]);
  const colors = ['#EB662C', '#D51592', '#068AFC', '#FAB195', '#FCEF50', '#FDF7A7', '#B400FF', '#384E96', '##B73FB7'];
  const [heatmapChecked, setHeatmapChecked] = useState(true)

  const keys = [
    { key: 'audienceSize', name: 'Audience Size' },
    { key: 'competitive', name: 'Competitive' },
    { key: 'cost', name: 'Cost' },
    { key: 'dependency', name: 'Dependency' },
    { key: 'duration', name: 'Duration' },
    { key: 'implementation', name: 'Implementation' },
    { key: 'innovation', name: 'Innovation' },
    { key: 'integration', name: 'Integration' },
    { key: 'maintainance', name: 'Maintainance' },
    { key: 'reusability', name: 'Reusability' },
    { key: 'strategic', name: 'Strategic' }];

  function generateData(programX, opi, kpi) {
    const dataBubble = [];

    for (let j = 0; j < programX.opportunities.length; j += 1) {
      for (let i = 0; i < programX.opportunities[j].modules.length; i += 1) {
        const module = programX.opportunities[j].modules[i];
        module.score = parseFloat(moduleRank(module, programX.config));
        module.scores = getScoreMatrix(module);
      }
    }

    for (let i = 0; i < programX.opportunities[opi].modules.length; i += 1) {
      const module = programX.opportunities[opi].modules[i];
      dataBubble.push({
        x: module.score,
        y: module.scores.cost,
        r: module.scores[keys[kpi].key],
        name: module.moduleName,
        color: colors[i],
      });
    }
    setDataBubble(dataBubble);
  }

  async function getProgram() {
    const px = programData;
    // const px = await Axios.get(`${config.api}/programs/${programId}`).then((x) => x.data);
    setProgram(px);
    generateData(px, oppIndex, keyIndex);
  }

  useEffect(() => {
    getProgram();
  }, []);

  function changeKey(index) {
    setKeyIndex(index);
    generateData(program, oppIndex, index);
  }
  // function changeOpp(e) {
  //   // eslint-disable-next-line radix
  //   const index = parseInt(e.target.value);
  //   setOppIndex(index);
  //   generateData(program, index, keyIndex);
  // }

  return (
    <div className="ip-bubble">
      <div className="ip-head">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Opportunity:</label>
            <Select style={{ width: 150 }} value={keyIndex} onChange={changeKey}>
              {program
                && keys.map((p, i) => (
                  <Select.Option value={i} key={p.name}>{p.name}</Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <div>
          <Checkbox checked={heatmapChecked} onChange={(e) => setHeatmapChecked(e.target.checked)}>Show Heatmap</Checkbox>
        </div>
      </div>
      <div className="timeline">
        {
          bubbleData.length > 0
          && <BubbleChart
            heatmapChecked={heatmapChecked}
            xAxis="x"
            yAxis="y"
            xMax={5.5}
            yMax={5}
            nameKey="name"
            dimension="r"
            data={bubbleData}
          />
        }
      </div>
    </div>
  );
};

IpBubble.propTypes = {
  opportunityIndex: PropTypes.number,
  programData: PropTypes.object,
}

export default IpBubble;
