import React, { Component } from "react";
import { Button, Input, message } from "antd";
import axios from "axios";
import config from "../../config/config";
import "../Login/login.scss";
import { Link } from "react-router-dom";
import { NeoButton } from "../../components/elements/elements";

class ForgetPassword extends Component {
  state = {
    isSent: false,
    emailId: ""
  };

  componentDidMount(){
    
  }

  // Login
  sendLink() {


    axios({
      method: "POST",
      url: `${config.api}/users/forgot-password`,
      data: {
        emailId: this.state.emailId
      },
    }).then((resp) => {
   
      
    }).catch((e)=>{
        
    })

    this.setState({
      isSent:true
    })
  }



  keyDown(e){
    // if(event.key === 'Enter'){
    //   if(this.state.password === this.state.cpassword){
    //     this.setPassword()
    //   } else {
    //     this.setState({
    //       err: true
    //     })
    //   }
    // }
  }

  render() {
    return (
      <div className="Aligner">
            <div className="leff-items">
              <div>
                <img src={require('../../assets/icons/WelcomeText.png')} style={{height:'324px'}} />
              </div>
            </div>
            <div className="container cards Aligner-item">
                <div className="section">
                    <div className="row">
                    <div className="col s12 m4">
                        <div className="card">
                            <div className="icon-block">
                                <span className="Sign-in">
                                    Forget Password?
                                </span>
                                <div className="Sign-in-to-start-your-assessment">
                                    Please enter your email id we wll sent you and forget password link
                                </div>
                            </div>

                            <div className="input-items">
                                <div className="group">      
                                    <input onKeyDown={this.keyDown.bind(this)} onChange={(e)=>this.setState({emailId: e.target.value})} type="text" required />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Email Id</label>
                                </div>

                            </div>
                            <div className="notification-sent" style={this.state.isSent ? {display: 'flex'} : {display: 'none'}}>
                                {
                              
                                    <span>
                                      <i className="fa fa-check-circle-o"/> We have sent you an email with reset password link. Please check your spam/junk if you can't find it in your inbox
                                    </span>
                                }
                            </div>
                            <div className="area" style={this.state.isSent ? {display: 'none'} : {display: 'flex'}}>
                                <NeoButton onClick={()=>this.sendLink()} disabled={
                                    !this.state.emailId.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
                                  }  primary>
                                  Send Reset Password Link
                                </NeoButton>
                            </div>
                            <div className="copy">
                                <p>
                                {/* By signing up, you are agree to 
                                Honeycom3 Terms and Conditions &amp; Privacy Policy */}
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}

export default ForgetPassword;
