import React from "react";
import { Skeleton } from "antd";

const SnapshotLoading = () => {
  return (
    <div>
      <Skeleton paragraph={{ rows: 10 }} active />
    </div>
  );
};

export default SnapshotLoading;
