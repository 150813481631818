import { GET_LOCATION,GET_SALAH_TIMES } from '../constants/constants.js'
const initialState = {
  data: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  name: "",
  position: [],
  location: "N/A",
  salahTimes: {},
  project: null,
  assessment: null
}

export default function dataReducer (state = initialState, action) {
  switch (action.type) {

    case GET_LOCATION :{
        return {
            ...state,
            location: action.payload
        }
    }
    case GET_SALAH_TIMES :{
        return {
            ...state,
            salahTimes: action.payload
        }
    }

    case 'SET_PROJECT': {
      return {
        ...state,
        project: action.payload
      }
    }

    case 'SET_ASSESSMENT': {
      return {
        ...state,
        assessment: action.payload
      }
    }

    default:
      return state
  }
}