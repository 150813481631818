import React, { useEffect, useState } from 'react';
import { FlxHrSB } from '../../components/elements/elements';
import './landing-page.scss';
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'antd';
import ModuleTile from '../../components/common/Tiles/ModuleTile/ModuleTile';
import assesmentImg from '../../assets/icons/assesment_module.png';
import tutorialsImg from '../../assets/icons/tutorials.png';
import helpImg from '../../assets/icons/help.png';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import config from '../../config/config'
import moment from 'moment';


const LandingPage = () => {

    const [data, setData] = useState({ count: 0, maxDate: new Date() });
    useEffect(() => {
        Axios.post(`${config.api}/assesments/count`).then(x => {
            setData(x.data)
        })

    }, []);
    function daysRemaining(edate) {
        var eventdate = moment(edate);
        var todaysdate = moment();
        return eventdate.diff(todaysdate, 'days');
    }

    const { user } = useAuth0();
    return (
        <div className="projects-page landing-page">
            <FlxHrSB>
                <div className="welcome">
                    <h1>Welcome <span>{user.name.split(" ")[0]}</span>!</h1>
                    <p>You have <Link to="/company-portal/assesments">{data.count} assessment(s)</Link> assigned to you</p>
                    {data.count > 0 &&
                        <p>We know it is a lot to ask, but we are hoping if you could finish it all by {moment(data.maxDate).format('LLLL')} ({daysRemaining(data.maxDate)} days left).
                        </p>}
                    <Link to="/company-portal/assesments">
                        <Button type="primary">Get Started</Button>
                    </Link>
                    <Link to="/company-portal/help">
                        <Button style={{ marginLeft: 10 }} type="default">Need Help?</Button>
                    </Link>
                </div>
                <div>
                    <img style={{ width: 273, height: 230 }} src="https://hc3images.s3.us-east-2.amazonaws.com/email/welcome.png" />
                </div>
            </FlxHrSB>
            <div className="module-assigned" style={{ display: 'flex', flexDirection: 'row' }}>
                <ModuleTile
                    imgLink={assesmentImg}
                    title="Assessments"
                    subText="Answer questions about your organization."
                    linkTo="/company-portal/assesments"
                />
                <ModuleTile
                    imgLink={tutorialsImg}
                    title="Tutorials"
                    subText="Learn how to use the Honeycom3 Nectar portal.."
                    linkTo="/company-portal/tutorial"
                />

                <ModuleTile
                    imgLink={helpImg}
                    title="Help"
                    subText="Setup the Nectar application to suit your needs"
                    linkTo="/company-portal/help"
                />

            </div>
        </div>
    );
};

export default LandingPage;