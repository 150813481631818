import React from 'react';

const Unauthorize = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', height: '86vh' }}>
            <h1>You are not authorised to access this page</h1>
            <img src={require('../../assets/icons/unauthorised.svg')} alt="unauthorised" style={{ height: 300 }} />
        </div>
    );
};

export default Unauthorize;