import React from 'react';
import './number-tile.scss';
import PropTypes from 'prop-types';

const NumberTile = ({title,number}) => {
    return <div className="number-tile">
        <span>{number}</span>
        <span>{title}</span>
    </div>
}

NumberTile.propTypes = {
    title: PropTypes.string.isRequired,
    number: PropTypes.any.isRequired
}

export default NumberTile;

