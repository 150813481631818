import { Breadcrumb, Skeleton } from 'antd';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InfoText } from '../../../components/elements/elements';
import config from '../../../config/config';
import { useAuth0 } from '@auth0/auth0-react';
import UserTile from './UserTile';
import './company-users.scss';
import AddNewSub from '../../../components/common/Tiles/SubsTile/AddNewSub';
import SubsTileLoading from '../../../components/common/Tiles/SubsTile/SubsTileLoading';
import _ from 'underscore';

const getUsers = async (user) => {
    const users = await Axios({
        method: 'POST',
        url: `${config.api}/auth0/auth0-users`,
        data: {
            db: user[`${config.auth0.audience}/user_metadata`].databaseName,
            userType: 'ALL'
        }
    });
    return users.data;
}

const numberArray = [1, 2, 3, 4, 5, 6];


const CompanyUsers = () => {

    const { user } = useAuth0();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUsers(user).then(data => {
            console.log(data);
            setUsers(data);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div className="projects-page">
            <div style={{ width: 400 }}><Skeleton active /></div>
            <div className="projects-loading">
                {
                    numberArray.map((x, i) => <SubsTileLoading key={i} />)
                }
            </div>
        </div>
    } else {
        return (
            <div className="projects-page">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to="/company-admin">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Users
                    </Breadcrumb.Item>
                </Breadcrumb>
                <h1>Users</h1>
                <InfoText>You have {users.length} users(s)</InfoText>
                {users.length > 0 &&
                    <div className="user-list">
                        {
                            users.map((user, index) => <UserTile
                                user={user}
                                key={user.user_id}
                                index={index}
                                onDelete={(indexU) => {
                                    let uCopy = _.clone(users)
                                    uCopy.splice(indexU, 1);
                                    setUsers(uCopy);
                                }}
                            />)
                        }
                        <AddNewSub type="user" link="/company-admin/add-user" data={null} />
                    </div>
                }
            </div>
        );
    }


};

export default CompanyUsers;