/* eslint-disable react/prop-types */
import { UploadOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FlexBox } from '../../../../components/elements/elements';
import config from '../../../../config/config';

const JiraModal = ({ programs, onFinish }) => {
  const [projects, setProjects] = useState([]);
  const [isUploading, setIsUploading] = useState(null);
  const [selectedJIRA, setJIRA] = useState(null);
  const [programId, setProgramId] = useState(null);

  useEffect(() => {
    Axios.post(`${config.api}/programs/jira-projects`).then((resp) => {
      setProjects(resp.data);
    });
  }, []);

  const uploadToJira = () => {
    setIsUploading(true);
    Axios.post(`${config.api}/programs/jira`, {
      programId,
      jiraProjectKey: selectedJIRA,
    }).then((resp) => {
      setIsUploading(false);
      onFinish(resp.data);
    });
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <FlexBox flexDirection="column" className="program-flex">
      <div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Program</label>
          <Select id="project" style={{ width: 300 }} onChange={(e) => {
            console.log(e)
            setProgramId(e);
          }} placeholder="Please select the program">
            {programs.map((p) => (
              <Select.Option key={`${p._id}`}>{p.programName}</Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>JIRA Project</label>
          <Select id="project" style={{ width: 300 }} onChange={(e) => setJIRA(e)} placeholder="Please select a JIRA Project">
            {projects.map((p) => (
              <Select.Option key={`${p.key}`}>{p.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="devider" />
        <div>
          {isUploading !== null
            && (
              <div style={{ float: 'left' }}>
                {
                  isUploading ? <Uploading text="Uploading to JIRA" /> : <Success text="Uploaded successfully!" />
                }
              </div>
            )}
          <Button onClick={uploadToJira} disabled={!selectedJIRA || !programId} type="primary" style={{ float: 'right' }}>
            <UploadOutlined />
            &nbsp;&nbsp;
            Upload
          </Button>

        </div>
      </div>
    </FlexBox>
  );
};

const Uploading = ({ text }) => (
  <>
    <LoadingOutlined />
    {' '}
    {text}
  </>
);

const Success = ({ text }) => (
  <div style={{ color: '#368211' }}>
    <CheckOutlined />
    {' '}
    {text}
  </div>
);

export default JiraModal;
