import React from "react";
import { DefaultRoundButton } from "../../elements/elements";
import styled,{css} from "styled-components";
import PropTypes from 'prop-types';
import _ from 'underscore';
import * as FontIcon from 'react-icons/fa'


function createIcon(icon){
  // eslint-disable-next-line import/namespace
  return React.createElement(FontIcon[icon]);
}

const StepComp = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 30px 0 30px 0;
  div {
    /* margin-left: 20px;
    margin-right: 20px; */
  }
`;

const StepContainer = styled.div`
  text-align: center;
    display: flex;
    flex-direction: row;
`
const Line = styled.div`
    min-width: 100px;
    /* width:150px; */
    border-top: 1.2px solid #ddd;
    margin-top: 28px;
    ${(props) =>
      props.active && css`
      border-top: 2px solid orange;
    `}
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
      min-width: 42px;
    }
`

const FlexStepContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
`

function IconSteps({data,active, onSelect, onClick, sectionCount,map}) {

  function checkData(inx){
    return inx.index != null
  }

  

  return (
    <StepComp className="steps">
        {data.map((px, inx) =>
          checkData(px) &&
           <div key={px.questionType}>
              {
                inx === active ? (
                  <StepContainer >
                      <FlexStepContainer>
                        <DefaultRoundButton completed={true} onClick={()=>onSelect(inx)} active>
                              {
                                createIcon(px.icon)
                              }
                        </DefaultRoundButton>
                        <span style={{color: 'var(--text-color)'}}>
                            {px.questionType} {_.findIndex(map, (x)=>x == inx)}
                        </span>
                      </FlexStepContainer>
                      {  sectionCount-1 > _.findIndex(map, (x)=>x == inx) &&
                        <Line>
    
                        </Line>
                      }
                  </StepContainer>
                ) : (
                  <StepContainer onClick={()=>onClick(inx)}>
                      <FlexStepContainer key={inx}>
                        <DefaultRoundButton completed={px.completed} onClick={()=>onSelect(inx)}>
                              {
                                createIcon(px.icon)
                              }
                        </DefaultRoundButton>
                        <span style={{color: 'var(--text-color)'}}>
                            {px.questionType} {}
                        </span>
                      </FlexStepContainer>
                      {  sectionCount-1 > _.findIndex(map, (x)=>x == inx) &&
                        <Line>
    
                        </Line>
                      }
                  </StepContainer>
                )
              }
           </div>
        )}        
    </StepComp>
  );
}

IconSteps.propTypes = {
  data: PropTypes.array,
  active: PropTypes.number,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  sectionCount: PropTypes.number,
  map: PropTypes.array
}

export default IconSteps;
