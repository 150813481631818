import React, { useState } from 'react';
import axios from 'axios';
import './login.scss';
import PropTypes from 'prop-types';
import loginImg from '../../assets/icons/WelcomeText.png';
import HomeBanner from './undraw-city-life-gnpr copy.png';
import config from '../../config/config';
import { Button } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

const initialState = {
  email: null,
  password: null,
  err: false,
  signIn: 'SIGN IN',
};

const Login = (props) => {

  const { loginWithRedirect } = useAuth0();

  const [loginState, setLoginState] = useState(initialState);

  const cachedUrl = () => {
    const url = sessionStorage.getItem('url-to-go');
    if (url) {
      sessionStorage.removeItem('url-to-go');
      window.location.href = url;
    }
  };



  const login = () => {
    setLoginState({
      ...loginState,
      signIn: 'LOGGING IN..',
    });
    axios({
      method: 'POST',
      url: `${config.api}/auth/login`,
      data: loginState,
      headers: null,
    }).then((resp) => {
      localStorage.setItem('cxpx', JSON.stringify(resp.data));
      axios.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`;
      if (resp.data.user.type === 'SUPER_ADMIN') {
        cachedUrl();
        props.history.push('/super-admin');
      } else if (resp.data.user.type === 'COMPANY_ADMIN') {
        cachedUrl();
        props.history.push('/company-admin');
      } else {
        cachedUrl();
        props.history.push('/company-portal');
      }
    }).catch(() => {
      setLoginState({
        ...loginState,
        err: true,
        signIn: 'SIGN IN',
      });
    });
  };

  const keyDown = () => {
    // eslint-disable-next-line no-restricted-globals
    if (event.key === 'Enter') {
      login();
    }
  };
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="Aligner">
      <div className="leff-items">
        <div>
          {/* <img src={loginImg} alt="Login Img" style={{ height: '324px' }} /> */}
          <div className="welcome-head">
            Welcome to
          </div>
          <div className="welcome-head-text">
            {/* Nectar */}
          </div>
          {/* <div className="by-hc3">
            <div>By</div> <img className='brand-logo' alt="HC3" />
          </div> */}
        </div>
        <div>
          <img src={HomeBanner} style={{ height: 265 }} />
        </div>
      </div>
      <div className="container cards Aligner-item">
        <div className="section">
          <div className="row">
            <div className="col s12 m4">
              <div className="card">
                <div className="mobile-banner">
                  <div className="welcome-head">
                    Welcome to
                  </div>
                  <div className="welcome-head-text">
                    Nectar
                  </div>
                  <div className="by-hc3">
                    <div>By</div> <img src={require('./HC3.svg')} alt="HC3" style={{ height: '35px' }} />
                  </div>
                </div>
                <div className="icon-block">
                  <div>
                    <span className="Sign-in">
                      Log In
                    </span>
                  </div>
                  <div>
                    <img src={require('./undraw_Developer_activity_re_39tg.svg')} alt="loj" />
                  </div>
                </div>
                <div className="input-items">
                  <p className="welcome-text">
                    Welcome to Prioriti.ai. Login with your registered email ID and password.
                  </p>
                </div>
                <div className="invalid">
                  <span style={loginState.err ? { visibility: 'visible' } : { visibility: 'hidden' }}>Invalid login credentials</span>
                </div>
                <div className="area">
                  {/* <button onClick={()=>this.login()}>
                                      {this.state.signIn}
                                  </button> */}
                  <Button onClick={() => loginWithRedirect()} type="primary" >Log in</Button>
                </div>
                <div className="copy">
                  <p>
                    By signing in, you agree to
                    Prioriti.ai <Link to="/terms-and-conditions">Terms and Conditions</Link>,&nbsp;<Link to="/privacy-policy">Privacy Policy</Link> &nbsp;&amp; <Link to="/saas-agreement">SaaS Agreement</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.any
};

Login.defaultProps = {
  history: {},
};

export default Login;
