import { Breadcrumb } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./snapshot.scss";
import Tile from "./Tile.jsx";
import TopInitiative from "./TopInitiative.jsx";
import SubsTab from "./SubsTab.jsx";
import AssessmentTrends from "./AssessmentTrends.jsx";
import Axios from "axios";
import config from "../../../../config/config";
import _ from "underscore";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/actions";
import PropTypes from "prop-types";
import { CustomerAdminContext } from "../../CustomerAdminContext";
import SnapshotLoading from "./SnapshotLoading.jsx";

const SnapshotDashboard = ({ actions }) => {
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [stats, setStats] = useState(null);
  const { rankingHierarchy } = useContext(CustomerAdminContext);
  async function getProjects() {
    const { data } = await Axios.post(`${config.api}/dashboard/projects`);
    setProjects(data);
    if (data.length > 0) {
      setCurrentProject(data[0]);
      actions.setProject(data[0]);
    }
  }

  async function getStats() {
    const { data } = await Axios.post(`${config.api}/dashboard/top-stats`);
    setStats(data);
  }

  useEffect(() => {
    getProjects();
    getStats();
  }, []);

  function topName(data, howMany = 3) {
    if (data.length < howMany) {
      howMany = data.length;
    }
    const x = [];
    for (let i = 0; i < howMany; i++) {
      if (data[i].name.length > 15) {
        x.push(`${data[i].name.slice(0, 15)}(${data[i].score})...`);
      } else {
        x.push(`${data[i].name}(${data[i].score})`);
      }
    }
    return x;
  }

  return (
    <section className="projects-page snapshot">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/company-admin">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      {stats ? (
        <div>
          <h1>Snapshot View</h1>
          <div className="snap-flex">
            <div className="number-snaps">
              <Tile
                value={projects.length}
                to="/company-admin/projects"
                name="Total Projects Active"
              />
              {stats && (
                <>
                  <Tile
                    value={stats.totalActiveParticipants}
                    name="Total Participations Active"
                  />
                  <Tile
                    value={stats.participantsResponded}
                    name="Participants Responded so far"
                  />
                  <Tile
                    value={stats.completedAssessment}
                    name="Assessments Complete"
                  />
                  <Tile value={2} name="Assessment Expiring this week" />
                </>
              )}
              <SubsTab
                value="Pro+"
                name="Subscription Plan- 03 months remaining"
              />
            </div>
            <div className="trends">
              <div className="top-initiatives">
                <TopInitiative
                  name={`Top ${rankingHierarchy[0].p}`}
                  value={
                    stats.programs.programs.length > 0
                      ? stats.programs.programs[0].name
                      : "No Programs"
                  }
                  otherInitiatives={
                    stats.programs.programs.length > 0
                      ? topName(stats.programs.programs, 3)
                      : []
                  }
                />
                <TopInitiative
                  name={`Top ${rankingHierarchy[1].p}`}
                  value={
                    stats.programs.opportunities.length > 0
                      ? stats.programs.opportunities[0].name
                      : "No Opportunities"
                  }
                  otherInitiatives={
                    stats.programs.opportunities.length > 0
                      ? topName(stats.programs.opportunities, 3)
                      : []
                  }
                />
                <TopInitiative
                  name={`Top ${rankingHierarchy[2].p}`}
                  value={
                    stats.programs.initiatives.length > 0
                      ? stats.programs.initiatives[0].name
                      : "No Initiatives"
                  }
                  otherInitiatives={
                    stats.programs.initiatives.length > 0
                      ? topName(stats.programs.initiatives, 3)
                      : []
                  }
                />
                <TopInitiative
                  name={`Top ${rankingHierarchy[3].p}`}
                  value={
                    stats.programs.features.length > 0
                      ? stats.programs.features[0].name
                      : "No Features"
                  }
                  otherInitiatives={
                    stats.programs.features.length > 0
                      ? topName(stats.programs.features, 3)
                      : []
                  }
                />
              </div>
              <div className="assessment-trends">
                {currentProject && (
                  <AssessmentTrends
                    currentProject={currentProject}
                    projects={projects}
                    selectProject={(project) => {
                      const p = _.find(projects, { _id: project });
                      actions.setProject(p);
                      setCurrentProject(p);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="snap-loading">
          <SnapshotLoading />
        </div>
      )}
    </section>
  );
};

function mapStateToProps(state) {
  return {
    reducer: state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

SnapshotDashboard.propTypes = {
  actions: PropTypes.object,
  reducer: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotDashboard);
