/* eslint-disable react/jsx-filename-extension */
import React, { useState, useContext } from 'react';
import { Button, Col, Input, Row, Form, DatePicker, Breadcrumb } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import './add-p.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CustomerAdminContext } from '../../CustomerAdminContext';


const stateX = {
  validateMessages: {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
}

function typeUnit(u) {
  if (u === 'Q') {
    return 'quarter';
  } if (u === 'M') {
    return 'month';
  } if (u === 'W') {
    return 'week';
  }
  return null;
}

const AddInitiative = ({ onSaveInitiative, onCancel, timeUnit, initiative, type, updateInitiative, projectId, projectName, programName, opportunitieName, navigate }) => {

  const { rankingHierarchy } = useContext(CustomerAdminContext);
  const [starts, setStarts] = useState(
    initiative ? (!initiative.starts ? null : moment(initiative.starts)) : null
  );
  const [disabled, setDisabled] = useState(false);
  function saveInitiative(data) {
    setDisabled(true);
    if (type === 'POST') {
      onSaveInitiative({
        ...data.initiative,
        features: [],
        createdAt: new Date(),
      })
    } else {
      const intx = {
        ...data.initiative,
        features: initiative.features,
        starts: starts.format('YYYY-MM-DDTHH:mm:ss'),
      }
      updateInitiative(intx)
    }
  }


  return (
    <div className={type === 'PUT' ? `projects-page add-program` : 'add-program'}>

      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/company-admin">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/company-admin/projects">{
            rankingHierarchy[0].p
          }</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate('program')}>
          <a>{programName}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate('opportunity')}>
          <a>{opportunitieName}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => {
          navigate('');
          setTimeout(() => {
            navigate('inititive')
          }, 0)
        }}>
          <a>{
            rankingHierarchy[3].p
          }</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a>{type === 'PUT' ? `Edit ${rankingHierarchy[3].s}` : `Add ${rankingHierarchy[3].s}`}</a>
        </Breadcrumb.Item>
      </Breadcrumb>

      <h1>
        {type === 'PUT' ? `Edit ${initiative.moduleName}` : `Create ${rankingHierarchy[3].s}`}
      </h1>

      <div className="form">
        <Form
          name="nest-messages"
          autoComplete="off"
          className="onboard-form"
          onFinish={saveInitiative}
          validateMessages={stateX.validateMessages}
          initialValues={{
            initiative: initiative
          }}
        >
          <Row>
            <Col span={8}>
              <Form.Item name={['initiative', 'moduleName']} label={`${rankingHierarchy[3].s} Name`} rules={[{ required: true }]}>
                <Input placeholder={`Please enter ${rankingHierarchy[3].s.toLowerCase()} name`} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item name={['initiative', 'description']} label="Description" rules={[]}>
                <TextArea placeholder={`Please enter ${rankingHierarchy[3].s.toLowerCase()} description`} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              {
                initiative === null ?
                  <Form.Item name={['initiative', 'starts']} label="Starts at" rules={[{ required: true }]}>
                    <DatePicker picker={typeUnit(timeUnit)} />
                  </Form.Item>
                  :
                  <>
                    {
                      starts !== null ?
                        <DatePicker
                          picker={typeUnit(timeUnit)}
                          value={starts}
                          onChange={(e) => setStarts(e)}
                        />
                        :
                        <DatePicker
                          picker={typeUnit(timeUnit)}
                          onChange={(e) => setStarts(e)}
                        />
                    }
                  </>
              }

            </Col>
          </Row>


          <Row>

            <Form.Item style={{ marginTop: 20 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                {
                  disabled ? `Saving ${rankingHierarchy[3].s}...` : `Save ${rankingHierarchy[3].s}`
                }
              </Button>
              <Button style={{ marginLeft: 10 }} onClick={onCancel} type="default">Cancel</Button>
            </Form.Item>
          </Row>

        </Form>
      </div>
    </div>
  );
};

AddInitiative.propTypes = {
  onSaveInitiative: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  timeUnit: PropTypes.string.isRequired,
  initiative: PropTypes.object,
  type: PropTypes.string,
  updateInitiative: PropTypes.func,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  programName: PropTypes.string,
  opportunitieName: PropTypes.string,
  navigate: PropTypes.func,
}

AddInitiative.defaultProps = {
  initiative: null,
  type: 'POST',
}


export default AddInitiative;
