import { Breadcrumb, Button } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { InfoText } from '../../../../components/elements/elements';
import PropTypes from 'prop-types';
import './list.scss';
import AddTile from './Tiles/AddTile';
import { MenuOutlined } from '@ant-design/icons';
import InitiativeTile from './Tiles/InitiativeTile';
import AddInitiative from './AddInitiative';
import {CustomerAdminContext} from '../../CustomerAdminContext';


const Initiatives = ({initiatives, setColView, onSaveInitiative, setInitiativeIndex, timeUnit, configs, editInitiative, updateInitiative, deleteInitiative, projectId, projectName, programName, opportunitieName, navigate}) => {
    const [mode,setMode] = useState('view');
    const {rankingHierarchy} = useContext(CustomerAdminContext);

    return (
        <div className="projects-page">
            {
                mode === 'view' ?
                <section>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/company-admin">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/company-admin/projects">{rankingHierarchy[0].p}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('program')}>
                            <a>{programName}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('opportunity')}>
                            <a>{opportunitieName}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {rankingHierarchy[3].p}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h1>{rankingHierarchy[3].p}</h1>
                        <Button type="default" title="List View" onClick={setColView}>
                            <MenuOutlined /> Show as Grid
                        </Button>
                    </div>
                    <InfoText>
                        You have {initiatives.length} <span style={{color: 'var(--primary-color)'}}>active</span> {rankingHierarchy[3].s.toLowerCase()}(s)
                    </InfoText>
                    <div className="list-area">
                        {
                            initiatives.map((initiative, index) => <InitiativeTile 
                                    onClick={()=>setInitiativeIndex(index)} 
                                    initiative={initiative} 
                                    configs={configs}
                                    key={index} 
                                    editInitiative={()=>editInitiative(index)}
                                    deleteInitiative={()=>deleteInitiative(index)}
                                />)
                        }
                        <AddTile type={rankingHierarchy[3].s} onNewClick={()=>setMode('add')} />
                    </div>
                </section>
                : 
                <AddInitiative 
                    onCancel={()=>{
                        setMode('view')
                    }}
                    onSaveInitiative={(data)=>{
                        onSaveInitiative(data);
                        setMode('view')
                    }}
                    timeUnit={timeUnit}
                    updateInitiative={updateInitiative}
                    projectId={projectId}
                    projectName={projectName}
                    programName={programName}
                    opportunitieName={opportunitieName}
                    navigate={navigate}
                />
            }
        </div>
    );
};

Initiatives.propTypes = {
    initiatives: PropTypes.array.isRequired,
    setColView: PropTypes.func.isRequired,
    onSaveInitiative: PropTypes.func.isRequired,
    setInitiativeIndex: PropTypes.func.isRequired,
    timeUnit: PropTypes.string.isRequired,
    configs: PropTypes.object.isRequired,
    editInitiative: PropTypes.func.isRequired,
    updateInitiative: PropTypes.func,
    deleteInitiative: PropTypes.func,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    opportunitieName: PropTypes.string.isRequired
}

export default Initiatives;