import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TopInitiative = ({ value, name, otherInitiatives, to = "/" }) => {
  return (
    <div className="subs-tile top-initiative">
      <label>{name}</label>
      <h2 style={{ fontSize: "25px" }}>{value}</h2>
      <div className="other-initiatives">
        {otherInitiatives.map((initiative, index) => (
          <label key={index}>{initiative}</label>
        ))}
      </div>
      <Link to={to}>View All</Link>
    </div>
  );
};

TopInitiative.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  to: PropTypes.string,
  otherInitiatives: PropTypes.array,
};

export default TopInitiative;
