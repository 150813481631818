import React from "react";
import styled, { css } from "styled-components";
import PropTypes from 'prop-types';

const Ctxt = styled.div`
  min-width: 250px;
  height: 130px;
  border-radius: 30px;
  float: left;
  cursor: pointer;
  text-align: center;
  div {
    font-size: 54px;
    color: #f7981d;
    font-weight: 600;
  }
  span {
    color: #171717;
    font-size: 18px;
    font-weight: bold;
    /* text-transform: uppercase; */
  }
  ${(props) =>
    props.selected && css`
    background: #F6F7FA;
      box-shadow: inset 9px 9px 18px #d4d4d7, inset -9px -9px 18px #ffffff;
  `}

  ${(props) =>
    props.notselected && css`
      
      /* background: linear-gradient(145deg, #dddde1, #ffffff);
    box-shadow:  20px 20px 60px #d1d1d5, -20px -20px 60px #ffffff; */
    background: #f2f3f6;
    box-shadow: 6px 6px 15px 6px #d3d3d6, -6px -6px 17px #ffffff;
  `}
`;

function Card(props) {
  return props.type === "selected" ? (
    <Ctxt selected>
      <div>{props.count}</div>
      <span>{props.text}</span>
    </Ctxt>
  ) : (
    <Ctxt notselected>
      <div>{props.count}</div>
      <span>{props.text}</span>
    </Ctxt>
  );
}

Card.propTypes = {
  type: PropTypes.string
}

export default Card;
