import { Breadcrumb, Skeleton } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AssessmentTile from '../../../components/common/Tiles/AssessmentTile/AssessmenTile';
import SubsTileLoading from '../../../components/common/Tiles/SubsTile/SubsTileLoading';
import config from '../../../config/config'
const numberArray = [ 1,2,3,4,5,6 ];


const MyAssesments = () => {

    const [assesments, setAssesment] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getMyAssesments();
    }, []);

    async function getMyAssesments(){
        const assm = await Axios.patch(`${config.api}/assesments`);
        setLoading(false);
        setAssesment(assm.data);
    }


    if(loading){
        return <div className="projects-page">
            <div style={{width: 400}}><Skeleton active /></div>
            <div className="projects-loading">
              {
                numberArray.map((x,i)=><SubsTileLoading key={i} />)
              }
            </div>
      </div>
    } else {
        if(assesments.length > 0){
          return (
            <div className="projects-page">
                <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/company-portal">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  Assessments
                </Breadcrumb.Item>
                
            </Breadcrumb>
              <h1>Assessments</h1>
              <span className="subtxt">
                You have {assesments.length} assesement(s)
              </span>
      
              <div className="projects">
                
                { !loading &&
                  assesments.map((x,i)=>{
                    return <AssessmentTile 
                      key={i} 
                      assessment={x} 
                      onClick={()=>{
                        history.push(`/company-portal/assesment/${x._id}`)
                      }}
                    />
                  })
                }
              </div>
            </div>
          );
        } else {
          return <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center',height: '86vh'}}>
              <h1>You do not have any assessment assigned yet</h1>
              <img src={require('../../../assets/icons/help.svg')} alt="Help icon" />
          </div>
        }
       }
};


export default MyAssesments;
