import { Button, message } from 'antd';
import Axios from 'axios';
import React from 'react';
import config from '../../config/config';

const Cleanup = () => {

    const migrate = async () => {
        const mx = message.loading('Migrating...', 0);
        const dx = await Axios.delete(`${config.api}/maturity-questions/cleanup`);
        console.log(dx);
        mx();
        message.success('Migration complete');
    }

    return (
        <div>
            <Button type="primary" onClick={migrate}>Migrate data</Button>
        </div>
    );
};

export default Cleanup;