import React, { useState, useEffect } from 'react';
import { TopArea, ContentArea, BottomArea } from '../../../components/elements/elements';
import Axios from 'axios';
import config from '../../../config/config';
import IconSteps from '../../../components/common/Steps/IconSteps';
import { Row, Col, Radio, message, Slider, Button, Breadcrumb } from 'antd';
import _ from 'underscore';
import ClientHomeSketch from '../ClientHomeSketch';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';


const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    color: 'var(--text-color)'
};

const MaturityAssesment = () => {
    const history = useHistory();
    let { assesmentId, retake } = useParams();
    const [project, setProject] = useState(null);
    const [px, setPx] = useState(null);
    const [assesment, setAssesment] = useState({ name: '' });
    const [activeQ, setActiveQu] = useState(0);
    const [map, setMap] = useState([]);
    const [sectionCount, setSectionCount] = useState(0);
    useEffect(() => {
        //Get Project details
        Axios.get(`${config.api}/maturity-questions/assesment/${assesmentId}`).then(resp => {
            if (resp.data.maturityDetails.status && retake !== 'retake') {
                history.push(`/company-portal/assessment-result/${assesmentId}`)
            } else {
                if (resp.data.code === "NO_ACCESS") {
                    // setError(resp.data.msg)
                } else {
                    setActiveQu(0);
                    setProject(resp.data.maturityDetails);
                    setAssesment(resp.data.assesmentDetails);
                    setPx(resp.data.projectDetails);
                    setMap(generateMap(resp.data.maturityDetails.questions));
                    setSectionCount(resp.data.sectionCount)
                }
            }
        });
    }, []);


    function generateMap(qx) {
        let x = [];

        for (let i = 0; i < qx.length; i++) {
            if (qx[i].index !== null) {
                x.push(i);
            }
        }
        return x;
    }

    function changeQ(e, index) {
        let projectX = _.clone(project);
        projectX.questions[map[activeQ]].questions[index].value = e.target.value
        setProject(projectX)
    }

    /* function checkData(inx){
        const count = _.filter(project.questions[inx].questions, x => x.selected)
        return count.length > 0 ? true : false;
    } */

    function goNext(incr) {
        let lastStep = false;
        // const  p = checkData(activeQ + incr);
        // console.log(p)


        if (activeQ == sectionCount - 1 && incr !== -1) { // This is the last step
            project.status = true;
            lastStep = true;
        } else {
            setActiveQu(activeQ + incr);
        }
        let msg;
        if (lastStep) {
            msg = message.loading("Please wait we are saving your result...");
        }

        Axios.put(`${config.api}/maturity-questions/assesment/${assesmentId}`, project).then(() => {
            if (lastStep) {
                msg();
                // message.success("Thank you for your participation");
                if (window.location.href.search("company-admin") >= 0) {
                    history.push("/company-admin/maturity-result/" + assesmentId);
                } else {
                    history.push("/company-portal/maturity-sumiited/" + assesmentId);
                }

            }
        })
    }

    function getType(optionArr) {
        let isRadio = true;
        let options = ["Strongly disagree", "Disagree", "Neither agree or disagree", "Somewhat agree", "Strongly agree"];
        for (let i = 0; i < options.length; i++) {
            let index = _.findIndex(optionArr, (x) => x.name == options[i])
            isRadio = isRadio && index >= 0 ? true : false
        }
        return !isRadio ? "Radio" : "Slider"

    }

    return (
        project && map.length > 0 ?
            <section className="projects-page">
                <TopArea>
                    {
                        px &&
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/company-portal">Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/company-portal/assesments">Assessments</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {px.projectName}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {assesment.name}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                    <h1>{assesment.name}</h1>
                    {
                        moment(assesment.validUpto).isBefore(moment()) &&
                        <small>Assesment has been Expired</small>
                    }
                    <IconSteps
                        data={project.questions}
                        active={map[activeQ]}
                        onClick={(index) => setActiveQu(index)}
                        sectionCount={sectionCount}
                        map={map}
                    />
                    <hr className="devider" style={{ marginTop: '20px', marginBottom: '20px' }} />
                </TopArea>
                <ContentArea className="q-content-area">
                    {
                        project.questions[map[activeQ]].questions.map((question, index) => (
                            question.selected &&
                            <Row key={index} className="q-row" gutter={12}>
                                <Col span={10}>
                                    <span className="text-q">
                                        {question.question}
                                    </span>
                                </Col>
                                <Col span={1} />
                                <Col span={13}>
                                    {
                                        getType(question.options) == "Radio" ?
                                            <Radio.Group value={question.value} key={`radio__${index}`} onChange={(e) => changeQ(e, index)}>
                                                {
                                                    question.options.map((option, opIndex) => (
                                                        <Radio key={opIndex} style={radioStyle} value={option.value}>{option.name}</Radio>
                                                    ))
                                                }
                                            </Radio.Group>
                                            :
                                            <div className="ma-slider" key={`slider__${index}`}>
                                                <Slider
                                                    min={1}
                                                    max={5}
                                                    key={Math.random()}
                                                    step={0.5}
                                                    value={question.value}
                                                    onChange={(e) => changeQ({ target: { value: e } }, index)}
                                                    marks={
                                                        {
                                                            1: "Strongly disagree",
                                                            2: "Disagree",
                                                            3: "Neither agree or disagree",
                                                            4: "Somewhat agree",
                                                            5: "Strongly agree"
                                                        }
                                                    }
                                                />
                                            </div>

                                    }

                                </Col>
                            </Row>
                        ))
                    }
                </ContentArea>
                <BottomArea>
                    <hr className="devider" style={{ marginTop: '10px' }} />
                    <BottomArea>
                        <Button type="default" onClick={() => history.push(`/company-portal/assesments`)}>
                            <i className="fa fa-chevron-left"></i> &nbsp; Go Back to Assessments
                        </Button>
                        <Button style={{ float: 'right' }} type="primary" onClick={() => goNext(1)} disabled={moment(assesment.validUpto).isBefore(moment())} >
                            {
                                activeQ == sectionCount - 1 ? "FINISH" : <>NEXT &nbsp;<i className="fa fa-chevron-right"></i></>
                            }
                        </Button>
                        <Button style={{ float: 'right', marginRight: 10 }} onClick={() => goNext(-1)} disabled={map[activeQ] == 0}>
                            {
                                <><i className="fa fa-chevron-left"></i>&nbsp;PREV</>
                            }
                        </Button>

                    </BottomArea>
                </BottomArea>
            </section>
            :
            <ClientHomeSketch />
    );
};

export default MaturityAssesment;