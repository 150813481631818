/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import '../Programs/programs.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Input, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const AddModuleForm = ({
  module, changeForm, type, timeUnit,
}) => {
  function typeUnit(u) {
    if (u === 'Q') {
      return 'quarter';
    } if (u === 'M') {
      return 'month';
    } if (u === 'W') {
      return 'week';
    }
    return null;
  }
  function renderDatePicker() {
    if (module.starts) {
      return <DatePicker value={moment(module.starts)} onChange={(e) => changeForm('starts', e)} picker={typeUnit(timeUnit)} />;
    }
    return <DatePicker onChange={(e) => changeForm('starts', e)} picker={typeUnit(timeUnit)} />;
  }
  return (
    <div className="form-area">
      <div className="fl-item">
        <div className="inp-group">
          <label htmlFor="module_name">Name</label>
          <Input
            className="neo"
            autoComplete="OFF"
            name="module_name"
            placeholder={`${type} name`}
            value={module.moduleName}
            onChange={(e) => changeForm('moduleName', e.target.value)}
          />
        </div>
        {
          type === 'module'
          && (
          <div className="inp-group">
            <label htmlFor="module_name">Starts on</label>
            {renderDatePicker()}
          </div>
          )
        }
        <div className="inp-group">
          <label>Descripion</label>
          <TextArea
            className="neo"
            placeholder={`Please enter ${type} description`}
            value={module.description}
            onChange={(e) => changeForm('description', e.target.value)}
          />
        </div>
      </div>

    </div>
  );
};

AddModuleForm.propTypes = {
  module: PropTypes.shape({
    moduleName: PropTypes.string,
    description: PropTypes.string,
  }),
  changeForm: PropTypes.func,
  type: PropTypes.string,
  timeUnit: PropTypes.string,
};

AddModuleForm.defaultProps = {
  module: {},
  changeForm: PropTypes.func,
  type: 'module',
  timeUnit: 'Q',
};

export default AddModuleForm;
