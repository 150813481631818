import React from 'react';

const OppKPI = (props) => {
    return <>
        <div className="score">
            <div className="opp-rank">
                <div className="rect1" style={{ height: '150px' }}>
                    {props.data}
                </div>
                <span>Scenario Rank</span>
            </div>

        </div>
    </>
}

export default OppKPI;