import { Breadcrumb, Button } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { InfoText } from '../../../../components/elements/elements';
import PropTypes from 'prop-types';
import ProgramTile from './Tiles/ProgramTile';
import './list.scss';
import AddTile from './Tiles/AddTile';
import AddProgram from './AddProgram';
import { MenuOutlined } from '@ant-design/icons';
import {CustomerAdminContext} from '../../CustomerAdminContext';


const Programs = ({programs,setColView, onAddProgram,setProgramIndex,projectId, editProgram, deleteProgram, projectName, accessFrom}) => {
    const [mode,setMode] = useState('view');
    const {rankingHierarchy} = useContext(CustomerAdminContext);

    return (
        <div className="projects-page">
            {
                mode === 'view' ?
                <section>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/company-admin">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/company-admin/projects">
                                {rankingHierarchy[0].p}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company-admin/project/${projectId}`}>{projectName}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {rankingHierarchy[1].p}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h1>{rankingHierarchy[1].p}</h1>
                        {
                            accessFrom === 'IR' && <Button type="default" title="List View" onClick={setColView}>
                                <MenuOutlined /> Show as Grid
                            </Button>
                        }
                    </div>
                    <InfoText>
                        You have {programs.length} <span style={{color: 'var(--primary-color)'}}>active</span> {(rankingHierarchy[1].s).toLowerCase()}(s).
                    </InfoText>
                    <div className="list-area">
                        {
                            programs.map((program, index) => <ProgramTile 
                                    program={program} 
                                    key={index} 
                                    onClick={()=>setProgramIndex(index)}
                                    editProgram={()=>editProgram(index)}
                                    deleteProgram={()=>deleteProgram(index)}
                                />
                            )
                        }
                        <AddTile type={`${rankingHierarchy[1].s}`} onNewClick={()=>setMode('add')} />
                    </div>
                </section>
                : 
                <AddProgram 
                    onAddProgram={()=>{
                        setMode('view')
                        onAddProgram();
                    }} 
                    type="POST"
                    projectId={projectId}
                    projectName={projectName}
                    onCacel={()=>setMode('view')}
                />
            }
        </div>
    );
};

Programs.propTypes = {
    programs: PropTypes.array.isRequired,
    setColView: PropTypes.func.isRequired,
    onAddProgram: PropTypes.func.isRequired,
    setProgramIndex: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    editProgram: PropTypes.func.isRequired,
    deleteProgram: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired,
    accessFrom: PropTypes.string
}

Programs.defaultProps = {
    accessFrom: 'IR'
}

export default Programs;