import React from "react";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

function getData(data, nameKey) {
  let dataArr = [];
  for (let i = 0; i < data.length; i++) {
    let startDate = data[i].start_date.split("-");
    startDate = new Date(parseInt(startDate[0]), parseInt(startDate[1] - 1), parseInt(startDate[2]));
    var endDate = new Date(data[i].start_date);
    endDate.setDate(endDate.getDate() + data[i].no_of_days);
    dataArr.push([data[i][nameKey], startDate, endDate]);
  }

  dataArr.unshift(["", new Date(2020, 1, 1), new Date(2020, 1, 5)]);
  console.log(dataArr);

  return dataArr;
}

const Roadmap = ({ data, nameKey }) => {
  return (
    <div>
      <Chart
        width={"700px"}
        height={"400px"}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={getData(data, nameKey)}
        options={{
          timeline: {
            singleColor: "#f7981d",
          },
        }}
      />
    </div>
  );
};

Roadmap.propTypes = {
  data: PropTypes.object.isRequired,
  nameKey: PropTypes.string.isRequired,
};

export default Roadmap;
