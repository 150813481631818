import React from 'react';
import './kpi.scss';
import PropTypes from 'prop-types';

// function to attach 0 before number
const pad = (num, size) => {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
};

const Tile = ({count,title, onClick}) => {
    return (
        <div className="tile" onClick={onClick}>
            <div>
                <span>{pad(count, 2)}</span>
                <span>{title}</span>
            </div>
            <div>
                <span>{pad(count,2)} Active</span>
                <span>00 Archived</span>
            </div>
        </div>
    );
};
Tile.propTypes ={
    count: PropTypes.number.isRequired, 
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func
}
export default Tile;