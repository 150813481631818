import React from 'react';
import './modcard.scss';


const ModuleCard = ({module, selectModule, index}) => {
    return (
        <div className="mod-card" onClick={()=>selectModule(index)}>
            <h4>{module.name}</h4>
            {
                module.selected ?
                <span className="selected"> ✓ Selected</span>
                :
                <span className="not-selected">Click to select</span>
            }
        </div>
    );
};

export default ModuleCard;