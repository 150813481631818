/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './bc.scss';
import PropTypes from 'prop-types';
import Heatmap from './Heatmap';

const BubbleChart = ({
  xAxis, yAxis, dimension, nameKey, data, xMax, yMax, heatmapChecked
}) => (
  <div className="bubble-chart">
    {/* <hr className="hr1" />
    <hr className="hr2" /> */}
    <div>
      {
        data.map((bubble) => (
          <Bubble
            key={bubble[nameKey]}
            color={bubble.color}
            dimension={bubble[dimension]}
            name={bubble[nameKey]}
            x={bubble[xAxis]}
            y={bubble[yAxis]}
            xMax={xMax}
            yMax={yMax}
          />
        ))
      }
    </div>

    <Heatmap heatmapChecked={heatmapChecked} />


    <span className="top-left">Low Values/High Maturity</span>
    <span className="bottom-left">Low Values/Low Maturity</span>
    <span className="top-right">High Values/High Maturity</span>
    <span className="bottom-right">High Values/Low Maturity</span>

  </div>
);

function hexToRgb(hex) { //alpha = 0.5
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let p = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
  if (p) {
    return `rgb(${p.r}, ${p.g}, ${p.b})`; //${alpha}
  }
}

const Bubble = ({
  color, dimension, name, x, y, xMax, yMax,
}) => {
  const px = 500;
  function getTop() {
    const ratio = yMax - y;
    const per = (ratio / yMax) * 100;
    const yPx = px * (per / 100);
    return yPx;
  }
  return (
    <div
      className="bubble animate__animated animate__pulse"
      style={{
        height: `${dimension * 30}px`,
        width: `${dimension * 30}px`,
        backgroundColor: hexToRgb(color),
        left: `${(x / xMax) * 100}%`,
        top: `${getTop()}px`,
        zIndex: 1,
        // borderColor: color,
        // borderWidth: 1,
        // borderStyle: 'solid',
        // boxShadow: `3px 2px 10px 0px ${hexToRgb(color, 0.3)}`,
      }}
    >
      <div className="title">{name}</div>
    </div>
  );
};

BubbleChart.propTypes = {
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  nameKey: PropTypes.string,
  data: PropTypes.array,
  dimension: PropTypes.any,
};

export default BubbleChart;
