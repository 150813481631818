import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const AssTileX = ({ value, name, linkName, onClick = null, to = "/" }) => {
  value = value < 10 ? `0${value}` : value;
  return (
    <div className="ass-tilex-tile">
      <h2 style={{ fontSize: "25px" }}>{value}</h2>
      <label>{name}</label>
      {to !== "NULL" ? (
        <Link to={to}>{linkName}</Link>
      ) : (
        <label
          onClick={onClick}
          style={{ color: "var(--primary-color)", cursor: "pointer" }}
        >
          {linkName}
        </label>
      )}
    </div>
  );
};

AssTileX.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  to: PropTypes.string,
  linkName: PropTypes.string,
  onClick: PropTypes.func,
};
export default AssTileX;
