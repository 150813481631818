import React from 'react';
import './subs-tile.scss';
import image4 from './4.jpg';
import image3 from './2.jpg';
import image2 from './3.jpg';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

const SubsTile = ({name, modules, topUser, plan, link, createdAt, data}) => {
    const history = useHistory();
    return (
        <div className="subs-tile" onClick={()=>{
            history.push({
                pathname: link,
                state: data
            });
        }}>
            <div className="head-text">
                <span>{name}</span>
                <small>{plan}</small>
            </div>
            <span className="mods">{modules.substr(0,100)}...</span>
            {
                !topUser ? 
                <div className="images">
                    <div style={{ backgroundImage: `url(${image4})`, height: 33, width: 33, backgroundSize: 'cover', borderRadius: '50%' }}/>
                    <div style={{ backgroundImage: `url(${image2})`, height: 33, width: 33, backgroundSize: 'cover', borderRadius: '50%' }}/>
                    <div style={{ backgroundImage: `url(${image3})`, height: 33, width: 33, backgroundSize: 'cover', borderRadius: '50%' }}/>
                    <div style={{marginLeft: 5}}>+20 Collebrators</div>
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                    {
                        topUser.slice(0,3).map((user, index)=>
                            <img title={user.name} src={user.picture} key={index} alt="user" className="user-image" style={{height: 33, width: 33, borderRadius: '50%', marginRight: 5}} />
                        )
                        
                    }
                    {
                        topUser.length > 3 &&
                        <span>+{topUser.length - 3} more</span>
                    }
                </div>
            }
            {
                createdAt &&
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{color: 'var(--text-color)', fontSize: 14, fontWeight: 'normal'}}>Created - {moment(createdAt).format('MMM DD yy')}</span>
                    <span style={{color: 'var(--primary-color)', marginRight: 10, fontSize: 14, fontWeight: 'normal'}}>Active</span>
                </div>
            }
        </div>
    );
};

SubsTile.propTypes ={
    name: PropTypes.string,
    modules: PropTypes.string,
    topUser: PropTypes.array,
    userCount: PropTypes.number,
    status: PropTypes.string,
    plan: PropTypes.string,
    link: PropTypes.string,
    createdAt: PropTypes.string,
    data: PropTypes.object
}

export default SubsTile;