import { Button } from "antd";
import React from "react";
import roDi from "./UserJourney.png";
import { useHistory } from "react-router-dom";

const InnovationMapper = () => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "70vh",
        alignItems: "center",
        marginTop: "75px",
      }}
    >
      <h1>Innovation Engagement Mapping…Coming Soon</h1>
      <img src={roDi} alt="Rodi" style={{ width: "40%" }} />
      <div style={{ marginTop: 10 }}>
        <h2>Stay tuned for updates.</h2>
        <Button type="primary">Get Notified</Button>
        <Button
          type="default"
          style={{ marginLeft: 10 }}
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default InnovationMapper;
