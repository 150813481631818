import React from 'react';

const Avatar = ({url, size, alt=''}) => {
    return (
        <div>
            <img src={url} alt={alt} alt="avatar" style={{height: size, borderRadius: '50%' }} />
        </div>
    );
};

export default Avatar;