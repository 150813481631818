import React from 'react';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'

const Spider = (props) => {
  return (
    <div className="spider-chart">
      <RadarChart axes={true} captions={props.captions} data={[props.data]} options={{ scales: 5 }} size={props.size} />
    </div>
  );
};

export default Spider;